import * as React from "react";
import { List, Datagrid, FunctionField, SimpleList} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from "@mui/material";
import BidFormModal from './BidFormModal';
import { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const findLocationByZipCode = (zipCode) => {
  // Tässä tapahtuu postinumeron haku. Palauttaa tarkemman sijainnin merkkijonona.
  // Tämä on esimerkkikoodia; korvaa se oikealla logiikalla.
  const locations = {
    '07230': 'Monninkylä',
    '07500': 'Askola',
    '07510': 'Vakkola',
    '07530': 'Onkimaa',
    '07580': 'Tiilää',
    '07590': 'Huuvari',
    '07680': 'Juornaankylä',
    '02100': 'Tapiola',
    '02110': 'Tapiola',
    '02120': 'Tapiola',
    '02130': 'Pohjois-Tapiola',
    '02140': 'Laajalahti',
    '02150': 'Otaniemi',
    '02160': 'Westend',
    '02170': 'Haukilahti',
    '02180': 'Mankkaa',
    '02200': 'Henttaa',
    '02210': 'Olari',
    '02230': 'Matinkylä',
    '02240': 'Olari',
    '02250': 'Henttaa',
    '02260': 'Kaitaa',
    '02270': 'Kaitaa',
    '02280': 'Latokaski',
    '02290': 'Olari',
    '02300': 'Nöykkiö',
    '02320': 'Espoonlahti',
    '02330': 'Saunalahti',
    '02340': 'Latokaski',
    '02360': 'Soukka',
    '02380': 'Suvisaaristo',
    '02600': 'Leppävaara',
    '02610': 'Kilo',
    '02620': 'Karakallio',
    '02630': 'Kilo',
    '02650': 'Leppävaara',
    '02660': 'Lintuvaara',
    '02680': 'Lintuvaara',
    '02710': 'Viherlaakso',
    '02720': 'Lippajärvi',
    '02730': 'Laaksolahti',
    '02740': 'Kunnarla',
    '02750': 'Sepänkylä',
    '02760': 'Espoon keskus',
    '02770': 'Espoon keskus',
    '02780': 'Espoonkartano',
    '02810': 'Espoonkartano',
    '02820': 'Nuuksio',
    '02860': 'Siikajärvi',
    '02920': 'Vanhakartano',
    '02940': 'Röylä',
    '02970': 'Luukki',
    '02980': 'Velskola',
    '10820': 'Hanko',
    '10900': 'Hanko',
    '10940': 'Hanko',
    '10960': 'Hanko',
    '00002': 'Helsinki',
    '00100': 'Postitalo',
    '00102': 'Helsinki',
    '00120': 'Punavuori',
    '00130': 'Kaartinkaupunki',
    '00140': 'Ullanlinna',
    '00150': 'Eira',
    '00160': 'Katajanokka',
    '00170': 'Kruununhaka',
    '00180': 'Ruoholahti',
    '00190': 'Suomenlinna',
    '00200': 'Lauttasaari',
    '00210': 'Lauttasaari',
    '00220': 'Jätkäsaari',
    '00230': 'Pasila postikeskus',
    '00240': 'Länsi-Pasila',
    '00250': 'Laakso',
    '00260': 'Taka-Töölö',
    '00270': 'Laakso',
    '00280': 'Ruskeasuo',
    '00290': 'Meilahden sairaala-alue',
    '00300': 'Pikku Huopalahti',
    '00310': 'Kivihaka',
    '00320': 'Etelä-Haaga',
    '00330': 'Munkkiniemi',
    '00340': 'Lehtisaari',
    '00350': 'Munkkivuori',
    '00360': 'Pajamäki',
    '00370': 'Pitäjänmäki',
    '00380': 'Pitäjänmäen yritysalue',
    '00390': 'Konala',
    '00400': 'Pohjois-Haaga',
    '00410': 'Malminkartano',
    '00420': 'Kannelmäki',
    '00430': 'Hakuninmaa',
    '00440': 'Lassila',
    '00500': 'Sörnäinen',
    '00510': 'Alppila',
    '00520': 'Itä-Pasila',
    '00530': 'Hakaniemi',
    '00540': 'Hanasaari',
    '00550': 'Hermanni',
    '00560': 'Kumpula',
    '00570': 'Kulosaari',
    '00580': 'Hermanni',
    '00590': 'Laajasalo',
    '00600': 'Käpylä',
    '00610': 'Käpylä',
    '00620': 'Metsälä',
    '00630': 'Maunula',
    '00640': 'Oulunkylä',
    '00650': 'Oulunkylä',
    '00660': 'Länsi-Pakila',
    '00670': 'Paloheinä',
    '00680': 'Itä-Pakila',
    '00690': 'Torpparinmäki',
    '00700': 'Malmi',
    '00710': 'Pihlajisto',
    '00720': 'Pukinmäki',
    '00730': 'Malmi',
    '00740': 'Siltamäki',
    '00750': 'Puistola',
    '00760': 'Puistola',
    '00770': 'Tattarisuo',
    '00780': 'Tapaninvainio',
    '00790': 'Viikki',
    '00800': 'Herttoniemi',
    '00810': 'Herttoniemenranta',
    '00820': 'Roihuvuori',
    '00830': 'Tammisalo',
    '00840': 'Laajasalo',
    '00850': 'Laajasalo',
    '00860': 'Santahamina',
    '00870': 'Laajasalo',
    '00880': 'Herttoniemi',
    '00890': 'Östersundom',
    '00900': 'Puotinharju',
    '00910': 'Puotila',
    '00920': 'Myllypuro',
    '00930': 'Itäkeskus',
    '00940': 'Kontula',
    '00950': 'Vartioharju',
    '00960': 'Vuosaari',
    '00970': 'Mellunmäki',
    '00980': 'Vuosaari',
    '00990': 'Vuosaari',
    '05440': 'Hyvinkää',
    '05460': 'Hyvinkää',
    '05470': 'Palopuro',
    '05510': 'Päivärinta',
    '05620': 'Noppo',
    '05720': 'Kytäjä',
    '05800': 'Hyvinkää',
    '05810': 'Martti',
    '05820': 'Hyvinkää',
    '05830': 'Hyvinkää',
    '05840': 'Hyvinkää',
    '05850': 'Hyvinkää',
    '05860': 'Talvisilta',
    '05880': 'Hyvinkää',
    '05900': 'Hyvinkää',
    '05950': 'Ridasjärvi',
    '10120': 'Inkoo',
    '10140': 'Inkoo',
    '10160': 'Inkoo',
    '10210': 'Inkoo',
    '10230': 'Inkoo',
    '10250': 'Inkoo',
    '10270': 'Inkoo',
    '04400': 'Järvenpää',
    '04410': 'Järvenpää',
    '04420': 'Järvenpää',
    '04430': 'Järvenpää',
    '04440': 'Järvenpää',
    '04460': 'Järvenpää',
    '04480': 'Järvenpää',
    '03600': 'Karkkila',
    '03620': 'Karkkila',
    '03710': 'Karkkila',
    '02700': 'Kauniainen',
    '04200': 'Kerava',
    '04220': 'Kerava',
    '04230': 'Kerava',
    '04250': 'Kerava',
    '04260': 'Savio',
    '02390': 'Kirkkonummi',
    '02400': 'Kirkkonummi',
    '02410': 'Kirkkonummi',
    '02420': 'Kirkkonummi',
    '02430': 'Kirkkonummi',
    '02440': 'Kirkkonummi',
    '02450': 'Kirkkonummi',
    '02460': 'Kirkkonummi',
    '02470': 'Kirkkonummi',
    '02480': 'Porkkala',
    '02490': 'Kirkkonummi',
    '02510': 'Kirkkonummi',
    '02520': 'Kirkkonummi',
    '02540': 'Kirkkonummi',
    '02550': 'Kirkkonummi',
    '02880': 'Kirkkonummi',
    '07800': 'Lapinjärvi',
    '07810': 'Lapinjärvi',
    '07820': 'Lapinjärvi',
    '07830': 'Lapinjärvi',
    '07840': 'Lapinjärvi',
    '07850': 'Lapinjärvi',
    '47350': 'Lapinjärvi',
    '03810': 'Lohja',
    '03850': 'Lohja',
    '03870': 'Lohja',
    '08100': 'Lohja',
    '08150': 'Lohja',
    '08200': 'Lohja',
    '08350': 'Routio',
    '08360': 'Kisakallio',
    '08450': 'Hormajärvi',
    '08480': 'Karstu',
    '08500': 'Ventelä (Lohja as)',
    '08680': 'Muijala',
    '08700': 'Virkkala',
    '08800': 'Kirkniemi',
    '09120': 'Lohja',
    '09220': 'Lohja',
    '09430': 'Lohja',
    '09520': 'Lohja',
    '09630': 'Lohja',
    '09810': 'Lohja',
    '09930': 'Lohja',
    '07700': 'Loviisa',
    '07720': 'Loviisa',
    '07740': 'Loviisa',
    '07750': 'Loviisa',
    '07780': 'Loviisa',
    '07870': 'Loviisa',
    '07880': 'Loviisa',
    '07890': 'Loviisa',
    '07900': 'Loviisa',
    '07910': 'Loviisa',
    '07920': 'Loviisa',
    '07930': 'Loviisa',
    '07940': 'Loviisa',
    '07945': 'Loviisa',
    '07955': 'Loviisa',
    '07960': 'Loviisa',
    '07970': 'Loviisa',
    '07980': 'Loviisa',
    '07990': 'Loviisa',
    '07600': 'Myrskylä',
    '07690': 'Myrskylä',
    '04530': 'Mäntsälä',
    '04600': 'Mäntsälä',
    '04620': 'Mäntsälä',
    '04630': 'Mäntsälä',
    '04660': 'Mäntsälä',
    '04680': 'Mäntsälä',
    '04740': 'Mäntsälä',
    '04770': 'Mäntsälä',
    '04820': 'Mäntsälä',
    '04840': 'Mäntsälä',
    '04920': 'Mäntsälä',
    '04940': 'Mäntsälä',
    '01800': 'Nurmijärvi',
    '01810': 'Nurmijärvi',
    '01820': 'Nurmijärvi',
    '01830': 'Nurmijärvi',
    '01840': 'Nurmijärvi',
    '01860': 'Nurmijärvi',
    '01900': 'Nurmijärvi',
    '01940': 'Nurmijärvi',
    '05100': 'Nurmijärvi',
    '05200': 'Nurmijärvi',
    '05250': 'Nurmijärvi',
    '05450': 'Nurmijärvi',
    '07150': 'Pornainen',
    '07170': 'Pornainen',
    '07190': 'Pornainen',
    '06100': 'Porvoo',
    '06150': 'Porvoo',
    '06200': 'Porvoo',
    '06400': 'Porvoo',
    '06450': 'Porvoo',
    '06500': 'Porvoo',
    '06530': 'Porvoo',
    '06650': 'Porvoo',
    '06750': 'Porvoo',
    '06830': 'Porvoo',
    '06850': 'Porvoo',
    '06880': 'Porvoo',
    '06950': 'Porvoo',
    '07110': 'Porvoo',
    '07130': 'Porvoo',
    '07220': 'Porvoo',
    '07280': 'Porvoo',
    '07310': 'Porvoo',
    '07320': 'Porvoo',
    '07350': 'Porvoo',
    '07360': 'Porvoo',
    '07370': 'Porvoo',
    '07390': 'Porvoo',
    '07410': 'Porvoo',
    '07450': 'Porvoo',
    '07550': 'Pukkila',
    '07560': 'Pukkila',
    '07565': 'Pukkila',
    '10300': 'Raasepori',
    '10320': 'Raasepori',
    '10330': 'Raasepori',
    '10350': 'Raasepori',
    '10360': 'Raasepori',
    '10380': 'Raasepori',
    '10410': 'Raasepori',
    '10420': 'Raasepori',
    '10440': 'Raasepori',
    '10470': 'Raasepori',
    '10480': 'Raasepori',
    '10520': 'Raasepori',
    '10570': 'Raasepori',
    '10600': 'Raasepori',
    '10620': 'Högbacka',
    '10640': 'Raasepori',
    '10650': 'Raasepori',
    '10660': 'Raasepori',
    '10680': 'Raasepori',
    '10710': 'Raasepori',
    '01120': 'Sipoo',
    '01150': 'Sipoo',
    '01180': 'Sipoo',
    '01190': 'Sipoo',
    '04130': 'Nikkilä',
    '04150': 'Sipoo',
    '04170': 'Sipoo',
    '04240': 'Sipoo',
    '02570': 'Siuntio',
    '02580': 'Siuntio',
    '02590': 'Siuntio',
    '04300': 'Hyrylä',
    '04310': 'Kirkonkylä',
    '04320': 'Riihikallio',
    '04330': 'Tuusula',
    '04340': 'Tuusula',
    '04350': 'Tuusula',
    '04360': 'Ruotsinkylä',
    '04370': 'Tuusula',
    '04380': 'Tuusula',
    '04390': 'Tuusula',
    '04500': 'Tuusula',
    '05400': 'Tuusula',
    '05430': 'Tuusula',
    '01200': 'Hakunila',
    '01230': 'Vaarala',
    '01260': 'Itä-Hakkila',
    '01280': 'Länsimäki',
    '01300': 'Koivuhaka',
    '01340': 'Leinelä',
    '01350': 'Hiekkaharju',
    '01360': 'Havukoski',
    '01370': 'Jokiniemi',
    '01380': 'Hakkila',
    '01390': 'Ilola',
    '01400': 'Asola',
    '01420': 'Päiväkumpu',
    '01450': 'Korso',
    '01480': 'Jokivarsi',
    '01490': 'Nikinmäki',
    '01510': 'Helsingin pitäjän kirkonkylä',
    '01520': 'Pakkala',
    '01530': 'Lentokenttä',
    '01600': 'Myyrmäki',
    '01610': 'Kaivoksela',
    '01620': 'Martinlaakso',
    '01630': 'Hämeenkylä',
    '01640': 'Hämevaara',
    '01650': 'Vapaala',
    '01660': 'Varisto',
    '01670': 'Vantaanlaakso',
    '01680': 'Askisto',
    '01690': 'Ylästö',
    '01700': 'Kivistö',
    '01710': 'Pähkinärinne',
    '01720': 'Petikko',
    '01730': 'Lapinkylä',
    '01740': 'Viinikkala',
    '01750': 'Keimola',
    '01760': 'Kiila',
    '01770': 'Sanomala',
    '03100': 'Vihti',
    '03150': 'Vihti',
    '03220': 'Vihti',
    '03250': 'Vihti',
    '03300': 'Vihti',
    '03320': 'Vihti',
    '03400': 'Vihti',
    '03430': 'Vihti',
    '03790': 'Vihti',
    'Kanta-Häme': 'Vihti',
    '30100': 'Forssa',
    '30300': 'Forssa',
    '30420': 'Forssa',
    '31110': 'Forssa',
    '31130': 'Forssa',
    '31170': 'Forssa',
    '13700': 'Hattula',
    '13720': 'Hattula',
    '13800': 'Hattula',
    '13880': 'Hattula',
    '13900': 'Hattula',
    '14430': 'Hattula',
    '14530': 'Hattula',
    '14610': 'Hattula',
    '14620': 'Hattula',
    '12100': 'Hausjärvi',
    '12130': 'Hausjärvi',
    '12170': 'Hausjärvi',
    '12210': 'Hausjärvi',
    '12240': 'Hausjärvi',
    '12310': 'Hausjärvi',
    '12350': 'Hausjärvi',
    '31640': 'Humppila',
    '31650': 'Humppila',
    '13100': 'Keskus',
    '13110': 'Kantola',
    '13130': 'Kauriala',
    '13200': 'Keinusaari',
    '13210': 'Hätilä',
    '13220': 'Aulanko',
    '13250': 'Katumajärvi',
    '13270': 'Ruununmylly',
    '13300': 'Käikälä',
    '13430': 'Hattelmala',
    '13500': 'Jukola',
    '13530': 'Ahvenisto',
    '13600': 'Ojoinen',
    '14300': 'Hämeenlinna',
    '14450': 'Hämeenlinna',
    '14500': 'Hämeenlinna',
    '14530': 'Hämeenlinna',
    '14680': 'Hämeenlinna',
    '14690': 'Hämeenlinna',
    '14700': 'Hämeenlinna',
    '14770': 'Hämeenlinna',
    '14810': 'Pohjoinen',
    '14820': 'Syrjäntaka',
    '14840': 'Sairiala',
    '14870': 'Juttila',
    '14930': 'Hämeenlinna',
    '16900': 'Hämeenlinna',
    '16960': 'Hämeenlinna',
    '16970': 'Hämeenlinna',
    '12380': 'Janakkala',
    '12400': 'Janakkala',
    '12450': 'Janakkala',
    '13330': 'Janakkala',
    '14140': 'Janakkala',
    '14200': 'Janakkala',
    '14240': 'Janakkala',
    '31600': 'Jokioinen',
    '31610': 'Jokioinen',
    '31620': 'Jokioinen',
    '31630': 'Jokioinen',
    '12520': 'Loppi',
    '12540': 'Loppi',
    '12600': 'Loppi',
    '12630': 'Loppi',
    '12640': 'Loppi',
    '12700': 'Loppi',
    '12750': 'Loppi',
    '12820': 'Loppi',
    '12920': 'Loppi',
    '12950': 'Loppi',
    '11100': 'Riihimäki',
    '11120': 'Riihimäki',
    '11130': 'Riihimäki',
    '11310': 'Riihimäki',
    '11710': 'Riihimäki',
    '11910': 'Riihimäki',
    '31230': 'Tammela',
    '31250': 'Tammela',
    '31300': 'Tammela',
    '31310': 'Tammela',
    '31340': 'Tammela',
    '31350': 'Tammela',
    '31370': 'Tammela',
    '31380': 'Tammela',
    '32100': 'Ypäjä',
    '32140': 'Ypäjä',
    'Päijät-Häme': 'Ypäjä',
    '17130': 'Asikkala',
    '17150': 'Asikkala',
    '17200': 'Asikkala',
    '17220': 'Asikkala',
    '17240': 'Asikkala',
    '17320': 'Asikkala',
    '17410': 'Asikkala',
    '17430': 'Asikkala',
    '17440': 'Asikkala',
    '17450': 'Asikkala',
    '19510': 'Hartola',
    '19540': 'Hartola',
    '19600': 'Hartola',
    '19610': 'Hartola',
    '19620': 'Hartola',
    '19630': 'Hartola',
    '19850': 'Hartola',
    '18100': 'keskusta',
    '18120': 'Rainio',
    '18130': 'Tommola',
    '18150': 'Jyränkö',
    '18200': 'Veljeskylä',
    '18300': 'Heinola',
    '18600': 'Heinola',
    '19110': 'Heinola',
    '19120': 'Heinola',
    '19210': 'Heinola',
    '19250': 'Heinola',
    '19260': 'Heinola',
    '15270': 'Hollola',
    '15820': 'Hollola',
    '15860': 'Kartano',
    '15870': 'Salpakangas',
    '15880': 'Soramäki',
    '15980': 'Hollola',
    '16500': 'Hollola',
    '16630': 'Hollola',
    '16710': 'Hollola',
    '16730': 'Hollola',
    '16790': 'Hollola',
    '16800': 'Hollola',
    '17110': 'Hollola',
    '17120': 'Hollola',
    '16540': 'Kärkölä',
    '16600': 'Kärkölä',
    '16610': 'Kärkölä',
    '16670': 'Kärkölä',
    '15100': 'keskustan eteläinen osa',
    '15110': 'keskustan läntinen osa',
    '15140': 'keskustan pohjoinen osa',
    '15150': 'Kujala',
    '15160': 'Karisto',
    '15170': 'Möysä',
    '15200': 'Kiveriö',
    '15210': 'Kivimaa',
    '15230': 'Holma',
    '15240': 'Kilpiäinen',
    '15300': 'Myllypohja',
    '15320': 'Ahtiala',
    '15340': 'Kunnas',
    '15460': 'Lahti',
    '15500': 'Liipola',
    '15520': 'Saksala',
    '15540': 'Lahti',
    '15550': 'Rakokivi',
    '15560': 'Kirkonkylä',
    '15580': 'Lahti',
    '15610': 'Laune',
    '15680': 'Renkomäki',
    '15700': 'Ala-Okeroinen',
    '15800': 'Kärpänen',
    '15810': 'Kasakkamäki',
    '15820': 'Okeroinen',
    '15830': 'Metsäkangas',
    '15840': 'Pirttiharju',
    '15850': 'Likolampi',
    '15900': 'Tapanila',
    '15950': 'Jalkaranta',
    '16100': 'Lahti',
    '16160': 'Lahti',
    '16200': 'Orimattila',
    '16230': 'Orimattila',
    '16260': 'Orimattila',
    '16270': 'Orimattila',
    '16280': 'Orimattila',
    '16300': 'Orimattila',
    '16310': 'Orimattila',
    '16320': 'Orimattila',
    '16330': 'Orimattila',
    '16350': 'Orimattila',
    '16390': 'Orimattila',
    '16450': 'Orimattila',
    '16510': 'Orimattila',
    '17470': 'Padasjoki',
    '17500': 'Padasjoki',
    '17510': 'Padasjoki',
    '17530': 'Padasjoki',
    '17610': 'Padasjoki',
    '17630': 'Padasjoki',
    '17710': 'Padasjoki',
    '17740': 'Padasjoki',
    '19230': 'Sysmä',
    '19310': 'Sysmä',
    '19320': 'Sysmä',
    '19370': 'Sysmä',
    '19700': 'Sysmä',
    '19730': 'Sysmä',
    '19740': 'Sysmä',
    '19770': 'Sysmä',
    '49400': 'Hamina',
    '49410': 'Hamina',
    '49420': 'Uusi-Summa',
    '49460': 'Hillo',
    '49480': 'Hamina',
    '49490': 'Hamina',
    '49510': 'Hamina',
    '49520': 'Hamina',
    '49540': 'Hamina',
    '49570': 'Hamina',
    '49610': 'Hamina',
    '49630': 'Hamina',
    '49640': 'Hamina',
    '49660': 'Hamina',
    '49780': 'Hamina',
    '49840': 'Hamina',
    '19160': 'Iitti',
    '47310': 'Iitti',
    '47400': 'Iitti',
    '47440': 'Iitti',
    '47450': 'Iitti',
    '47460': 'Iitti',
    '47490': 'Iitti',
    '47520': 'Iitti',
    '47540': 'Iitti',
    '47610': 'Iitti',
    '46930': 'Kotka',
    '48100': 'Kotka',
    '48130': 'Kotka',
    '48200': 'Kotka',
    '48210': 'Kotka',
    '48220': 'Kotka',
    '48230': 'Kotka',
    '48300': 'Kotka',
    '48310': 'Kotka',
    '48350': 'Kotka',
    '48400': 'Kotka',
    '48410': 'Kotka',
    '48600': 'Karhula',
    '48700': 'Kyminlinna',
    '48710': 'Karhula (Koivula)',
    '48720': 'Kymi',
    '48750': 'Nikeli',
    '48770': 'Karhula (Ristinkallio)',
    '48800': 'Karhula (Otsola)',
    '48810': 'Karhula (Saksala)',
    '48900': 'Sunila',
    '48910': 'Suulisniemi',
    '48930': 'Tiutinen',
    '49300': 'Kotka',
    '49340': 'Kotka',
    '45100': 'Kouvola',
    '45120': 'Kotkankallio',
    '45130': 'Kasarminmäki',
    '45150': 'Eskolanmäki',
    '45160': 'Lehtomäki',
    '45200': 'Tornionmäki',
    '45330': 'Kouvola',
    '45360': 'Jokela',
    '45370': 'Kirkonkylä',
    '45410': 'Kouvola',
    '45460': 'Kouvola',
    '45610': 'Kouvola',
    '45700': 'Kouvola',
    '45720': 'Kymintehdas',
    '45740': 'Mäkikylä',
    '45750': 'Kouvola',
    '45810': 'Kouvola',
    '45910': 'Kouvola',
    '45940': 'Kouvola',
    '46110': 'Kouvola',
    '46140': 'Kouvola',
    '46230': 'Aro',
    '46400': 'Kouvola',
    '46430': 'Kouvola',
    '46450': 'Kouvola',
    '46570': 'Kouvola',
    '46710': 'Kouvola',
    '46730': 'Kouvola',
    '46750': 'Kouvola',
    '46800': 'Kouvola',
    '46810': 'Kouvola',
    '46860': 'Kouvola',
    '46900': 'Kouvola',
    '46910': 'Kouvola',
    '46950': 'Kouvola',
    '46960': 'Kouvola',
    '47110': 'Kouvola',
    '47150': 'Kouvola',
    '47200': 'Kouvola',
    '47260': 'Kouvola',
    '47650': 'Kouvola',
    '47710': 'Kouvola',
    '47760': 'Kouvola',
    '47810': 'Kouvola',
    '47830': 'Kouvola',
    '47850': 'Kouvola',
    '47900': 'Kouvola',
    '47910': 'Kouvola',
    '52920': 'Kouvola',
    '49700': 'Miehikkälä',
    '49720': 'Miehikkälä',
    '49730': 'Miehikkälä',
    '49750': 'Miehikkälä',
    '49760': 'Miehikkälä',
    '49770': 'Miehikkälä',
    '54490': 'Miehikkälä',
    '49200': 'Pyhtää',
    '49210': 'Pyhtää',
    '49220': 'Pyhtää',
    '49240': 'Pyhtää',
    '49270': 'Pyhtää',
    '49290': 'Pyhtää',
    '49860': 'Virolahti',
    '49900': 'Virojoki',
    '49930': 'Virolahti',
    '49960': 'Virolahti',
    '49980': 'Virolahti',
    'Etelä-Karjala': 'Virolahti',
    '55100': 'Imatra keskus- Imatrankoski',
    '55120': 'Mansikkala-Tuulikallio',
    '55400': 'Tainionkoski-Mustalampi',
    '55420': 'Karhumäki-Karhukallio',
    '55510': 'Sotkulampi-Linnasuo',
    '55610': 'Rajapatsas-Teppanala',
    '55700': 'Vintteri-Virasoja',
    '55800': 'Vuoksenniska-Saarlampi',
    '55910': 'Rautio-Kurkvuori',
    '53100': 'Lappeenranta keskus',
    '53130': 'Keskussairaala-alue-Kimpinen',
    '53200': 'Kaukas-Tirilä',
    '53300': 'Lauritsala-Mälkiä',
    '53400': 'Laihia',
    '53420': 'Mustola',
    '53500': 'Parkkarila-Harapainen-Reijola',
    '53550': 'Kourulanmäki',
    '53600': 'Kesämäki',
    '53650': 'Mattila-Kuusela',
    '53810': 'Huhtiniemi-Kuusimäki-Lavola',
    '53830': 'Rutola',
    '53850': 'Sammonlahti-Skinnarila',
    '53900': 'Pallo-Tysterniemi-Leiri',
    '53920': 'Voisalmi',
    '53950': 'Kivisalmi',
    '54100': 'Lappeenranta',
    '54110': 'Lappeenranta',
    '54120': 'Lappeenranta',
    '54190': 'Lappeenranta',
    '54230': 'Lappeenranta',
    '54250': 'Lappeenranta',
    '54260': 'Hanhijärvi',
    '54270': 'Lappeenranta',
    '54300': 'Vilkjärvi',
    '54310': 'Lappeenranta',
    '54330': 'Lappeenranta',
    '54390': 'Lappeenranta',
    '54410': 'Lappeenranta',
    '54430': 'Lappeenranta',
    '54460': 'Lappeenranta',
    '55300': 'Lappeenranta',
    '55320': 'Lappeenranta',
    '55330': 'Lappeenranta',
    '54710': 'Lemi',
    '54720': 'Lemi',
    '54820': 'Lemi',
    '54850': 'Lemi',
    '46530': 'Luumäki',
    '54500': 'Luumäki',
    '54510': 'Luumäki',
    '54530': 'Luumäki',
    '54550': 'Luumäki',
    '54580': 'Luumäki',
    '54590': 'Luumäki',
    '59100': 'Parikkala',
    '59130': 'Parikkala',
    '59210': 'Parikkala',
    '59310': 'Parikkala',
    '59320': 'Parikkala',
    '59410': 'Parikkala',
    '59510': 'Akonpohja',
    '59520': 'Parikkala',
    '59530': 'Parikkala',
    '59610': 'Parikkala',
    '59710': 'Kummunkylä',
    '59720': 'Niukkala',
    '59730': 'Kirkonkylä',
    '56550': 'Rautjärvi',
    '56610': 'Rautjärvi',
    '56640': 'Rautjärvi',
    '56710': 'Rautjärvi',
    '56730': 'Rautjärvi',
    '56800': 'Rautjärvi',
    '56100': 'Ruokolahti',
    '56120': 'Ruokolahti',
    '56140': 'Ruokolahti',
    '56210': 'Ruokolahti',
    '56310': 'Ruokolahti',
    '56330': 'Ruokolahti',
    '56410': 'Ruokolahti',
    '56440': 'Ruokolahti',
    '56510': 'Ruokolahti',
    '54750': 'Savitaipale',
    '54770': 'Savitaipale',
    '54800': 'Savitaipale',
    '54950': 'Savitaipale',
    '54915': 'Taipalsaari',
    '54920': 'Taipalsaari',
    '54930': 'Taipalsaari',
    '54940': 'Taipalsaari',
    '54960': 'Taipalsaari',
    '56350': 'Taipalsaari',
    'Varsinais-Suomi': 'Taipalsaari',
    '21370': 'Aura',
    '21380': 'Aura',
    '20660': 'Kaarina',
    '20760': 'Kaarina',
    '20780': 'Kaarina',
    '21500': 'Kaarina',
    '21620': 'Kaarina',
    '25650': 'Kemiönsaari',
    '25700': 'Kemiönsaari',
    '25710': 'Kemiönsaari',
    '25730': 'Kemiönsaari',
    '25760': 'Kemiönsaari',
    '25810': 'Kemiönsaari',
    '25820': 'Kemiönsaari',
    '25830': 'Kemiönsaari',
    '25840': 'Kemiönsaari',
    '25860': 'Kemiönsaari',
    '25870': 'Kemiönsaari',
    '25900': 'Kemiönsaari',
    '25910': 'Kemiönsaari',
    '25930': 'Kemiönsaari',
    '25940': 'Kemiönsaari',
    '25950': 'Kemiönsaari',
    '25960': 'Kemiönsaari',
    'Koski': 'Kemiönsaari',
    '31500': 'Kemiönsaari',
    '23360': 'Kustavi',
    '23800': 'Laitila',
    '23840': 'Laitila',
    '23880': 'Laitila',
    '20660': 'Lieto',
    '21350': 'Lieto',
    '21360': 'Lieto',
    '21410': 'Lieto',
    '21420': 'Lieto',
    '21430': 'Lieto',
    '21450': 'Lieto',
    '31960': 'Loimaa',
    '32200': 'Loimaa',
    '32210': 'Hirvikoski (Loimaa kk)',
    '32250': 'Loimaa',
    '32260': 'Loimaa',
    '32270': 'Loimaa',
    '32280': 'Loimaa',
    '32300': 'Loimaa',
    '32410': 'Loimaa',
    '32430': 'Loimaa',
    '32440': 'Loimaa',
    '32450': 'Loimaa',
    '32560': 'Loimaa',
    '21480': 'Marttila',
    '21490': 'Marttila',
    '21560': 'Marttila',
    '21230': 'Masku',
    '21240': 'Masku',
    '21250': 'Masku',
    '23100': 'Mynämäki',
    '23120': 'Mynämäki',
    '23140': 'Mynämäki',
    '21100': 'Naantali keskus',
    '21110': 'Taimo-Nuhjala-Lietsala',
    '21130': 'Naantali',
    '21140': 'Naantali',
    '21150': 'Naantali',
    '21160': 'Naantali',
    '21180': 'Naantali',
    '21195': 'Naantali',
    '21270': 'Nousiainen',
    '32500': 'Oripää',
    '32520': 'Oripää',
    '21510': 'Paimio',
    '21520': 'Paimio',
    '21530': 'Paimio',
    '21540': 'Paimio',
    '21555': 'Paimio',
    '21600': 'Parainen',
    '21610': 'Parainen',
    '21630': 'Parainen',
    '21650': 'Parainen',
    '21660': 'Parainen',
    '21670': 'Parainen',
    '21680': 'Parainen',
    '21710': 'Parainen',
    '21720': 'Parainen',
    '21740': 'Parainen',
    '21750': 'Parainen',
    '21760': 'Parainen',
    '21770': 'Parainen',
    '23390': 'Parainen',
    '23950': 'Pyhäranta',
    '23960': 'Pyhäranta',
    '27320': 'Pyhäranta',
    '27340': 'Pyhäranta',
    '21800': 'Pöytyä',
    '21820': 'Pöytyä',
    '21840': 'Pöytyä',
    '21860': 'Pöytyä',
    '21870': 'Pöytyä',
    '21880': 'Pöytyä',
    '21890': 'Pöytyä',
    '21900': 'Pöytyä',
    '21930': 'Pöytyä',
    '21120': 'Vanto',
    '21200': 'Raisio keskus',
    '21210': 'Tikanmaa',
    '21220': 'Somersoja',
    '21260': 'Ihala',
    '21280': 'Kuloinen',
    '21290': 'Rusko',
    '21310': 'Rusko',
    '24100': 'Salo',
    '24130': 'Ylhäisi',
    '24240': 'Rappula',
    '24260': 'Ollikkala',
    '24280': 'Tupuri',
    '24800': 'Salo',
    '24910': 'Salo',
    '25110': 'Salo',
    '25130': 'Salo',
    '25160': 'Salo',
    '25170': 'Salo',
    '25180': 'Romsila',
    '25190': 'Inkere',
    '25210': 'Salo',
    '25230': 'Salo',
    '25240': 'Salo',
    '25250': 'Salo',
    '25260': 'Salo',
    '25320': 'Salo',
    '25330': 'Salo',
    '25340': 'Salo',
    '25360': 'Kaivola',
    '25370': 'Hiisi',
    '25380': 'Salo',
    '25390': 'Salo',
    '25410': 'Salo',
    '25420': 'Salo',
    '25430': 'Salo',
    '25460': 'Toija',
    '25470': 'Salo',
    '25500': 'Salo',
    '25520': 'Salo',
    '25540': 'Salo',
    '25550': 'Salo',
    '25560': 'Salo',
    '25570': 'Salo',
    '25610': 'Salo',
    '25630': 'Salo',
    '25640': 'Salo',
    '25660': 'Salo',
    '25760': 'Salo',
    '21570': 'Sauvo',
    '21590': 'Sauvo',
    '31400': 'Somero',
    '31410': 'Somero',
    '31450': 'Somero',
    '31460': 'Somero',
    '31470': 'Somero',
    '31490': 'Somero',
    '31520': 'Somero',
    '31530': 'Somero',
    '23310': 'Taivassalo',
    '20100': 'VI ja VII kaupunginosat',
    '20200': 'Iso-Heikkilä',
    '20210': 'Pahaniemi',
    '20240': 'Pansio',
    '20250': 'Pitkämäki',
    '20300': 'Raunistula',
    '20320': 'Hepokulta',
    '20360': 'Runosmäki',
    '20380': 'Räntämäki',
    '20400': 'Moisio',
    '20460': 'Jäkärlä',
    '20500': 'Yliopiston alue',
    '20520': 'Itäharju',
    '20540': 'Itäharju',
    '20610': 'Pääskyvuori',
    '20700': 'II kaupunginosa',
    '20720': 'Vasaramäki',
    '20730': 'Skanssi',
    '20740': 'Ilpoinen',
    '20750': 'Lauste',
    '20800': 'Virastotalo',
    '20810': 'Mäntymäki',
    '20880': 'Uittamo',
    '20900': 'Hirvensalo',
    '20960': 'Satava',
    '21330': 'Turku',
    '21340': 'Turku',
    '23450': 'Uusikaupunki',
    '23500': 'Uusikaupunki',
    '23600': 'Uusikaupunki',
    '23660': 'Uusikaupunki',
    '23930': 'Uusikaupunki',
    '23200': 'Vehmaa',
    '23210': 'Vehmaa',
    '23260': 'Vehmaa',
    '27400': 'Eura',
    '27420': 'Eura',
    '27430': 'Eura',
    '27450': 'Eura',
    '27500': 'Eura',
    '27510': 'Eura',
    '27600': 'Eura',
    '27640': 'Eura',
    '27650': 'Eura',
    '27670': 'Eura',
    '27100': 'Eurajoki',
    '27110': 'Irjanne',
    '27130': 'Eurajoki as',
    '27150': 'Lapijoki',
    '27160': 'Olkiluoto',
    '27170': 'Kuivalahti',
    '29100': 'Eurajoki',
    '29180': 'Eurajoki',
    '29200': 'Harjavalta',
    '32610': 'Huittinen',
    '32620': 'Huittinen',
    '32700': 'Lauttakylä',
    '32710': 'Huittinen',
    '32760': 'Huittinen',
    '32770': 'Huittinen',
    '38800': 'Jämijärvi',
    '38860': 'Jämijärvi',
    '38700': 'Kankaanpää',
    '38710': 'Myllymäki',
    '38720': 'Kankaanpää',
    '38740': 'Kankaanpää',
    '38750': 'Kankaanpää',
    '38760': 'Kankaanpää',
    '38770': 'Kankaanpää',
    '38840': 'Kankaanpää',
    '38910': 'Kankaanpää',
    '38920': 'Kankaanpää',
    '38950': 'Kankaanpää',
    '38970': 'Kankaanpää',
    '39920': 'Karvia',
    '39930': 'Karvia',
    '39940': 'Karvia',
    '39960': 'Karvia',
    '39965': 'Karvia',
    '39980': 'Karvia',
    '39990': 'Karvia',
    '32800': 'Kokemäki',
    '32810': 'Kokemäki',
    '32830': 'Kokemäki',
    '32860': 'Kokemäki',
    '32910': 'Kokemäki',
    '32920': 'Kokemäki',
    '29740': 'Merikarvia',
    '29750': 'Merikarvia',
    '29760': 'Merikarvia',
    '29790': 'Merikarvia',
    '29900': 'Merikarvia',
    '29940': 'Merikarvia',
    '29250': 'Nakkila',
    '29270': 'Nakkila',
    '29280': 'Nakkila',
    '29630': 'Pomarkku',
    '29640': 'Pomarkku',
    '38680': 'Pomarkku',
    '28100': 'Keskusta',
    '28120': 'Käppärä',
    '28130': 'Uusikoivisto',
    '28190': 'Pormestarinluoto',
    '28200': 'Toejoki',
    '28220': 'Ruosniemi',
    '28240': 'Isojoenranta',
    '28300': 'Kalaholma',
    '28330': 'Koivistonluoto',
    '28360': 'Vanhakoivisto',
    '28370': 'Sampola',
    '28430': 'Kartano',
    '28500': 'Keskussairaala-alue',
    '28540': 'Liikastenmäki',
    '28560': 'Lattomeri',
    '28580': 'Niittumaa',
    '28600': 'Vähärauma',
    '28610': 'Musa',
    '28660': 'Pietniemi',
    '28760': 'Kyläsaari',
    '28800': 'Pihlava',
    '28840': 'Kaanaa',
    '28880': 'Mäntyluoto',
    '28900': 'Reposaari',
    '29570': 'Pori',
    '29600': 'Pori',
    '29680': 'Pori',
    '29700': 'Pori',
    '29720': 'Pori',
    '38600': 'Pori',
    '38650': 'Pori',
    '38670': 'Pori',
    '26100': 'Rauma',
    '26200': 'Merirauma',
    '26410': 'Rauma',
    '26510': 'Uotila',
    '26560': 'Rauma',
    '26660': 'Paroalho',
    '26720': 'Rauma',
    '26740': 'Rauma',
    '26820': 'Sampaanala',
    '26840': 'Rauma',
    '26910': 'Rauma',
    '26930': 'Rauma',
    '26950': 'Rauma',
    '27220': 'Ala-Kieri',
    '27230': 'Rauma',
    '27250': 'Kodiksami',
    '27260': 'Kuolimaa',
    '27310': 'Rauma',
    '29810': 'Siikainen',
    '29830': 'Siikainen',
    '29860': 'Siikainen',
    '29880': 'Siikainen',
    '27710': 'Säkylä',
    '27730': 'Säkylä',
    '27740': 'Säkylä',
    '27750': 'Yttilä',
    '27800': 'Säkylä',
    '27820': 'Iso-Vimma',
    '27840': 'Kakkuri',
    '27860': 'Vuori',
    '27910': 'Huovinrinne',
    '27920': 'Otajoki',
    '28260': 'Ulvila',
    '28400': 'Ulvila',
    '28450': 'Ulvila',
    '29310': 'Ulvila',
    '29320': 'Ulvila',
    '29340': 'Ulvila',
    '29350': 'Ulvila',
    '37800': 'Toijala',
    '37830': 'Viiala',
    '37900': 'Kylmäkoski as',
    '37910': 'Kylmäkoski',
    '37960': 'Sotkia',
    '38490': 'Hämeenkyrö',
    '39100': 'Hämeenkyrö',
    '39110': 'Hämeenkyrö',
    '39130': 'Hämeenkyrö',
    '39170': 'Hämeenkyrö',
    '39180': 'Hämeenkyrö',
    '39195': 'Hämeenkyrö',
    '39200': 'Hämeenkyrö',
    '39230': 'Hämeenkyrö',
    '39290': 'Hämeenkyrö',
    '39360': 'Hämeenkyrö',
    '39380': 'Hämeenkyrö',
    '39410': 'Ikaalinen',
    '39430': 'Ikaalinen',
    '39450': 'Ikaalinen',
    '39500': 'Ikaalinen',
    '39530': 'Ikaalinen',
    '39580': 'Ikaalinen',
    '39590': 'Ikaalinen',
    '39610': 'Ikaalinen',
    '35500': 'Juupajoki',
    '35520': 'Juupajoki',
    '35530': 'Juupajoki',
    '35540': 'Juupajoki',
    '35550': 'Juupajoki',
    '36100': 'Kangasala',
    '36110': 'Kangasala',
    '36120': 'Kangasala',
    '36200': 'Kangasala',
    '36220': 'Suorama',
    '36240': 'Nattari',
    '36270': 'Huutijärvi',
    '36280': 'Kangasala',
    '36340': 'Kangasala',
    '36420': 'Kangasala',
    '36430': 'Pakkala',
    '36520': 'Kangasala',
    '36570': 'Kangasala',
    '36810': 'Kangasala',
    '36840': 'Kangasala',
    '36880': 'Kangasala',
    '39810': 'Kihniö',
    '39820': 'Kihniö',
    '39880': 'Kihniö',
    '33880': 'Sääksjärvi',
    '37500': 'Lempäälä',
    '37530': 'Säijä',
    '37550': 'Moisio',
    '37560': 'Kulju',
    '37570': 'Ideapark',
    'Mänttä-Vilppula': 'Lempäälä',
    '35700': 'Lempäälä',
    '35800': 'Lempäälä',
    '35820': 'Lempäälä',
    '35990': 'Lempäälä',
    '42830': 'Lempäälä',
    '42840': 'Lempäälä',
    '42850': 'Lempäälä',
    '33380': 'Nokia',
    '37100': 'Nokia',
    '37120': 'Koskenmäki',
    '37130': 'Alhoniitty',
    '37140': 'Harjuniitty',
    '37150': 'Kankaantaka',
    '37180': 'Nokia',
    '37200': 'Nokia',
    '37210': 'Nokia',
    '37240': 'Nokia',
    '37310': 'Nokia',
    '35100': 'Orivesi',
    '35220': 'Orivesi',
    '35240': 'Orivesi',
    '35260': 'Orivesi',
    '35270': 'Orivesi',
    '35300': 'Orivesi',
    '35320': 'Orivesi',
    '35420': 'Orivesi',
    '35480': 'Orivesi',
    '39620': 'Parkano',
    '39660': 'Parkano',
    '39700': 'Parkano',
    '39750': 'Parkano',
    '39770': 'Parkano',
    '39780': 'Parkano',
    '33920': 'Toivio',
    '33950': 'Killo',
    '33960': 'Pirkkala',
    '33980': 'Pirkkala',
    '31810': 'Punkalaidun',
    '31830': 'Punkalaidun',
    '31850': 'Punkalaidun',
    '31860': 'Punkalaidun',
    '31900': 'Punkalaidun',
    '31930': 'Punkalaidun',
    '31950': 'Punkalaidun',
    '31970': 'Punkalaidun',
    '14960': 'Pälkäne',
    '14980': 'Pälkäne',
    '36450': 'Pälkäne',
    '36600': 'Pälkäne',
    '36640': 'Pälkäne',
    '36660': 'Pälkäne',
    '36720': 'Pälkäne',
    '36760': 'Pälkäne',
    '36910': 'Pälkäne',
    '36930': 'Pälkäne',
    '34410': 'Ruovesi',
    '34420': 'Ruovesi',
    '34430': 'Ruovesi',
    '34450': 'Ruovesi',
    '34600': 'Ruovesi',
    '34640': 'Ruovesi',
    '34870': 'Ruovesi',
    '35740': 'Ruovesi',
    '35750': 'Ruovesi',
    '32730': 'Keikyä',
    '32740': 'Äetsä',
    '38100': 'Karkku',
    '38120': 'Kutala',
    '38130': 'Ellivuori',
    '38140': 'Kärppälä',
    '38200': 'Vammala',
    '38210': 'Vammala',
    '38220': 'Stormi',
    '38250': 'Roismala',
    '38270': 'Sammaljoki',
    '38280': 'Illo',
    '38300': 'Kiikka',
    '38360': 'Kiikoinen',
    '38420': 'Häijää',
    '38460': 'Mouhijärvi',
    '38470': 'Hyynilä',
    '38510': 'Suodenniemi',
    '33100': 'keskusta',
    '33180': 'Lappi',
    '33200': 'Kaakinmaa',
    '33210': 'Tammerkoski',
    '33230': 'Amuri',
    '33240': 'Tahmela',
    '33250': 'Ala-Pispala',
    '33270': 'Epilä',
    '33300': 'Rahola',
    '33310': 'Tesomajärvi',
    '33330': 'Kalkku',
    '33340': 'Ikuri',
    '33400': 'Lielahti',
    '33410': 'Lentävänniemi',
    '33420': 'Lamminpää',
    '33500': 'Petsamo',
    '33520': 'Kauppi',
    '33530': 'Kissanmaa',
    '33540': 'Kaleva',
    '33560': 'Janka',
    '33580': 'Atala',
    '33610': 'Olkahinen',
    '33680': 'Aitolahti',
    '33700': 'Messukylä',
    '33710': 'Kaukajärvi',
    '33720': 'Hervanta',
    '33730': 'Vehmainen',
    '33800': 'Nekala',
    '33820': 'Koivistonkylä',
    '33840': 'Lakalaiva',
    '33850': 'Multisilta',
    '33870': 'Vuores',
    '33900': 'Härmälä',
    '34240': 'Tampere',
    '34260': 'Tampere',
    '34270': 'Tampere',
    '31140': 'Urjala',
    '31160': 'Urjala',
    '31700': 'Urjala',
    '31720': 'Urjala',
    '31730': 'Urjala',
    '31750': 'Urjala',
    '31760': 'Urjala',
    '31830': 'Urjala',
    '31860': 'Urjala',
    '37600': 'Valkeakoski',
    '37630': 'Valkeakoski',
    '37700': 'Valkeakoski',
    '37720': 'Valkeakoski',
    '37740': 'Valkeakoski',
    '37770': 'Valkeakoski',
    '37850': 'Valkeakoski',
    '37860': 'Valkeakoski',
    '37350': 'Rämsöö',
    '37370': 'Narva',
    '37380': 'Krääkkiö',
    '37420': 'Valkkinen',
    '37470': 'Vesilahti',
    '34710': 'Virrat',
    '34730': 'Virrat',
    '34740': 'Virrat',
    '34770': 'Virrat',
    '34800': 'Virrat',
    '34910': 'Virrat',
    '34930': 'Virrat',
    '34950': 'Virrat',
    '34970': 'Virrat',
    '34980': 'Virrat',
    '42870': 'Virrat',
    '33430': 'Ylöjärvi',
    '33450': 'Ylöjärvi',
    '33470': 'Ylöjärvi',
    '33480': 'Ylöjärvi',
    '34110': 'Ylöjärvi',
    '34130': 'Ylöjärvi',
    '34140': 'Ylöjärvi',
    '34150': 'Ylöjärvi',
    '34180': 'Ylöjärvi',
    '34300': 'Ylöjärvi',
    '34320': 'Ylöjärvi',
    '34330': 'Ylöjärvi',
    '34510': 'Ylöjärvi',
    '34530': 'Ylöjärvi',
    '34550': 'Ylöjärvi',
    '39150': 'Ylöjärvi',
    '39160': 'Ylöjärvi',
    '39310': 'Ylöjärvi',
    '39340': 'Ylöjärvi',
    'Keski-Suomi': 'Ylöjärvi',
    '41490': 'Hankasalmi',
    '41500': 'Hankasalmi',
    '41520': 'Hankasalmi',
    '41530': 'Hankasalmi',
    '41540': 'Hankasalmi',
    '41550': 'Hankasalmi',
    '41560': 'Hankasalmi',
    '41580': 'Hankasalmi',
    '19650': 'Joutsa',
    '19670': 'Joutsa',
    '19920': 'Joutsa',
    '41710': 'Joutsa',
    '41730': 'Joutsa',
    '41750': 'Joutsa',
    '41770': 'Joutsa',
    '40100': 'keskusta',
    '40200': 'Taulumäki',
    '40250': 'Lohikoski',
    '40270': 'Jyväskylä',
    '40320': 'Seppälä',
    '40340': 'Huhtasuo',
    '40350': 'Jyväskylä',
    '40400': 'Halssila',
    '40420': 'Jyväskylä',
    '40500': 'Keljo',
    '40520': 'Kuokkala',
    '40530': 'Keljonkangas',
    '40600': 'Mattilanpelto',
    '40620': 'Kyllö',
    '40630': 'Kypärämäki',
    '40640': 'Keltinmäki',
    '40660': 'Ruoke',
    '40700': 'Mäki-Matti',
    '40720': 'Nisula',
    '40740': 'Kortepohja',
    '40800': 'Jyväskylä',
    '40820': 'Jyväskylä',
    '40900': 'Jyväskylä',
    '41120': 'Jyväskylä',
    '41140': 'Jyväskylä',
    '41160': 'Jyväskylä',
    '41450': 'Jyväskylä',
    '41630': 'Jyväskylä',
    '41800': 'Jyväskylä',
    '41820': 'Jyväskylä',
    '41840': 'Jyväskylä',
    '41860': 'Jyväskylä',
    '41870': 'Jyväskylä',
    '41880': 'Jyväskylä',
    '41930': 'Jyväskylä',
    '41940': 'Jyväskylä',
    '35400': 'Länkipohja',
    '35600': 'Jämsä',
    '35610': 'Jämsä',
    '35630': 'Jämsä',
    '42100': 'Jämsä',
    '42140': 'Jämsä',
    '42220': 'Jämsä',
    '42300': 'Jämsä',
    '42330': 'Jämsä',
    '42440': 'Jämsä',
    '43300': 'Kannonkoski',
    '43340': 'Kannonkoski',
    '43490': 'Karstula',
    '43500': 'Karstula',
    '43520': 'Karstula',
    '43540': 'Karstula',
    '43560': 'Karstula',
    '43610': 'Karstula',
    '43640': 'Karstula',
    '43660': 'Karstula',
    '41970': 'Keuruu',
    '42520': 'Keuruu',
    '42560': 'Keuruu',
    '42700': 'Keuruu',
    '42720': 'Keuruu',
    '42800': 'Keuruu',
    '42820': 'Keuruu',
    '42910': 'Keuruu',
    '42930': 'Keuruu',
    '43900': 'Kinnula',
    '43940': 'Kinnula',
    '43960': 'Kinnula',
    '43390': 'Kivijärvi',
    '43800': 'Kivijärvi',
    '43840': 'Kivijärvi',
    '44300': 'Konnevesi',
    '44320': 'Konnevesi',
    '44330': 'Konnevesi',
    '44350': 'Konnevesi',
    '44370': 'Mäntymäki',
    '17780': 'Kuhmoinen',
    '17800': 'Kuhmoinen',
    '17840': 'Kuhmoinen',
    '17850': 'Kuhmoinen',
    '17870': 'Kuhmoinen',
    '17930': 'Kuhmoinen',
    '17950': 'Kuhmoinen',
    '17970': 'Kuhmoinen',
    '43700': 'Kyyjärvi',
    '43720': 'Kyyjärvi',
    '41180': 'Laukaa',
    '41310': 'Laukaa',
    '41325': 'Laukaa',
    '41330': 'Laukaa',
    '41340': 'Laukaa',
    '41350': 'Laukaa',
    '41360': 'Laukaa',
    '41370': 'Laukaa',
    '41390': 'Laukaa',
    '41400': 'Laukaa',
    '19910': 'Luhanka',
    '19950': 'Luhanka',
    '42600': 'Multia',
    '42660': 'Multia',
    '43430': 'Multia',
    '40930': 'Muurame',
    '40950': 'Muurame',
    '41900': 'Petäjävesi',
    '41920': 'Petäjävesi',
    '41925': 'Petäjävesi',
    '41980': 'Petäjävesi',
    '44800': 'Pihtipudas',
    '44860': 'Pihtipudas',
    '44880': 'Pihtipudas',
    '44910': 'Pihtipudas',
    '44920': 'Pihtipudas',
    '44950': 'Pihtipudas',
    '44970': 'Pihtipudas',
    '41260': 'Saarijärvi',
    '41270': 'Saarijärvi',
    '43100': 'Saarijärvi',
    '43130': 'Saarijärvi',
    '43170': 'Saarijärvi',
    '43220': 'Saarijärvi',
    '43240': 'Saarijärvi',
    '43250': 'Saarijärvi',
    '43270': 'Saarijärvi',
    '43420': 'Saarijärvi',
    '43440': 'Saarijärvi',
    '43480': 'Saarijärvi',
    '41410': 'Toivakka',
    '41440': 'Toivakka',
    '41660': 'Toivakka',
    '41210': 'Uurainen',
    '41220': 'Uurainen',
    '41230': 'Uurainen',
    '41240': 'Uurainen',
    '41290': 'Uurainen',
    '44480': 'Viitasaari',
    '44500': 'Viitasaari',
    '44530': 'Viitasaari',
    '44580': 'Viitasaari',
    '44590': 'Viitasaari',
    '44610': 'Viitasaari',
    '44630': 'Viitasaari',
    '44640': 'Viitasaari',
    '44670': 'Viitasaari',
    '44680': 'Viitasaari',
    '44730': 'Viitasaari',
    '44740': 'Viitasaari',
    '44760': 'Viitasaari',
    '44770': 'Viitasaari',
    '44790': 'Viitasaari',
    '44100': 'Äänekoski',
    '44120': 'Äänekoski',
    '44150': 'Äänekoski',
    '44160': 'Äänekoski',
    '44170': 'Äänekoski',
    '44190': 'Äänekoski',
    '44200': 'Äänekoski',
    '44220': 'Äänekoski',
    '44250': 'Äänekoski',
    '44260': 'Äänekoski',
    '44280': 'Äänekoski',
    '44400': 'Äänekoski',
    '44420': 'Äänekoski',
    '44440': 'Äänekoski',
    '44460': 'Äänekoski',
    'Etelä-Pohjanmaa': 'Äänekoski',
    '62710': 'Alajärvi',
    '62720': 'Alajärvi',
    '62730': 'Alajärvi',
    '62740': 'Alajärvi',
    '62750': 'Alajärvi',
    '62760': 'Alajärvi',
    '62830': 'Alajärvi',
    '62900': 'Alajärvi',
    '62920': 'Alajärvi',
    '62940': 'Alajärvi',
    '62950': 'Alajärvi',
    '62990': 'Alajärvi',
    '63120': 'Alajärvi',
    '63500': 'Alajärvi',
    '63510': 'Alajärvi',
    '63530': 'Alajärvi',
    '63540': 'Alajärvi',
    '61140': 'Alavus',
    '61150': 'Alavus',
    '61170': 'Alavus',
    '61470': 'Alavus',
    '63160': 'Alavus',
    '63210': 'Alavus',
    '63300': 'Alavus',
    '63320': 'Alavus',
    '63330': 'Alavus',
    '63340': 'Alavus',
    '63350': 'Alavus',
    '63355': 'Alavus',
    '63360': 'Alavus',
    '63370': 'Alavus',
    '63400': 'Alavus',
    '63410': 'Alavus',
    '63430': 'Alavus',
    '63600': 'Alavus',
    '63610': 'Alavus',
    '63640': 'Alavus',
    '63680': 'Alavus',
    '62500': 'Evijärvi',
    '62510': 'Evijärvi',
    '62520': 'Evijärvi',
    '62540': 'Evijärvi',
    '62570': 'Evijärvi',
    '68840': 'Evijärvi',
    '60450': 'Ilmajoki',
    '60720': 'Ilmajoki',
    '60760': 'Ilmajoki',
    '60800': 'Ilmajoki',
    '61330': 'Ilmajoki',
    '61340': 'Ilmajoki',
    '61350': 'Ilmajoki',
    '64810': 'Isojoki',
    '64820': 'Isojoki',
    '64830': 'Isojoki',
    '64840': 'Isojoki',
    '64850': 'Isojoki',
    '64900': 'Isojoki',
    '64930': 'Isojoki',
    '64350': 'Karijoki',
    '64370': 'Karijoki',
    '64810': 'Karijoki',
    '61800': 'Kauhajoki',
    '61810': 'Kauhajoki',
    '61820': 'Kauhajoki',
    '61850': 'Kauhajoki',
    '61860': 'Kauhajoki',
    '61880': 'Kauhajoki',
    '61910': 'Kauhajoki',
    '61920': 'Kauhajoki',
    '61940': 'Kauhajoki',
    '61950': 'Kauhajoki',
    '61960': 'Kauhajoki',
    '61980': 'Kauhajoki',
    '62150': 'Kauhava',
    '62200': 'Kauhava',
    '62220': 'Kauhava',
    '62230': 'Kauhava',
    '62240': 'Kauhava',
    '62260': 'Kauhava',
    '62300': 'Kauhava',
    '62310': 'Kauhava',
    '62340': 'Kauhava',
    '62350': 'Kauhava',
    '62370': 'Kauhava',
    '62375': 'Kauhava',
    '62380': 'Kauhava',
    '62410': 'Kauhava',
    '62420': 'Kauhava',
    '62430': 'Kauhava',
    '62435': 'Kauhava',
    '62440': 'Kauhava',
    '62470': 'Kauhava',
    '63100': 'Kuortane',
    '63120': 'Kuortane',
    '63130': 'Kuortane',
    '63150': 'Kuortane',
    '63160': 'Kuortane',
    '63230': 'Kuortane',
    '63250': 'Kuortane',
    '63450': 'Kuortane',
    '61220': 'Kurikka',
    '61230': 'Kurikka',
    '61250': 'Kurikka',
    '61270': 'Kurikka',
    '61280': 'Kurikka',
    '61290': 'Kurikka',
    '61300': 'Kurikka',
    '61310': 'Kurikka',
    '61360': 'Kurikka',
    '61370': 'Kurikka',
    '61380': 'Kurikka',
    '61600': 'Kurikka',
    '61630': 'Kurikka',
    '61640': 'Kurikka',
    '61680': 'Kurikka',
    '61710': 'Kurikka',
    '61720': 'Kurikka',
    '61730': 'Kurikka',
    '61760': 'Kurikka',
    '66300': 'Kurikka',
    '66320': 'Kurikka',
    '66330': 'Kurikka',
    '66340': 'Kurikka',
    '66350': 'Kurikka',
    '66360': 'Kurikka',
    '66370': 'Kurikka',
    '62600': 'Lappajärvi',
    '62610': 'Lappajärvi',
    '62620': 'Lappajärvi',
    '62630': 'Lappajärvi',
    '62640': 'Lappajärvi',
    '62660': 'Lappajärvi',
    '62100': 'Lapua',
    '62130': 'Lapua',
    '62160': 'Lapua',
    '62165': 'Lapua',
    '62170': 'Lapua',
    '62175': 'Lapua',
    '62185': 'Lapua',
    '62190': 'Lapua',
    '62280': 'Lapua',
    '62290': 'Lapua',
    '62295': 'Lapua',
    '60100': 'keskusta',
    '60110': 'Seinäjoki',
    '60120': 'Pohja',
    '60150': 'Itä-Kivistö',
    '60200': 'Törnävä',
    '60220': 'Huhtala',
    '60280': 'Eskoo',
    '60320': 'Jouppi',
    '60420': 'Aunes',
    '60510': 'Seinäjoki',
    '60550': 'Seinäjoki',
    '60560': 'Seinäjoki',
    '60640': 'Seinäjoki',
    '61100': 'Seinäjoki',
    '61110': 'Seinäjoki',
    '61120': 'Seinäjoki',
    '61140': 'Seinäjoki',
    '61150': 'Seinäjoki',
    '61160': 'Seinäjoki',
    '61180': 'Seinäjoki',
    '61400': 'Seinäjoki',
    '61410': 'Seinäjoki',
    '61430': 'Seinäjoki',
    '61440': 'Seinäjoki',
    '61450': 'Seinäjoki',
    '61460': 'Seinäjoki',
    '61470': 'Seinäjoki',
    '61550': 'Seinäjoki',
    '61560': 'Seinäjoki',
    '61650': 'Seinäjoki',
    '63800': 'Soini',
    '63830': 'Soini',
    '63880': 'Soini',
    '63890': 'Soini',
    '63950': 'Soini',
    '61840': 'Teuva',
    '64700': 'Teuva',
    '64720': 'Teuva',
    '64740': 'Teuva',
    '64760': 'Teuva',
    '64770': 'Teuva',
    '62800': 'Vimpeli',
    '62810': 'Vimpeli',
    '62840': 'Vimpeli',
    '62860': 'Vimpeli',
    '62870': 'Vimpeli',
    '62880': 'Vimpeli',
    '63660': 'Ähtäri',
    '63700': 'Ähtäri',
    '63770': 'Ähtäri',
    '63780': 'Ähtäri',
    '63900': 'Ähtäri',
    '63910': 'Ähtäri',
    '63920': 'Ähtäri',
    '63950': 'Ähtäri',
    'Keski-Pohjanmaa': 'Ähtäri',
    '69510': 'Halsua',
    '69550': 'Halsua',
    '69570': 'Halsua',
    '69100': 'Kannus',
    '69150': 'Kannus',
    '69170': 'Kannus',
    '69600': 'Kaustinen',
    '69660': 'Kaustinen',
    '67100': 'keskusta',
    '67200': 'Leenala',
    '67300': 'Halkokari',
    '67400': 'Rytimäki',
    '67410': 'Vitsari',
    '67500': 'Rimmi',
    '67600': 'Kaustari',
    '67700': 'Kirkonmäki',
    '67800': 'Koivuhaka',
    '67900': 'Ykspihlaja',
    '68210': 'Kokkola',
    '68220': 'Kokkola',
    '68230': 'Kokkola',
    '68240': 'Kokkola',
    '68300': 'Kokkola',
    '68320': 'Kokkola',
    '68370': 'Kokkola',
    '68380': 'Kokkola',
    '68390': 'Kokkola',
    '68550': 'Kokkola',
    '68999': 'Kokkola',
    '69420': 'Lestijärvi',
    '69440': 'Lestijärvi',
    '69450': 'Lestijärvi',
    '69910': 'Perho',
    '69920': 'Perho',
    '69950': 'Perho',
    '69970': 'Perho',
    '69980': 'Perho',
    '69300': 'Toholampi',
    '69310': 'Toholampi',
    '69330': 'Toholampi',
    '69340': 'Toholampi',
    '69410': 'Toholampi',
    '69700': 'Veteli',
    '69730': 'Veteli',
    '69750': 'Veteli',
    '69820': 'Veteli',
    '69830': 'Veteli',
    '69850': 'Veteli',
    '61500': 'Isokyrö',
    '61520': 'Isokyrö',
    '61550': 'Isokyrö',
    '61560': 'Isokyrö',
    '64260': 'Kaskinen',
    '66200': 'Korsnäs',
    '66210': 'Korsnäs',
    '66230': 'Korsnäs',
    '66280': 'Korsnäs',
    '66290': 'Korsnäs',
    '64100': 'Kristiinankaupunki',
    '64140': 'Kristiinankaupunki',
    '64300': 'Kristiinankaupunki',
    '64320': 'Kristiinankaupunki',
    '64440': 'Kristiinankaupunki',
    '64450': 'Kristiinankaupunki',
    '64460': 'Kristiinankaupunki',
    '64480': 'Kristiinankaupunki',
    '64490': 'Kristiinankaupunki',
    '68410': 'Kruunupyy',
    '68500': 'Kruunupyy',
    '68700': 'Kruunupyy',
    '68750': 'Kruunupyy',
    '66400': 'Laihia',
    '66420': 'Laihia',
    '66430': 'Laihia',
    '66450': 'Laihia',
    '66460': 'Laihia',
    '66470': 'Laihia',
    '68555': 'Luoto',
    '68560': 'Luoto',
    '68570': 'Luoto',
    '68580': 'Furuholmen',
    '66100': 'Maalahti',
    '66140': 'Maalahti',
    '66160': 'Maalahti',
    '66220': 'Maalahti',
    '66240': 'Maalahti',
    '66260': 'Maalahti',
    '65450': 'Mustasaari',
    '65460': 'Mustasaari',
    '65470': 'Mustasaari',
    '65480': 'Mustasaari',
    '65520': 'Mustasaari',
    '65610': 'Sepänkylä',
    '65630': 'Mustasaari',
    '65650': 'Mustasaari',
    '65710': 'Mustasaari',
    '65730': 'Mustasaari',
    '65760': 'Mustasaari',
    '65800': 'Mustasaari',
    '65870': 'Mustasaari',
    '65920': 'Mustasaari',
    '65930': 'Mustasaari',
    '65970': 'Mustasaari',
    '66520': 'Mustasaari',
    '66530': 'Mustasaari',
    '66540': 'Mustasaari',
    '66550': 'Mustasaari',
    '66560': 'Mustasaari',
    '66580': 'Mustasaari',
    '66590': 'Mustasaari',
    '66999': 'Mustasaari',
    '64200': 'Närpiö',
    '64210': 'Närpiö',
    '64220': 'Närpiö',
    '64230': 'Närpiö',
    '64240': 'Närpiö',
    '64250': 'Närpiö',
    '64510': 'Närpiö',
    '64530': 'Närpiö',
    '64550': 'Närpiö',
    '64610': 'Närpiö',
    '66270': 'Närpiö',
    '66295': 'Närpiö',
    '68530': 'Pedersöre',
    '68690': 'Pedersöre',
    '68800': 'Pedersöre',
    '68810': 'Pedersöre',
    '68820': 'Pedersöre',
    '68830': 'Pedersöre',
    '68840': 'Pedersöre',
    '68870': 'Pedersöre',
    '68910': 'Pedersöre',
    '68920': 'Pedersöre',
    '68930': 'Pedersöre',
    '68940': 'Pedersöre',
    '68970': 'Pedersöre',
    '68600': 'Pietarsaari',
    '68620': 'Pietarsaari',
    '68630': 'Pietarsaari',
    '68660': 'Pietarsaari',
    '66840': 'Uusikaarlepyy',
    '66850': 'Uusikaarlepyy',
    '66900': 'Uusikaarlepyy',
    '66910': 'Uusikaarlepyy',
    '66930': 'Uusikaarlepyy',
    '66950': 'Uusikaarlepyy',
    '66970': 'Uusikaarlepyy',
    '66980': 'Uusikaarlepyy',
    '65100': 'keskusta',
    '65130': 'Hietalahti',
    '65170': 'Vaskiluoto',
    '65200': 'Palosaari',
    '65230': 'Vetokannas',
    '65280': 'Gerby',
    '65300': 'Kivihaka',
    '65320': 'Huutoniemi',
    '65350': 'Suvilahti',
    '65370': 'Ristinummi',
    '65380': 'Vanha Vaasa',
    '65410': 'Vaasa',
    '66430': 'Vaasa',
    '66440': 'Vaasa',
    '66500': 'Vaasa',
    '66510': 'Vaasa',
    '62395': 'Vöyri',
    '66600': 'Vöyri',
    '66640': 'Vöyri',
    '66660': 'Vöyri',
    '66680': 'Vöyri',
    '66710': 'Vöyri',
    '66730': 'Vöyri',
    '66800': 'Vöyri',
    '66810': 'Vöyri',
    '66820': 'Vöyri',
    '66830': 'Vöyri',
    'Etelä-Savo': 'Vöyri',
    '58160': 'Enonkoski',
    '58170': 'Enonkoski',
    '58175': 'Enonkoski',
    '58180': 'Enonkoski',
    '79700': 'Heinävesi',
    '79810': 'Heinävesi',
    '79820': 'Heinävesi',
    '79830': 'Heinävesi',
    '79850': 'Heinävesi',
    '79860': 'Heinävesi',
    '79885': 'Heinävesi',
    '79895': 'Heinävesi',
    '79910': 'Heinävesi',
    '79940': 'Heinävesi',
    '52520': 'Hirvensalmi',
    '52550': 'Hirvensalmi',
    '52610': 'Hirvensalmi',
    '52620': 'Hirvensalmi',
    '77460': 'Joroinen',
    '77480': 'Joroinen',
    '78880': 'Joroinen',
    '79600': 'Joroinen',
    '79620': 'Joroinen',
    '79630': 'Joroinen',
    '79660': 'Joroinen',
    '79680': 'Joroinen',
    '79690': 'Joroinen',
    '51760': 'Juva',
    '51780': 'Juva',
    '51810': 'Juva',
    '51820': 'Juva',
    '51850': 'Juva',
    '51860': 'Juva',
    '51880': 'Juva',
    '51890': 'Juva',
    '51900': 'Juva',
    '51930': 'Juva',
    '51940': 'Juva',
    '51980': 'Juva',
    '51200': 'Kangasniemi',
    '51260': 'Kangasniemi',
    '51270': 'Kangasniemi',
    '51310': 'Kangasniemi',
    '51335': 'Kangasniemi',
    '51340': 'Kangasniemi',
    '51360': 'Kangasniemi',
    '51380': 'Kangasniemi',
    '51430': 'Kangasniemi',
    '51440': 'Kangasniemi',
    '51460': 'Kangasniemi',
    '50100': 'Mikkeli',
    '50120': 'Mikkeli',
    '50130': 'Mikkeli',
    '50150': 'Mikkeli',
    '50160': 'Mikkeli',
    '50170': 'Mikkeli',
    '50180': 'Mikkeli',
    '50190': 'Mikkeli',
    '50200': 'Kovala',
    '50300': 'Rämälä',
    '50350': 'Mikkeli',
    '50500': 'Mikkeli',
    '50520': 'Mikkeli',
    '50600': 'Rantakylä',
    '50670': 'Mikkeli',
    '50700': 'Mikkeli',
    '50770': 'Mikkeli',
    '50800': 'Mikkeli',
    '50970': 'Ihastjärvi',
    '51130': 'Mikkeli',
    '51420': 'Mikkeli',
    '51450': 'Mikkeli',
    '51520': 'Mikkeli',
    '51540': 'Mikkeli',
    '51600': 'Mikkeli',
    '51620': 'Mikkeli',
    '51670': 'Mikkeli',
    '51720': 'Mikkeli',
    '51740': 'Mikkeli',
    '52100': 'Mikkeli',
    '52110': 'Mikkeli',
    '52150': 'Mikkeli',
    '52300': 'Mikkeli',
    '52320': 'Mikkeli',
    '52330': 'Mikkeli',
    '52340': 'Mikkeli',
    '52360': 'Mikkeli',
    '52420': 'Mikkeli',
    '52510': 'Mikkeli',
    '52780': 'Mikkeli',
    '52830': 'Mikkeli',
    '77380': 'Mikkeli',
    '52700': 'Mäntyharju',
    '52720': 'Mäntyharju',
    '52730': 'Mäntyharju',
    '52740': 'Mäntyharju',
    '52760': 'Mäntyharju',
    '52850': 'Mäntyharju',
    '52890': 'Mäntyharju',
    '52960': 'Mäntyharju',
    '52970': 'Mäntyharju',
    '52980': 'Mäntyharju',
    '19410': 'Pertunmaa',
    '19420': 'Pertunmaa',
    '19430': 'Pertunmaa',
    '19460': 'Pertunmaa',
    '19470': 'Pertunmaa',
    '19480': 'Pertunmaa',
    '76100': 'Pieksämäki',
    '76120': 'Pieksämäki',
    '76130': 'Pieksämäki',
    '76150': 'Pieksämäki',
    '76280': 'Pieksämäki',
    '76620': 'Pieksämäki',
    '76780': 'Pieksämäki',
    '76850': 'Pieksämäki',
    '76940': 'Pieksämäki',
    '77110': 'Pieksämäki',
    '77120': 'Pieksämäki',
    '77140': 'Pieksämäki',
    '77220': 'Pieksämäki',
    '77240': 'Pieksämäki',
    '77320': 'Pieksämäki',
    '77330': 'Pieksämäki',
    '77350': 'Pieksämäki',
    '77430': 'Pieksämäki',
    '77520': 'Pieksämäki',
    '77570': 'Pieksämäki',
    '77580': 'Pieksämäki',
    '52200': 'Puumala',
    '52230': 'Puumala',
    '52270': 'Puumala',
    '58830': 'Rantasalmi',
    '58850': 'Rantasalmi',
    '58900': 'Rantasalmi',
    '58910': 'Rantasalmi',
    '58920': 'Rantasalmi',
    '58940': 'Rantasalmi',
    '57100': 'Savonlinna',
    '57120': 'Savonlinna',
    '57130': 'Savonlinna',
    '57170': 'Savonlinna',
    '57200': 'Savonlinna',
    '57210': 'Nojanmaa',
    '57220': 'Savonlinna',
    '57230': 'Savonlinna',
    '57310': 'Rinkilä',
    '57510': 'Savonlinna',
    '57600': 'Poukkusilta',
    '57710': 'Kellarpelto',
    '57810': 'Aholahti',
    '58130': 'Savonlinna',
    '58140': 'Savonlinna',
    '58150': 'Savonlinna',
    '58200': 'Savonlinna',
    '58220': 'Savonlinna',
    '58260': 'Savonlinna',
    '58300': 'Savonlinna',
    '58320': 'Savonlinna',
    '58360': 'Savonlinna',
    '58390': 'Savonlinna',
    '58410': 'Savonlinna',
    '58420': 'Savonlinna',
    '58430': 'Savonlinna',
    '58450': 'Savonlinna',
    '58500': 'Punkasalmi',
    '58520': 'Savonlinna',
    '58550': 'Savonlinna',
    '58570': 'Savonlinna',
    '58580': 'Savonlinna',
    '58650': 'Savonlinna',
    '58680': 'Savonlinna',
    '58690': 'Savonlinna',
    '58770': 'Savonlinna',
    '58810': 'Savonlinna',
    '58610': 'Sulkava',
    '58620': 'Sulkava',
    '58700': 'Sulkava',
    '58720': 'Sulkava',
    'Pohjois-Savo': 'Sulkava',
    '74100': 'Iisalmi',
    '74120': 'Iisalmi',
    '74130': 'Kihmula',
    '74140': 'Ritoniemi',
    '74150': 'Iisalmi',
    '74160': 'Koljonvirta',
    '74170': 'Soinlahti',
    '74420': 'Iisalmi',
    '74510': 'Peltosalmi',
    '74520': 'Kirmanranta',
    '74540': 'Iisalmi',
    '74550': 'Iisalmi',
    '74590': 'Iisalmi',
    '74595': 'Iisalmi',
    '73600': 'Kaavi',
    '73620': 'Kaavi',
    '73640': 'Kaavi',
    '73645': 'Kaavi',
    '73670': 'Kaavi',
    '72600': 'Keitele',
    '72630': 'Keitele',
    '72930': 'Keitele',
    '72950': 'Keitele',
    '72980': 'Keitele',
    '74610': 'Kiuruvesi',
    '74630': 'Kiuruvesi',
    '74640': 'Kiuruvesi',
    '74670': 'Kiuruvesi',
    '74680': 'Kiuruvesi',
    '74700': 'Kiuruvesi',
    '74720': 'Kiuruvesi',
    '74740': 'Kiuruvesi',
    '74770': 'Kiuruvesi',
    '74840': 'Kiuruvesi',
    '74940': 'Kiuruvesi',
    '74980': 'Kiuruvesi',
    '70100': 'keskusta',
    '70110': 'keskusta',
    '70150': 'Neulamäki',
    '70200': 'Puijonlaakso',
    '70210': 'Itä-Suomen yliopisto',
    '70240': 'Niuvanniemi',
    '70260': 'Julkula',
    '70280': 'Rypysuo',
    '70300': 'Linnanpelto',
    '70340': 'Inkilänmäki',
    '70400': 'Kettulanlahti',
    '70420': 'Päiväranta',
    '70460': 'Kelloniemi',
    '70500': 'Itkonniemi',
    '70600': 'Niirala',
    '70620': 'Haapaniemi',
    '70700': 'Kolmisoppi',
    '70780': 'Jynkkä',
    '70800': 'Litmanen',
    '70820': 'Petonen',
    '70840': 'Saaristokaupunki',
    '70870': 'Kuopio',
    '71130': 'Kuopio',
    '71150': 'Kuopio',
    '71160': 'Kuopio',
    '71310': 'Kuopio',
    '71330': 'Kuopio',
    '71360': 'Kuopio',
    '71380': 'Kuopio',
    '71480': 'Kuopio',
    '71490': 'Kuopio',
    '71520': 'Kuopio',
    '71570': 'Kuopio',
    '71610': 'Kuopio',
    '71650': 'Kuopio',
    '71660': 'Kuopio',
    '71670': 'Kuopio',
    '71680': 'Kuopio',
    '71690': 'Kuopio',
    '71720': 'Kuopio',
    '71730': 'Kuopio',
    '71740': 'Kuopio',
    '71745': 'Kuopio',
    '71750': 'Kuopio',
    '71760': 'Kuopio',
    '71775': 'Kuopio',
    '71950': 'Kuopio',
    '72100': 'Kuopio',
    '72140': 'Kuopio',
    '72490': 'Kuopio',
    '73300': 'Kuopio',
    '73310': 'Kuopio',
    '73320': 'Reittiö',
    '73350': 'Kuopio',
    '73360': 'Kuopio',
    '73410': 'Kuopio',
    '73460': 'Kuopio',
    '73470': 'Kuopio',
    '73500': 'Kuopio',
    '73710': 'Kuopio',
    '73730': 'Kuopio',
    '73770': 'Kuopio',
    '73810': 'Kuopio',
    '73830': 'Kuopio',
    '71910': 'Lapinlahti',
    '71920': 'Lapinlahti',
    '71960': 'Lapinlahti',
    '73100': 'Lapinlahti',
    '73110': 'Lapinlahti',
    '73120': 'Lapinlahti',
    '73200': 'Lapinlahti',
    '73230': 'Lapinlahti',
    '73250': 'Lapinlahti',
    '74470': 'Lapinlahti',
    '71460': 'Leppävirta',
    '71470': 'Leppävirta',
    '78480': 'Leppävirta',
    '79100': 'Leppävirta',
    '79130': 'Leppävirta',
    '79140': 'Leppävirta',
    '79150': 'Leppävirta',
    '79160': 'Leppävirta',
    '79180': 'Leppävirta',
    '79190': 'Leppävirta',
    '79230': 'Leppävirta',
    '79255': 'Leppävirta',
    '79265': 'Leppävirta',
    '79330': 'Leppävirta',
    '79350': 'Leppävirta',
    '79410': 'Leppävirta',
    '72400': 'Pielavesi',
    '72430': 'Kumpula',
    '72510': 'Pielavesi',
    '72530': 'Pielavesi',
    '72550': 'Pielavesi',
    '72570': 'Pielavesi',
    '72710': 'Pielavesi',
    '72740': 'Pielavesi',
    '72810': 'Pielavesi',
    '72840': 'Pielavesi',
    '77700': 'Rautalampi',
    '77770': 'Rautalampi',
    '77910': 'Rautalampi',
    '77930': 'Rautalampi',
    '77960': 'Rautalampi',
    '73850': 'Rautavaara',
    '73860': 'Rautavaara',
    '73900': 'Rautavaara',
    '73970': 'Rautavaara',
    '73990': 'Rautavaara',
    '75680': 'Rautavaara',
    '70900': 'Siilinjärvi',
    '70910': 'Siilinjärvi',
    '70940': 'Siilinjärvi',
    '71800': 'Siilinjärvi',
    '71820': 'Siilinjärvi',
    '71840': 'Siilinjärvi',
    '71850': 'Siilinjärvi',
    '71870': 'Siilinjärvi',
    '71890': 'Siilinjärvi',
    '74300': 'Sonkajärvi',
    '74340': 'Sonkajärvi',
    '74345': 'Sonkajärvi',
    '74360': 'Sonkajärvi',
    '74380': 'Sonkajärvi',
    '74390': 'Sonkajärvi',
    '74490': 'Sonkajärvi',
    '77600': 'Suonenjoki',
    '77630': 'Suonenjoki',
    '77690': 'Suonenjoki',
    '77800': 'Suonenjoki',
    '71640': 'Tervo',
    '72210': 'Tervo',
    '72220': 'Tervo',
    '71170': 'Tuusniemi',
    '71200': 'Tuusniemi',
    '71210': 'Tuusniemi',
    '71240': 'Tuusniemi',
    '71260': 'Tuusniemi',
    '71280': 'Tuusniemi',
    '78200': 'Varkaus',
    '78210': 'Kuoppakangas',
    '78250': 'Päiviönsaari',
    '78300': 'Kommila',
    '78310': 'Puurtila',
    '78400': 'Varkaus',
    '78500': 'Könönpelto',
    '78610': 'Varkaus',
    '78710': 'Varkaus',
    '78850': 'Käpykangas',
    '78870': 'Kaura-aho',
    '78900': 'Varkaus',
    '79480': 'Varkaus',
    '79520': 'Varkaus',
    '72300': 'Vesanto',
    '72310': 'Vesanto',
    '72330': 'Vesanto',
    '72350': 'Vesanto',
    '72360': 'Vesanto',
    '72380': 'Vesanto',
    '74200': 'Vieremä',
    '74230': 'Vieremä',
    '74240': 'Vieremä',
    '74250': 'Vieremä',
    '74270': 'Vieremä',
    'Pohjois-Karjala': 'Vieremä',
    '81350': 'Ilomantsi',
    '81420': 'Ilomantsi',
    '81430': 'Ilomantsi',
    '81450': 'Ilomantsi',
    '81460': 'Ilomantsi',
    '81470': 'Ilomantsi',
    '82815': 'Ilomantsi',
    '82820': 'Ilomantsi',
    '82830': 'Ilomantsi',
    '82900': 'Ilomantsi',
    '82915': 'Ilomantsi',
    '82960': 'Ilomantsi',
    '82967': 'Ilomantsi',
    '82980': 'Ilomantsi',
    '80100': 'Joensuu',
    '80110': 'Joensuu',
    '80130': 'Joensuu',
    '80140': 'Joensuu',
    '80160': 'Joensuu',
    '80170': 'Joensuu',
    '80200': 'Joensuu',
    '80210': 'Joensuu',
    '80220': 'Joensuu',
    '80230': 'Joensuu',
    '80260': 'Joensuu',
    '80330': 'Joensuu',
    '81200': 'Joensuu',
    '81210': 'Joensuu',
    '81260': 'Joensuu',
    '81270': 'Joensuu',
    '81280': 'Joensuu',
    '81290': 'Joensuu',
    '81295': 'Joensuu',
    '81320': 'Joensuu',
    '81330': 'Joensuu',
    '81360': 'Joensuu',
    '81390': 'Joensuu',
    '82110': 'Joensuu',
    '82120': 'Joensuu',
    '82140': 'Joensuu',
    '82160': 'Joensuu',
    '82170': 'Joensuu',
    '82180': 'Joensuu',
    '82200': 'Joensuu',
    '82210': 'Joensuu',
    '82220': 'Joensuu',
    '82710': 'Joensuu',
    '82730': 'Joensuu',
    '82750': 'Joensuu',
    '82760': 'Joensuu',
    '82840': 'Joensuu',
    '82850': 'Joensuu',
    '82865': 'Joensuu',
    '82870': 'Joensuu',
    '82880': 'Joensuu',
    '83870': 'Juuka',
    '83880': 'Juuka',
    '83900': 'Juuka',
    '83910': 'Juuka',
    '83915': 'Juuka',
    '83940': 'Juuka',
    '83950': 'Juuka',
    '83985': 'Juuka',
    '58350': 'Kitee',
    '59800': 'Kitee',
    '59810': 'Kitee',
    '59820': 'Kitee',
    '82380': 'Kitee',
    '82395': 'Kitee',
    '82430': 'Kitee',
    '82460': 'Kitee',
    '82490': 'Kitee',
    '82500': 'Kitee',
    '82510': 'Kitee',
    '82580': 'Kitee',
    '82590': 'Kitee',
    '80510': 'Kontiolahti',
    '80710': 'Kontiolahti',
    '80770': 'Kontiolahti',
    '80780': 'Kontiolahti',
    '80790': 'Kontiolahti',
    '80850': 'Kontiolahti',
    '80910': 'Kontiolahti',
    '81100': 'Kontiolahti',
    '81120': 'Kontiolahti',
    '81160': 'Kontiolahti',
    '81220': 'Kontiolahti',
    '81230': 'Kontiolahti',
    '81235': 'Kontiolahti',
    '81560': 'Lieksa',
    '81570': 'Lieksa',
    '81590': 'Lieksa',
    '81650': 'Lieksa',
    '81660': 'Lieksa',
    '81700': 'Lieksa',
    '81720': 'Lieksa',
    '81750': 'Lieksa',
    '81810': 'Lieksa',
    '81820': 'Lieksa',
    '81850': 'Lieksa',
    '81860': 'Lieksa',
    '81950': 'Lieksa',
    '81970': 'Lieksa',
    '83960': 'Lieksa',
    '80400': 'Liperi',
    '83100': 'Liperi',
    '83130': 'Liperi',
    '83140': 'Liperi',
    '83150': 'Liperi',
    '83160': 'Liperi',
    '83320': 'Liperi',
    '83330': 'Liperi',
    '83340': 'Liperi',
    '83400': 'Liperi',
    '83430': 'Liperi',
    '83450': 'Liperi',
    '83460': 'Liperi',
    '83480': 'Liperi',
    '75500': 'Nurmes',
    '75530': 'Porokylä',
    '75650': 'Nurmes',
    '75680': 'Nurmes',
    '75700': 'Nurmes',
    '75710': 'Nurmes',
    '75740': 'Nurmes',
    '75770': 'Nurmes',
    '75790': 'Nurmes',
    '75840': 'Nurmes',
    '75890': 'Nurmes',
    '75930': 'Nurmes',
    '75940': 'Nurmes',
    '75970': 'Nurmes',
    '75990': 'Nurmes',
    '83500': 'Outokumpu',
    '83550': 'Outokumpu',
    '83630': 'Outokumpu',
    '83660': 'Outokumpu',
    '83700': 'Polvijärvi',
    '83720': 'Polvijärvi',
    '83750': 'Polvijärvi',
    '83760': 'Polvijärvi',
    '83780': 'Polvijärvi',
    '83825': 'Polvijärvi',
    '83830': 'Polvijärvi',
    '83835': 'Polvijärvi',
    '83840': 'Polvijärvi',
    '83855': 'Polvijärvi',
    '82290': 'Rääkkylä',
    '82300': 'Rääkkylä',
    '82310': 'Rääkkylä',
    '82335': 'Rääkkylä',
    '82350': 'Tohmajärvi',
    '82360': 'Tohmajärvi',
    '82600': 'Tohmajärvi',
    '82655': 'Tohmajärvi',
    '82660': 'Tohmajärvi',
    '82670': 'Tohmajärvi',
    '82675': 'Tohmajärvi',
    '82685': 'Tohmajärvi',
    '82770': 'Tohmajärvi',
    'Pohjois-Pohjanmaa': 'Tohmajärvi',
    '85200': 'Alavieska',
    '85210': 'Alavieska',
    '85230': 'Alavieska',
    '85710': 'Haapajärvi',
    '85730': 'Haapajärvi',
    '85800': 'Haapajärvi',
    '85820': 'Haapajärvi',
    '85840': 'Haapajärvi',
    '86480': 'Haapavesi',
    '86550': 'Haapavesi',
    '86600': 'Haapavesi',
    '86650': 'Haapavesi',
    '86680': 'Haapavesi',
    '86690': 'Haapavesi',
    '90480': 'Hailuoto',
    '91100': 'Ii',
    '91110': 'Ii',
    '91140': 'Ii',
    '91150': 'Ii',
    '91210': 'Ii',
    '95100': 'Ii',
    '95110': 'Ii',
    '95130': 'Ii',
    '95160': 'Ii',
    '68100': 'Kalajoki',
    '68150': 'Kalajoki',
    '85100': 'Kalajoki',
    '85120': 'Kalajoki',
    '85140': 'Kalajoki',
    '85150': 'Kalajoki',
    '85160': 'Kalajoki',
    '85180': 'Kalajoki',
    '90440': 'Kempele',
    '90450': 'Kempele',
    '93600': 'Kuusamo',
    '93700': 'Kuusamo',
    '93800': 'Kuusamo',
    '93830': 'Kuusamo',
    '93900': 'Kuusamo',
    '86710': 'Kärsämäki',
    '86790': 'Kärsämäki',
    '91900': 'Liminka',
    '91910': 'Liminka',
    '91930': 'Liminka',
    '91980': 'Lumijoki',
    '86220': 'Merijärvi',
    '86230': 'Merijärvi',
    '86240': 'Merijärvi',
    '91410': 'Muhos',
    '91430': 'Muhos',
    '91500': 'Muhos',
    '91510': 'Muhos',
    '91560': 'Muhos',
    '85500': 'Nivala',
    '85540': 'Mönkö',
    '85560': 'Ainastalo',
    '85580': 'Karvoskylä',
    '85620': 'Sarja',
    '85630': 'Sarjanahde',
    '85640': 'Maliskylä',
    '85660': 'Ruuskankylä',
    '86210': 'Oulainen',
    '86300': 'Oulainen',
    '86350': 'Oulainen',
    '86510': 'Oulainen',
    '86530': 'Oulainen',
    '90100': 'Keskusta',
    '90120': 'Heinäpää',
    '90130': 'Intiö',
    '90140': 'Karjasilta',
    '90150': 'Höyhtyä',
    '90220': 'Kontinkangas',
    '90230': 'Oulunsuu',
    '90240': 'Maikkula',
    '90250': 'Kaukovainio',
    '90310': 'Madekoski',
    '90400': 'Nuottasaari',
    '90410': 'Kiviniemi',
    '90420': 'Kaakkuri',
    '90460': 'Oulu',
    '90470': 'Oulu',
    '90500': 'Tuira',
    '90510': 'Hietasaari',
    '90520': 'Toppila',
    '90530': 'Alppila',
    '90540': 'Kuivasjärvi',
    '90550': 'Puolivälinkangas',
    '90560': 'Koskela',
    '90570': 'Kaijonharju',
    '90580': 'Rajakylä',
    '90590': 'Teknologiakylä',
    '90600': 'Postikeskus',
    '90620': 'Rusko',
    '90630': 'Korvensuora',
    '90650': 'Myllyoja',
    '90660': 'Sanginsuu',
    '90670': 'Hiukkavaara',
    '90800': 'Pateniemi',
    '90810': 'Oulu',
    '90820': 'Oulu',
    '90830': 'Oulu',
    '90840': 'Oulu',
    '90850': 'Oulu',
    '90860': 'Oulu',
    '90900': 'Oulu',
    '90910': 'Oulu',
    '90940': 'Oulu',
    '91200': 'Oulu',
    '91240': 'Oulu',
    '91260': 'Oulu',
    '91300': 'Oulu',
    '91310': 'Oulu',
    '93100': 'Pudasjärvi',
    '93140': 'Pudasjärvi',
    '93160': 'Pudasjärvi',
    '93170': 'Yli-Kollaja',
    '93180': 'Pudasjärvi',
    '93187': 'Pudasjärvi',
    '93190': 'Pudasjärvi',
    '93195': 'Pudasjärvi',
    '93220': 'Pudasjärvi',
    '93225': 'Pudasjärvi',
    '93240': 'Pudasjärvi',
    '93250': 'Pudasjärvi',
    '93270': 'Pudasjärvi',
    '93277': 'Pudasjärvi',
    '93280': 'Pudasjärvi',
    '93290': 'Pudasjärvi',
    '93350': 'Pudasjärvi',
    '93390': 'Pudasjärvi',
    '86100': 'Pyhäjoki',
    '86110': 'Pyhäjoki',
    '86160': 'Pyhäjoki',
    '86170': 'Pyhäjoki',
    '86800': 'Pyhäjärvi',
    '86810': 'Pyhäjärvi',
    '86850': 'Pyhäjärvi',
    '86870': 'Pyhäjärvi',
    '86900': 'Pyhäjärvi',
    '86980': 'Pyhäjärvi',
    '92910': 'Pyhäntä',
    '92920': 'Pyhäntä',
    '92930': 'Pyhäntä',
    '86360': 'Raahe',
    '86400': 'Raahe',
    '86440': 'Raahe',
    '86460': 'Raahe',
    '86470': 'Raahe',
    '92100': 'Raahe',
    '92120': 'Raahe',
    '92130': 'Raahe',
    '92140': 'Raahe',
    '92150': 'Raahe',
    '92160': 'Raahe',
    '92180': 'Raahe',
    '92210': 'Raahe',
    '92220': 'Raahe',
    '92230': 'Raahe',
    '92240': 'Raahe',
    '92260': 'Raahe',
    '85900': 'Reisjärvi',
    '85930': 'Reisjärvi',
    '85940': 'Reisjärvi',
    '85980': 'Reisjärvi',
    '85310': 'Sievi',
    '85320': 'Markkula',
    '85340': 'Jyrinki',
    '85410': 'Sievi',
    '85430': 'Koivisto',
    '85450': 'Joenkylä',
    '85470': 'Kiiskilampi',
    '92260': 'Siikajoki',
    '92320': 'Siikajoki',
    '92330': 'Siikajoki',
    '92350': 'Siikajoki',
    '92400': 'Siikajoki',
    '92430': 'Siikajoki',
    '92440': 'Siikajoki',
    '92450': 'Siikajoki',
    '92470': 'Siikajoki',
    '92500': 'Siikalatva',
    '92520': 'Siikalatva',
    '92530': 'Siikalatva',
    '92600': 'Siikalatva',
    '92610': 'Siikalatva',
    '92620': 'Siikalatva',
    '92640': 'Siikalatva',
    '92650': 'Siikalatva',
    '92700': 'Siikalatva',
    '93400': 'Taivalkoski',
    '93420': 'Taivalkoski',
    '93440': 'Taivalkoski',
    '93470': 'Taivalkoski',
    '93540': 'Taivalkoski',
    '93590': 'Taivalkoski',
    '91800': 'Tyrnävä',
    '91950': 'Tyrnävä',
    '91600': 'Utajärvi',
    '91620': 'Utajärvi',
    '91630': 'Utajärvi',
    '91640': 'Utajärvi',
    '91660': 'Utajärvi',
    '91670': 'Utajärvi',
    '88340': 'Vaala',
    '91700': 'Vaala',
    '91710': 'Vaala',
    '91720': 'Vaala',
    '91730': 'Vaala',
    '91740': 'Vaala',
    '91750': 'Vaala',
    '91760': 'Vaala',
    '91780': 'Vaala',
    '91790': 'Vaala',
    '92810': 'Vaala',
    '92830': 'Vaala',
    '84100': 'Ylivieska',
    '84460': 'Tuomiperä',
    '84540': 'Vähäkangas',
    '84650': 'Kantokylä',
    '84770': 'Isokoski',
    '84880': 'Raudaskylä',
    '89400': 'Hyrynsalmi',
    '89430': 'Hyrynsalmi',
    '89540': 'Hyrynsalmi',
    '89560': 'Hyrynsalmi',
    '87100': 'Kajaani',
    '87150': 'Kajaani',
    '87200': 'Kajaani',
    '87250': 'Kajaani',
    '87300': 'Kajaani',
    '87400': 'Kajaani',
    '87500': 'Kajaani',
    '87700': 'Kajaani',
    '87800': 'Kajaani',
    '87830': 'Kajaani',
    '87850': 'Kajaani',
    '87900': 'Kajaani',
    '87910': 'Kajaani',
    '87930': 'Kajaani',
    '87950': 'Kajaani',
    '87970': 'Kajaani',
    '88200': 'Kajaani',
    '88270': 'Kajaani',
    '88730': 'Kuhmo',
    '88740': 'Kuhmo',
    '88760': 'Kuhmo',
    '88820': 'Kuhmo',
    '88900': 'Kuhmo',
    '88930': 'Kuhmo',
    '88940': 'Kuhmo',
    '88300': 'Paltamo',
    '88310': 'Paltamo',
    '88350': 'Paltamo',
    '88380': 'Paltamo',
    '88470': 'Paltamo',
    '89110': 'Puolanka',
    '89140': 'Puolanka',
    '89200': 'Puolanka',
    '89210': 'Puolanka',
    '89320': 'Puolanka',
    '89330': 'Puolanka',
    '88400': 'Ristijärvi',
    '88440': 'Ristijärvi',
    '88460': 'Ristijärvi',
    '88120': 'Sotkamo',
    '88490': 'Sotkamo',
    '88530': 'Sotkamo',
    '88600': 'Sotkamo',
    '88610': 'Sotkamo',
    '88620': 'Sotkamo',
    '88640': 'Sotkamo',
    '88670': 'Sotkamo',
    '88690': 'Sotkamo',
    '89440': 'Suomussalmi',
    '89600': 'Ämmänsaari',
    '89610': 'Suomussalmi',
    '89640': 'Suomussalmi',
    '89660': 'Suomussalmi',
    '89670': 'Suomussalmi',
    '89680': 'Suomussalmi',
    '89730': 'Suomussalmi',
    '89740': 'Suomussalmi',
    '89760': 'Suomussalmi',
    '89770': 'Suomussalmi',
    '89780': 'Suomussalmi',
    '89800': 'Suomussalmi',
    '89830': 'Suomussalmi',
    '89840': 'Suomussalmi',
    '89920': 'Suomussalmi',
    '99400': 'Hetta',
    '99410': 'Enontekiö',
    '99420': 'Enontekiö',
    '99430': 'Enontekiö',
    '99440': 'Enontekiö',
    '99450': 'Enontekiö',
    '99460': 'Enontekiö',
    '99470': 'Enontekiö',
    '99490': 'Enontekiö',
    '99800': 'Inari',
    '99830': 'Inari',
    '99860': 'Inari',
    '99870': 'Inari',
    '99885': 'Inari',
    '99910': 'Inari',
    '99930': 'Inari',
    '99940': 'Inari',
    '94100': 'Kemi',
    '94200': 'Kemi',
    '94300': 'Kemi',
    '94600': 'Kemi',
    '94700': 'Kemi',
    '94720': 'Kemi',
    '94800': 'Veitsiluoto',
    '94830': 'Hepola',
    '94900': 'Ajos',
    '97590': 'Kemijärvi',
    '97645': 'Kemijärvi',
    '98100': 'Kemijärvi',
    '98120': 'Kemijärvi',
    '98230': 'Kemijärvi',
    '98310': 'Kemijärvi',
    '98350': 'Kemijärvi',
    '98360': 'Kemijärvi',
    '98400': 'Kemijärvi',
    '98420': 'Kemijärvi',
    '98440': 'Kemijärvi',
    '98450': 'Kemijärvi',
    '98710': 'Kemijärvi',
    '98720': 'Kemijärvi',
    '94400': 'Laurila',
    '94450': 'Keminmaa',
    '94500': 'Keminmaa',
    '95310': 'Keminmaa',
    '95315': 'Keminmaa',
    '95365': 'Keminmaa',
    '95370': 'Keminmaa',
    '97470': 'Kittilä',
    '99100': 'Kittilä',
    '99110': 'Kittilä',
    '99120': 'Kittilä',
    '99130': 'Kittilä',
    '99140': 'Kittilä',
    '99150': 'Kittilä',
    '99160': 'Kittilä',
    '99170': 'Kittilä',
    '99180': 'Kittilä',
    '99190': 'Kittilä',
    '99195': 'Kittilä',
    '99230': 'Kittilä',
    '99240': 'Kittilä',
    '99250': 'Kittilä',
    '99270': 'Kittilä',
    '99280': 'Kittilä',
    '99290': 'Kittilä',
    '99340': 'Kittilä',
    '95800': 'Kolari',
    '95830': 'Kolari',
    '95840': 'Kolari',
    '95850': 'Kolari',
    '95860': 'Kolari',
    '95900': 'Kolari',
    '95920': 'Kolari',
    '95930': 'Kolari',
    '95970': 'Kolari',
    '95980': 'Kolari',
    '95990': 'Kolari',
    '97430': 'Kolari',
    '95940': 'Muonio',
    '95950': 'Muonio',
    '99300': 'Muonio',
    '99310': 'Muonio',
    '99320': 'Muonio',
    '99330': 'Muonio',
    '99360': 'Muonio',
    '98500': 'Pelkosenniemi',
    '98510': 'Pelkosenniemi',
    '98530': 'Pelkosenniemi',
    '98550': 'Pelkosenniemi',
    '98560': 'Pelkosenniemi',
    '98570': 'Pelkosenniemi',
    '98580': 'Pelkosenniemi',
    '95640': 'Pello',
    '95645': 'Pello',
    '95700': 'Pello',
    '95710': 'Pello',
    '95720': 'Pello',
    '95750': 'Pello',
    '95760': 'Pello',
    '95770': 'Pello',
    '95780': 'Pello',
    '95790': 'Pello',
    '97330': 'Pello',
    '97335': 'Pello',
    '97810': 'Posio',
    '97815': 'Posio',
    '97820': 'Posio',
    '97840': 'Posio',
    '97850': 'Posio',
    '97870': 'Posio',
    '97880': 'Posio',
    '97890': 'Posio',
    '97895': 'Posio',
    '97900': 'Posio',
    '97920': 'Posio',
    '97925': 'Posio',
    '97940': 'Posio',
    '97950': 'Posio',
    '97960': 'Posio',
    '97965': 'Posio',
    '97970': 'Posio',
    '97980': 'Posio',
    '98780': 'Posio',
    '97665': 'Ranua',
    '97700': 'Ranua',
    '97715': 'Ranua',
    '97720': 'Ranua',
    '97760': 'Ranua',
    '97765': 'Ranua',
    '97780': 'Ranua',
    '97785': 'Ranua',
    '96100': 'Rovaniemi',
    '96190': 'Rovaniemi',
    '96200': 'Rovaniemi',
    '96300': 'Rovaniemi',
    '96320': 'Rovaniemi',
    '96400': 'Rovaniemi',
    '96440': 'Rovaniemi',
    '96460': 'Rovaniemi',
    '96500': 'Rovaniemi',
    '96600': 'Rovaniemi',
    '96700': 'Rovaniemi',
    '96800': 'Rovaniemi',
    '96900': 'Rovaniemi',
    '96910': 'Rovaniemi',
    '96930': 'Rovaniemi',
    '96960': 'Rovaniemi',
    '97110': 'Rovaniemi',
    '97130': 'Rovaniemi',
    '97140': 'Rovaniemi',
    '97145': 'Rovaniemi',
    '97160': 'Rovaniemi',
    '97170': 'Rovaniemi',
    '97220': 'Rovaniemi',
    '97240': 'Rovaniemi',
    '97280': 'Rovaniemi',
    '97290': 'Rovaniemi',
    '97310': 'Rovaniemi',
    '97320': 'Rovaniemi',
    '97340': 'Rovaniemi',
    '97420': 'Rovaniemi',
    '97510': 'Rovaniemi',
    '97520': 'Rovaniemi',
    '97530': 'Rovaniemi',
    '97540': 'Rovaniemi',
    '97580': 'Rovaniemi',
    '97610': 'Rovaniemi',
    '97615': 'Rovaniemi',
    '97620': 'Rovaniemi',
    '97625': 'Rovaniemi',
    '97630': 'Rovaniemi',
    '97635': 'Rovaniemi',
    '97650': 'Rovaniemi',
    '97655': 'Rovaniemi',
    '97670': 'Rovaniemi',
    '97675': 'Rovaniemi',
    '97680': 'Rovaniemi',
    '97685': 'Rovaniemi',
    '97690': 'Rovaniemi',
    '98600': 'Salla',
    '98620': 'Salla',
    '98630': 'Salla',
    '98660': 'Salla',
    '98760': 'Salla',
    '98770': 'Salla',
    '98790': 'Salla',
    '98900': 'Salla',
    '98920': 'Salla',
    '98930': 'Salla',
    '98950': 'Salla',
    '98960': 'Salla',
    '98970': 'Salla',
    '98980': 'Salla',
    '98995': 'Salla',
    '98800': 'Savukoski',
    '98810': 'Savukoski',
    '98820': 'Savukoski',
    '98830': 'Savukoski',
    '98840': 'Savukoski',
    '98850': 'Savukoski',
    '99640': 'Savukoski',
    '95200': 'Simo',
    '95210': 'Simo',
    '95220': 'Simo',
    '95225': 'Simo',
    '95230': 'Simo',
    '95255': 'Simo',
    '95260': 'Simo',
    '95270': 'Simo',
    '97370': 'Sodankylä',
    '97380': 'Sodankylä',
    '97390': 'Sodankylä',
    '99510': 'Sodankylä',
    '99520': 'Sodankylä',
    '99530': 'Sodankylä',
    '99540': 'Sodankylä',
    '99550': 'Sodankylä',
    '99555': 'Sodankylä',
    '99560': 'Sodankylä',
    '99600': 'Sodankylä',
    '99610': 'Sodankylä',
    '99620': 'Sodankylä',
    '99645': 'Sodankylä',
    '99650': 'Sodankylä',
    '99655': 'Sodankylä',
    '99660': 'Sodankylä',
    '99665': 'Sodankylä',
    '99670': 'Sodankylä',
    '99690': 'Sodankylä',
    '99695': 'Sodankylä',
    '99710': 'Sodankylä',
    '99720': 'Sodankylä',
    '99740': 'Sodankylä',
    '99750': 'Sodankylä',
    '99760': 'Sodankylä',
    '99770': 'Sodankylä',
    '95300': 'Tervola',
    '95325': 'Tervola',
    '95330': 'Kaisajoki',
    '95340': 'Tervola',
    '95350': 'Tervola',
    '95355': 'Tervola',
    '95375': 'Tervola',
    '94430': 'Kaakamo',
    '94460': 'Ruottala',
    '95400': 'Tornio',
    '95410': 'Kiviranta',
    '95420': 'Tornio',
    '95430': 'Tornio',
    '95440': 'Kyläjoki',
    '95450': 'Kokkokangas',
    '95460': 'Tornio',
    '95470': 'Pirkkiö',
    '95490': 'Tornio',
    '95500': 'Vojakkala',
    '95520': 'Kukkola',
    '95530': 'Karunki',
    '95540': 'Korpikylä',
    '95560': 'Aapajoki',
    '95590': 'Arpela',
    '99950': 'Utsjoki',
    '99970': 'Utsjoki',
    '99980': 'Utsjoki',
    '99990': 'Utsjoki',
    '95600': 'Ylitornio',
    '95610': 'Ylitornio',
    '95615': 'Ylitornio',
    '95620': 'Ylitornio',
    '95630': 'Ylitornio',
    '95635': 'Ylitornio',
    '95655': 'Ylitornio',
    '95660': 'Ylitornio',
    '95670': 'Ylitornio',
    '95675': 'Ylitornio',
    '95680': 'Ylitornio',
    '95690': 'Ylitornio',
    '97250': 'Ylitornio',
    '22840': 'Brändö',
    '22910': 'Brändö',
    '22920': 'Brändö',
    '22930': 'Brändö',
    '22940': 'Brändö',
    '22950': 'Brändö',
    '22270': 'Eckerö',
    '22220': 'Finström',
    '22310': 'Finström',
    '22330': 'Finström',
    '22410': 'Finström',
    '22710': 'Föglö',
    '22340': 'Geta',
    '22240': 'Hammarland',
    '22130': 'Jomala',
    '22140': 'Jomala',
    '22150': 'Jomala',
    '22810': 'Kumlinge',
    '22820': 'Kumlinge',
    '22830': 'Kumlinge',
    '22730': 'Kökar',
    '22610': 'Lemland',
    '22630': 'Lumparland',
    '22100': 'Maarianhamina',
    '22110': 'Maarianhamina',
    '22120': 'Maarianhamina',
    '22160': 'Maarianhamina',
    '22320': 'Saltvik',
    '22430': 'Saltvik',
    '22720': 'Sottunga',
    '22520': 'Sund',
    '22530': 'Sund',
    '22550': 'Vårdö',
    
     
  };

  return locations[zipCode] || 'Tuntematon alue';
};

const pricesPerSquareMeter = {
  "00100":6642,
  "00120":7202,
  "00130":7877,
  "00140":7599,
  "00150":7244,
  "00160":6077,
  "00170":7009,
  "00180":7148,
  "00190":4951,
  "00200":5602,
  "00210":5502,
  "00220":5255,
  "00240":4615,
  "00250":6155,
  "00260":6428,
  "00270":5951,
  "00280":5405,
  "00290":4064,
  "00300":5321,
  "00310":4238,
  "00320":4797,
  "00330":5466,
  "00340":4201,
  "00350":4553,
  "00360":3847,
  "00370":4141,
  "00380":4569,
  "00390":3329,
  "00400":4004,
  "00410":2664,
  "00420":3197,
  "00430":3381,
  "00440":3661,
  "00500":6027,
  "00510":5668,
  "00520":5242,
  "00530":5739,
  "00540":6562,
  "00550":5358,
  "00560":5323,
  "00570":5002,
  "00580":5557,
  "00590":3559,
  "00600":3077,
  "00610":5123,
  "00620":3933,
  "00630":3582,
  "00640":3362,
  "00650":3451,
  "00660":3557,
  "00670":3594,
  "00680":3520,
  "00690":3125,
  "00700":3260,
  "00710":2649,
  "00720":2987,
  "00730":3273,
  "00740":2543,
  "00750":2718,
  "00760":2948,
  "00770":2118,
  "00780":3214,
  "00790":4186,
  "00800":4549,
  "00810":4767,
  "00820":3159,
  "00830":3725,
  "00840":3468,
  "00850":4500,
  "00860":2702,
  "00870":3069,
  "00880":3177,
  "00890":2730,
  "00900":2983,
  "00910":3286,
  "00920":2714,
  "00930":3060,
  "00940":2202,
  "00950":3435,
  "00960":2992,
  "00970":2533,
  "00980":3207,
  "00990":4646,
  "01120":2187,
  "01150":2455,
  "01180":2090,
  "01190":2106,
  "01200":2137,
  "01230":2768,
  "01260":2628,
  "01280":2416,
  "01300":3228,
  "01340":3039,
  "01350":2778,
  "01360":2139,
  "01370":2986,
  "01380":3078,
  "01390":3179,
  "01400":2989,
  "01420":2938,
  "01450":2498,
  "01480":2137,
  "01490":2865,
  "01510":3841,
  "01520":3814,
  "01530":2287,
  "01600":2455,
  "01610":2984,
  "01620":2363,
  "01630":3005,
  "01640":3022,
  "01650":2171,
  "01660":2602,
  "01670":2811,
  "01680":2494,
  "01690":3365,
  "01700":3644,
  "01710":2584,
  "01720":2519,
  "01730":2154,
  "01740":2071,
  "01750":2144,
  "01760":2232,
  "01800":2341,
  "01810":2091,
  "01820":2186,
  "01830":2187,
  "01840":2367,
  "01860":2034,
  "01900":2008,
  "01940":2035,
  "02100":4932,
  "02110":4612,
  "02120":4443,
  "02130":4089,
  "02140":3142,
  "02150":4123,
  "02160":4609,
  "02170":4252,
  "02180":3669,
  "02200":4164,
  "02210":3043,
  "02230":3957,
  "02240":3981,
  "02260":2922,
  "02270":3506,
  "02280":3388,
  "02290":2596,
  "02300":3495,
  "02320":3056,
  "02330":3726,
  "02340":3154,
  "02360":2863,
  "02380":2408,
  "02400":2485,
  "02410":1460,
  "02420":2089,
  "02430":2372,
  "02440":2068,
  "02450":3050,
  "02460":2045,
  "02470":2001,
  "02480":2401,
  "02490":1346,
  "02510":1994,
  "02520":2070,
  "02540":2051,
  "02550":1969,
  "02570":1407,
  "02580":1842,
  "02590":1892,
  "02600":3750,
  "02610":3786,
  "02620":2772,
  "02630":3321,
  "02650":3703,
  "02660":3647,
  "02680":3668,
  "02700":3975,
  "02710":2591,
  "02720":3249,
  "02730":3246,
  "02740":3332,
  "02750":3683,
  "02760":2810,
  "02770":3023,
  "02780":2846,
  "02810":3035,
  "02820":2163,
  "02860":2295,
  "02880":2470,
  "02920":2814,
  "02940":2955,
  "02970":2758,
  "02980":2104,
  "03100":1975,
  "03150":1535,
  "03220":1470,
  "03250":1622,
  "03300":1466,
  "03320":1400,
  "03400":1677,
  "03430":1412,
  "03600":1367,
  "03620":1062,
  "03710":1265,
  "03790":1324,
  "03810":1381,
  "03850":1328,
  "03870":1361,
  "04130":2184,
  "04150":1890,
  "04170":1781,
  "04200":2435,
  "04220":2497,
  "04230":2078,
  "04240":1838,
  "04250":2440,
  "04260":2020,
  "04300":2346,
  "04310":2500,
  "04320":2085,
  "04330":2434,
  "04340":1896,
  "04350":1777,
  "04360":1854,
  "04370":1850,
  "04380":1803,
  "04390":1804,
  "04400":2499,
  "04410":2340,
  "04420":2392,
  "04430":2602,
  "04440":1726,
  "04460":2446,
  "04480":1954,
  "04500":1945,
  "04530":1674,
  "04600":1813,
  "04620":2103,
  "04630":1606,
  "04660":1674,
  "04680":1629,
  "04740":1616,
  "04770":1545,
  "04820":1607,
  "04840":1563,
  "04920":1638,
  "04940":1648,
  "05100":1831,
  "05200":1884,
  "05250":1422,
  "05400":1975,
  "05430":1519,
  "05440":1542,
  "05450":1436,
  "05460":1791,
  "05470":1409,
  "05510":962,
  "05620":1448,
  "05720":1247,
  "05800":2057,
  "05810":2403,
  "05820":1845,
  "05830":2001,
  "05840":1695,
  "05860":1280,
  "05880":1296,
  "05900":1814,
  "05950":1235,
  "06100":2759,
  "06150":2025,
  "06200":1573,
  "06400":1979,
  "06450":1841,
  "06500":1720,
  "06530":2038,
  "06650":2078,
  "06750":2330,
  "06830":1663,
  "06850":1271,
  "06880":1568,
  "06950":1525,
  "07110":1457,
  "07130":1421,
  "07150":1409,
  "07170":1833,
  "07190":1436,
  "07220":1387,
  "07230":1744,
  "07280":1391,
  "07310":1337,
  "07320":1320,
  "07350":1339,
  "07360":1275,
  "07370":1280,
  "07390":1298,
  "07410":1406,
  "07450":1361,
  "07500":1590,
  "07510":1393,
  "07530":1323,
  "07550":1361,
  "07560":1367,
  "07565":1314,
  "07580":1316,
  "07590":1376,
  "07600":1290,
  "07680":1282,
  "07690":1240,
  "07700":1873,
  "07720":1300,
  "07740":1332,
  "07750":1342,
  "07780":1325,
  "07800":1071,
  "07810":1199,
  "07820":1212,
  "07830":1202,
  "07840":1192,
  "07850":1206,
  "07870":1216,
  "07880":1231,
  "07890":1192,
  "07900":1576,
  "07910":870,
  "07920":1191,
  "07930":1171,
  "07940":985,
  "07945":1161,
  "07955":1172,
  "07960":1136,
  "07970":1111,
  "07980":1124,
  "07990":1129,
  "08100":2203,
  "08150":2097,
  "08200":1335,
  "08350":1486,
  "08450":1431,
  "08480":1436,
  "08500":1774,
  "08680":1807,
  "08700":1564,
  "08800":1415,
  "09120":1222,
  "09220":1578,
  "09430":1304,
  "09520":1183,
  "09630":1237,
  "09810":1257,
  "09930":1152,
  "10120":1158,
  "10140":1176,
  "10160":1168,
  "10210":1606,
  "10230":1232,
  "10250":1184,
  "10270":1162,
  "10300":1403,
  "10320":1319,
  "10330":1313,
  "10350":1265,
  "10360":1183,
  "10380":1125,
  "10410":1198,
  "10420":872,
  "10440":1184,
  "10470":1126,
  "10480":1038,
  "10520":1008,
  "10570":1088,
  "10600":2079,
  "10620":1115,
  "10640":1635,
  "10650":1568,
  "10660":985,
  "10680":1206,
  "10710":1233,
  "10820":751,
  "10900":1484,
  "10940":1629,
  "10960":1409,
  "11100":1636,
  "11120":1464,
  "11130":1571,
  "11310":1353,
  "11710":1674,
  "11910":1757,
  "12100":1062,
  "12130":1029,
  "12170":999,
  "12210":1104,
  "12240":1154,
  "12310":1268,
  "12350":1077,
  "12380":1118,
  "12400":1076,
  "12450":1006,
  "12520":1159,
  "12540":1230,
  "12600":920,
  "12630":1043,
  "12640":1099,
  "12700":1423,
  "12750":1015,
  "12820":946,
  "12920":1003,
  "12950":976,
  "13100":2203,
  "13110":1097,
  "13130":1937,
  "13200":2350,
  "13210":1643,
  "13220":2547,
  "13250":1100,
  "13270":2116,
  "13300":1773,
  "13330":1376,
  "13430":1580,
  "13500":1570,
  "13530":1251,
  "13600":1442,
  "13700":853,
  "13720":1542,
  "13800":1626,
  "13880":1693,
  "13900":1379,
  "14140":1128,
  "14200":1282,
  "14240":1195,
  "14300":1196,
  "14330":1273,
  "14370":1132,
  "14430":1021,
  "14450":1035,
  "14500":1366,
  "14530":1172,
  "14610":1222,
  "14620":1156,
  "14680":1138,
  "14690":1072,
  "14700":998,
  "14770":1152,
  "14810":1179,
  "14820":1183,
  "14840":1150,
  "14870":1118,
  "14930":1057,
  "14960":1080,
  "14980":1042,
  "15100":2242,
  "15110":2461,
  "15140":2571,
  "15150":1765,
  "15160":2237,
  "15170":1144,
  "15200":1389,
  "15210":1395,
  "15230":1567,
  "15240":1193,
  "15270":1870,
  "15300":1488,
  "15320":1705,
  "15340":1777,
  "15460":1329,
  "15500":1061,
  "15520":1435,
  "15540":1542,
  "15550":1114,
  "15560":1032,
  "15580":1187,
  "15610":1961,
  "15680":2075,
  "15700":1696,
  "15800":1666,
  "15810":1454,
  "15820":1336,
  "15830":1120,
  "15840":1214,
  "15850":1896,
  "15860":1162,
  "15870":1412,
  "15880":1293,
  "15900":2457,
  "15950":1566,
  "16100":557,
  "16160":789,
  "16200":928,
  "16230":886,
  "16260":883,
  "16270":916,
  "16280":918,
  "16300":1250,
  "16310":1022,
  "16320":1066,
  "16330":972,
  "16350":964,
  "16390":954,
  "16450":931,
  "16500":989,
  "16510":923,
  "16540":916,
  "16600":964,
  "16610":1305,
  "16630":981,
  "16670":984,
  "16710":1046,
  "16730":970,
  "16790":882,
  "16800":946,
  "16900":969,
  "16960":914,
  "16970":806,
  "16980":892,
  "17110":1078,
  "17120":946,
  "17130":976,
  "17150":892,
  "17200":1194,
  "17220":897,
  "17240":905,
  "17320":886,
  "17410":897,
  "17430":925,
  "17440":952,
  "17450":891,
  "17470":914,
  "17500":836,
  "17510":867,
  "17530":862,
  "17610":869,
  "17630":850,
  "17710":837,
  "17740":832,
  "17780":851,
  "17800":785,
  "17840":864,
  "17850":827,
  "17870":835,
  "17930":819,
  "17950":786,
  "17970":822,
  "18100":1338,
  "18120":1124,
  "18130":762,
  "18150":844,
  "18200":674,
  "18300":726,
  "18600":828,
  "19110":852,
  "19120":842,
  "19160":791,
  "19210":793,
  "19230":738,
  "19250":753,
  "19260":764,
  "19310":760,
  "19320":758,
  "19370":782,
  "19410":778,
  "19420":750,
  "19430":711,
  "19460":729,
  "19470":761,
  "19480":749,
  "19510":749,
  "19540":750,
  "19600":720,
  "19610":785,
  "19620":784,
  "19630":784,
  "19650":896,
  "19670":762,
  "19700":778,
  "19730":816,
  "19740":740,
  "19770":787,
  "19850":733,
  "19910":784,
  "19920":773,
  "19950":757,
  "20100":3293,
  "20200":2240,
  "20210":1198,
  "20240":1156,
  "20250":1679,
  "20300":2340,
  "20320":1823,
  "20360":1332,
  "20380":2188,
  "20400":2025,
  "20460":1114,
  "20500":3320,
  "20520":3249,
  "20540":1873,
  "20610":1244,
  "20660":2227,
  "20700":3057,
  "20720":2171,
  "20730":2823,
  "20740":1745,
  "20750":1190,
  "20760":1809,
  "20780":1916,
  "20810":3088,
  "20880":1858,
  "20900":2591,
  "20960":1822,
  "21100":2108,
  "21110":1769,
  "21120":1545,
  "21130":1304,
  "21140":938,
  "21150":1120,
  "21160":1970,
  "21180":1185,
  "21195":1193,
  "21200":1566,
  "21210":1441,
  "21220":1303,
  "21230":1598,
  "21240":1337,
  "21250":1846,
  "21260":1620,
  "21270":1524,
  "21280":2312,
  "21290":1924,
  "21310":1489,
  "21330":1558,
  "21340":1295,
  "21350":2220,
  "21360":1582,
  "21370":1359,
  "21380":1387,
  "21410":2390,
  "21420":1830,
  "21430":1250,
  "21450":1044,
  "21480":1164,
  "21490":1029,
  "21500":1609,
  "21510":1222,
  "21520":1443,
  "21530":1344,
  "21540":1382,
  "21555":1229,
  "21560":1139,
  "21570":1180,
  "21590":1149,
  "21600":1307,
  "21610":1443,
  "21620":1915,
  "21630":1270,
  "21650":1190,
  "21660":1325,
  "21670":1198,
  "21680":999,
  "21710":1138,
  "21720":1212,
  "21740":1221,
  "21750":1044,
  "21760":1108,
  "21770":1154,
  "21800":1004,
  "21820":1064,
  "21840":1164,
  "21860":1168,
  "21870":1107,
  "21880":1122,
  "21890":1062,
  "21900":926,
  "21930":1060,
  "22100":1354,
  "22130":1021,
  "22150":1084,
  "22220":1000,
  "22240":1006,
  "22270":989,
  "22310":1018,
  "22320":1033,
  "22330":992,
  "22340":957,
  "22410":1110,
  "22430":981,
  "22520":998,
  "22530":992,
  "22550":952,
  "22610":1030,
  "22630":1004,
  "22710":952,
  "22720":968,
  "22730":972,
  "22820":953,
  "22830":965,
  "22840":940,
  "22910":1020,
  "22920":979,
  "22930":934,
  "22940":975,
  "22950":967,
  "23100":1291,
  "23120":1215,
  "23140":1068,
  "23200":959,
  "23210":902,
  "23260":922,
  "23310":1046,
  "23360":910,
  "23390":865,
  "23450":682,
  "23500":1103,
  "23600":955,
  "23660":896,
  "23800":1112,
  "23840":1015,
  "23880":1080,
  "23930":870,
  "23950":699,
  "23960":893,
  "24100":1351,
  "24130":1135,
  "24240":1050,
  "24260":965,
  "24280":1323,
  "24800":1150,
  "24910":1140,
  "25110":852,
  "25130":1021,
  "25160":870,
  "25170":859,
  "25180":873,
  "25190":831,
  "25210":970,
  "25230":882,
  "25240":862,
  "25250":914,
  "25260":846,
  "25320":846,
  "25330":895,
  "25340":850,
  "25360":1030,
  "25370":844,
  "25380":874,
  "25390":634,
  "25410":891,
  "25420":855,
  "25430":810,
  "25460":802,
  "25470":813,
  "25500":991,
  "25520":904,
  "25540":878,
  "25550":864,
  "25560":865,
  "25570":914,
  "25610":869,
  "25630":913,
  "25640":866,
  "25650":876,
  "25660":896,
  "25700":1042,
  "25710":953,
  "25730":876,
  "25760":903,
  "25810":834,
  "25820":878,
  "25830":870,
  "25840":868,
  "25860":874,
  "25870":832,
  "25900":973,
  "25910":819,
  "25930":890,
  "25940":864,
  "25950":874,
  "25960":844,
  "26100":1352,
  "26200":1346,
  "26410":1096,
  "26510":1029,
  "26560":986,
  "26660":1096,
  "26720":1149,
  "26740":1017,
  "26820":952,
  "26840":1319,
  "26910":1081,
  "26930":989,
  "26950":998,
  "27100":1271,
  "27110":877,
  "27130":857,
  "27150":935,
  "27170":853,
  "27220":840,
  "27230":952,
  "27250":827,
  "27260":786,
  "27310":800,
  "27320":1051,
  "27340":855,
  "27400":865,
  "27420":846,
  "27430":750,
  "27450":803,
  "27500":1070,
  "27510":1117,
  "27600":727,
  "27640":796,
  "27650":746,
  "27670":773,
  "27710":635,
  "27730":790,
  "27740":756,
  "27750":733,
  "27800":1200,
  "27820":980,
  "27840":959,
  "27860":839,
  "27910":457,
  "27920":698,
  "28100":1907,
  "28120":1297,
  "28130":1530,
  "28190":904,
  "28200":1391,
  "28220":1558,
  "28240":1259,
  "28260":1094,
  "28300":1608,
  "28330":850,
  "28360":1225,
  "28370":724,
  "28400":1264,
  "28430":1399,
  "28450":1024,
  "28500":1136,
  "28540":1768,
  "28560":1074,
  "28580":1124,
  "28600":1534,
  "28610":1336,
  "28660":1452,
  "28760":1043,
  "28800":635,
  "28840":533,
  "28880":867,
  "28900":1055,
  "29100":1063,
  "29180":808,
  "29200":797,
  "29250":865,
  "29270":790,
  "29280":784,
  "29310":795,
  "29320":754,
  "29340":591,
  "29350":771,
  "29570":859,
  "29600":984,
  "29630":745,
  "29640":779,
  "29680":762,
  "29700":789,
  "29720":792,
  "29740":774,
  "29750":799,
  "29760":768,
  "29790":766,
  "29810":758,
  "29830":763,
  "29860":768,
  "29880":748,
  "29900":775,
  "29940":731,
  "30100":1202,
  "30300":707,
  "30420":1082,
  "31110":877,
  "31130":859,
  "31140":836,
  "31160":879,
  "31170":826,
  "31230":809,
  "31250":819,
  "31300":1181,
  "31310":937,
  "31340":895,
  "31350":832,
  "31380":871,
  "31400":1088,
  "31410":882,
  "31450":875,
  "31460":854,
  "31470":837,
  "31490":857,
  "31500":881,
  "31520":865,
  "31530":879,
  "31600":1101,
  "31610":872,
  "31620":876,
  "31630":861,
  "31640":688,
  "31650":871,
  "31700":939,
  "31720":892,
  "31730":824,
  "31750":823,
  "31760":969,
  "31810":857,
  "31830":844,
  "31850":856,
  "31860":843,
  "31900":756,
  "31930":793,
  "31950":813,
  "31960":782,
  "31970":817,
  "32100":850,
  "32140":794,
  "32200":1197,
  "32210":841,
  "32250":818,
  "32260":768,
  "32270":805,
  "32280":794,
  "32300":697,
  "32410":774,
  "32430":749,
  "32440":771,
  "32450":734,
  "32500":962,
  "32520":830,
  "32560":809,
  "32610":674,
  "32620":766,
  "32700":1088,
  "32710":792,
  "32730":820,
  "32740":720,
  "32760":765,
  "32770":772,
  "32800":795,
  "32810":864,
  "32830":808,
  "32860":755,
  "32910":789,
  "32920":735,
  "33100":3545,
  "33180":3922,
  "33200":3505,
  "33210":3425,
  "33230":3332,
  "33240":2871,
  "33250":3000,
  "33270":2544,
  "33300":2232,
  "33310":1568,
  "33330":1808,
  "33340":1983,
  "33380":1529,
  "33400":2383,
  "33410":1671,
  "33420":1792,
  "33430":1963,
  "33450":2079,
  "33470":1869,
  "33480":1990,
  "33500":3236,
  "33520":2192,
  "33530":2626,
  "33540":3018,
  "33560":2308,
  "33580":2321,
  "33610":2204,
  "33680":1586,
  "33700":2636,
  "33710":2063,
  "33720":1718,
  "33730":2103,
  "33800":2311,
  "33820":2283,
  "33840":1626,
  "33850":1197,
  "33870":1682,
  "33880":1657,
  "33900":2570,
  "33920":2818,
  "33950":2319,
  "33960":2313,
  "33980":1783,
  "34110":994,
  "34130":999,
  "34140":918,
  "34150":890,
  "34180":852,
  "34240":978,
  "34260":1006,
  "34270":870,
  "34300":863,
  "34320":879,
  "34330":894,
  "34410":874,
  "34420":828,
  "34430":849,
  "34450":854,
  "34510":816,
  "34530":825,
  "34550":791,
  "34600":774,
  "34640":814,
  "34710":859,
  "34730":800,
  "34740":856,
  "34770":824,
  "34800":871,
  "34870":855,
  "34910":822,
  "34930":864,
  "34950":840,
  "34970":875,
  "34980":870,
  "35100":1189,
  "35220":800,
  "35240":759,
  "35260":754,
  "35270":742,
  "35300":1228,
  "35320":859,
  "35400":772,
  "35420":732,
  "35480":746,
  "35500":850,
  "35520":767,
  "35530":759,
  "35540":779,
  "35550":791,
  "35600":559,
  "35610":707,
  "35630":719,
  "35700":707,
  "35740":731,
  "35750":747,
  "35800":650,
  "35820":768,
  "35990":814,
  "36100":1972,
  "36110":1922,
  "36120":1488,
  "36200":1854,
  "36220":1844,
  "36240":1963,
  "36270":1663,
  "36280":2516,
  "36340":1338,
  "36420":1194,
  "36430":1335,
  "36450":1277,
  "36520":1360,
  "36570":1343,
  "36600":1345,
  "36640":1374,
  "36660":1334,
  "36720":1338,
  "36760":1139,
  "36810":1352,
  "36840":1300,
  "36880":1218,
  "36910":1316,
  "36930":1253,
  "37100":1666,
  "37120":1829,
  "37130":1686,
  "37140":2062,
  "37150":1617,
  "37180":1195,
  "37200":1050,
  "37210":1070,
  "37240":897,
  "37310":1156,
  "37350":1070,
  "37370":1137,
  "37380":1082,
  "37420":1183,
  "37470":1629,
  "37500":1924,
  "37530":1347,
  "37550":2039,
  "37560":2371,
  "37570":1410,
  "37600":1209,
  "37630":1135,
  "37700":1214,
  "37720":1050,
  "37740":1133,
  "37770":1129,
  "37800":1162,
  "37830":1094,
  "37850":1095,
  "37860":1150,
  "37900":1103,
  "37910":924,
  "37960":1211,
  "38100":875,
  "38120":843,
  "38130":872,
  "38140":828,
  "38200":1504,
  "38210":1145,
  "38220":888,
  "38250":961,
  "38270":855,
  "38280":897,
  "38300":944,
  "38360":851,
  "38420":931,
  "38460":1041,
  "38470":860,
  "38490":840,
  "38510":673,
  "38600":768,
  "38650":755,
  "38670":770,
  "38680":741,
  "38700":1229,
  "38710":877,
  "38720":815,
  "38740":878,
  "38750":819,
  "38760":828,
  "38770":896,
  "38800":877,
  "38840":840,
  "38860":820,
  "38910":742,
  "38920":764,
  "38950":597,
  "38970":745,
  "39100":1348,
  "39110":1128,
  "39130":1144,
  "39150":1133,
  "39160":2037,
  "39170":1104,
  "39180":1083,
  "39195":1040,
  "39200":883,
  "39230":970,
  "39290":872,
  "39310":886,
  "39340":946,
  "39360":936,
  "39380":994,
  "39410":901,
  "39430":886,
  "39450":811,
  "39500":963,
  "39530":931,
  "39580":897,
  "39590":916,
  "39610":911,
  "39620":833,
  "39660":909,
  "39700":899,
  "39750":856,
  "39770":810,
  "39780":976,
  "39810":933,
  "39820":990,
  "39880":915,
  "39920":862,
  "39930":1071,
  "39940":894,
  "39960":973,
  "39965":915,
  "39980":934,
  "39990":857,
  "40100":2969,
  "40200":2594,
  "40250":1876,
  "40270":2008,
  "40320":1854,
  "40340":1165,
  "40400":1776,
  "40420":1749,
  "40500":2325,
  "40520":2229,
  "40530":2025,
  "40600":2146,
  "40620":2564,
  "40630":1596,
  "40640":1223,
  "40660":1423,
  "40700":2636,
  "40720":2465,
  "40740":1745,
  "40800":1498,
  "40820":1107,
  "40900":1332,
  "40930":1481,
  "40950":1685,
  "41120":1046,
  "41140":967,
  "41160":1040,
  "41180":990,
  "41210":927,
  "41220":874,
  "41230":996,
  "41240":833,
  "41260":864,
  "41270":901,
  "41290":951,
  "41310":1708,
  "41325":941,
  "41330":1086,
  "41340":1391,
  "41350":1026,
  "41360":981,
  "41370":940,
  "41390":967,
  "41400":815,
  "41410":870,
  "41440":895,
  "41450":925,
  "41490":908,
  "41500":589,
  "41520":773,
  "41530":784,
  "41540":758,
  "41550":776,
  "41560":747,
  "41580":763,
  "41630":856,
  "41660":908,
  "41710":745,
  "41730":665,
  "41750":699,
  "41770":583,
  "41800":1045,
  "41820":905,
  "41840":843,
  "41860":935,
  "41870":811,
  "41880":819,
  "41900":1069,
  "41920":918,
  "41925":884,
  "41930":969,
  "41940":939,
  "41970":778,
  "41980":830,
  "42100":1098,
  "42140":786,
  "42220":549,
  "42300":664,
  "42330":727,
  "42440":691,
  "42520":688,
  "42560":679,
  "42600":725,
  "42660":677,
  "42700":1056,
  "42720":788,
  "42800":445,
  "42820":621,
  "42830":609,
  "42840":618,
  "42850":616,
  "42870":630,
  "42910":675,
  "42930":662,
  "43100":933,
  "43130":857,
  "43170":701,
  "43220":723,
  "43240":737,
  "43250":786,
  "43270":747,
  "43300":630,
  "43340":672,
  "43390":660,
  "43420":657,
  "43430":621,
  "43440":731,
  "43480":738,
  "43490":671,
  "43500":899,
  "43520":723,
  "43540":761,
  "43560":683,
  "43610":706,
  "43640":742,
  "43660":702,
  "43700":900,
  "43720":713,
  "43800":566,
  "43840":637,
  "43900":679,
  "43940":722,
  "43960":648,
  "44100":1141,
  "44120":1226,
  "44150":913,
  "44160":905,
  "44170":861,
  "44190":858,
  "44200":617,
  "44220":844,
  "44250":815,
  "44260":773,
  "44280":758,
  "44300":942,
  "44320":788,
  "44330":832,
  "44350":809,
  "44370":782,
  "44400":732,
  "44420":818,
  "44440":757,
  "44460":751,
  "44480":761,
  "44500":1051,
  "44530":820,
  "44580":833,
  "44590":792,
  "44610":781,
  "44630":770,
  "44640":796,
  "44670":764,
  "44680":757,
  "44730":781,
  "44740":781,
  "44760":774,
  "44770":779,
  "44790":778,
  "44800":934,
  "44860":790,
  "44880":821,
  "44910":788,
  "44920":794,
  "44950":790,
  "44970":746,
  "45100":1389,
  "45120":1381,
  "45130":1068,
  "45150":880,
  "45160":995,
  "45200":993,
  "45330":1005,
  "45360":1160,
  "45370":1194,
  "45410":911,
  "45460":875,
  "45610":1124,
  "45700":992,
  "45720":1160,
  "45740":919,
  "45750":1076,
  "45810":966,
  "45910":624,
  "45940":843,
  "46110":747,
  "46140":764,
  "46230":757,
  "46400":761,
  "46430":750,
  "46450":754,
  "46530":732,
  "46570":713,
  "46710":766,
  "46730":776,
  "46750":793,
  "46800":720,
  "46810":809,
  "46860":836,
  "46900":749,
  "46910":860,
  "46930":757,
  "46950":753,
  "46960":757,
  "47110":821,
  "47150":788,
  "47200":668,
  "47260":762,
  "47310":774,
  "47350":781,
  "47400":889,
  "47440":800,
  "47450":806,
  "47460":883,
  "47490":773,
  "47520":821,
  "47540":773,
  "47610":749,
  "47650":766,
  "47710":728,
  "47760":687,
  "47810":767,
  "47830":806,
  "47850":749,
  "47900":774,
  "47910":675,
  "48100":1432,
  "48130":981,
  "48200":925,
  "48210":1460,
  "48220":841,
  "48230":628,
  "48300":1032,
  "48310":1776,
  "48350":586,
  "48400":1145,
  "48410":1266,
  "48600":1045,
  "48700":900,
  "48710":1386,
  "48720":989,
  "48750":976,
  "48770":970,
  "48800":1241,
  "48810":1051,
  "48900":688,
  "48910":799,
  "48930":915,
  "49200":1035,
  "49210":1199,
  "49220":1418,
  "49240":1011,
  "49270":1009,
  "49290":980,
  "49300":947,
  "49340":896,
  "49400":1142,
  "49410":934,
  "49420":915,
  "49460":706,
  "49480":926,
  "49490":941,
  "49510":686,
  "49520":843,
  "49540":844,
  "49570":834,
  "49610":959,
  "49630":935,
  "49640":898,
  "49660":884,
  "49700":909,
  "49720":866,
  "49730":895,
  "49750":895,
  "49760":896,
  "49770":895,
  "49780":869,
  "49840":897,
  "49860":911,
  "49900":721,
  "49930":870,
  "49960":860,
  "49980":855,
  "50100":2040,
  "50120":1230,
  "50130":1912,
  "50150":1472,
  "50160":1062,
  "50170":1487,
  "50180":1387,
  "50190":1998,
  "50200":1269,
  "50300":1241,
  "50350":1311,
  "50500":1508,
  "50520":1362,
  "50600":1649,
  "50670":1181,
  "50700":1311,
  "50770":1197,
  "50800":1287,
  "50970":2182,
  "51130":847,
  "51200":957,
  "51260":856,
  "51270":848,
  "51310":821,
  "51335":928,
  "51340":854,
  "51360":823,
  "51380":888,
  "51420":875,
  "51430":836,
  "51440":855,
  "51450":856,
  "51460":849,
  "51520":895,
  "51540":846,
  "51600":806,
  "51620":833,
  "51670":825,
  "51720":918,
  "51740":887,
  "51760":861,
  "51780":847,
  "51810":887,
  "51820":873,
  "51850":848,
  "51860":875,
  "51880":852,
  "51890":803,
  "51900":971,
  "51930":886,
  "51940":880,
  "51980":860,
  "52100":1101,
  "52110":958,
  "52150":912,
  "52200":674,
  "52230":800,
  "52270":800,
  "52300":979,
  "52320":956,
  "52330":947,
  "52340":875,
  "52360":837,
  "52420":886,
  "52510":904,
  "52520":918,
  "52550":979,
  "52610":871,
  "52620":811,
  "52700":859,
  "52720":877,
  "52730":842,
  "52740":863,
  "52760":882,
  "52780":880,
  "52830":865,
  "52850":831,
  "52890":812,
  "52920":833,
  "52960":852,
  "52970":850,
  "52980":814,
  "53100":2287,
  "53130":2592,
  "53200":1419,
  "53300":1375,
  "53400":1185,
  "53420":1500,
  "53500":1879,
  "53550":2146,
  "53600":2193,
  "53650":1019,
  "53810":1323,
  "53830":1210,
  "53850":1459,
  "53900":2294,
  "53920":1278,
  "53950":1164,
  "54100":1109,
  "54110":1504,
  "54120":1231,
  "54190":1006,
  "54230":1048,
  "54250":1005,
  "54260":1080,
  "54270":1033,
  "54300":1052,
  "54310":1067,
  "54330":1047,
  "54390":987,
  "54410":838,
  "54430":953,
  "54460":936,
  "54490":923,
  "54500":798,
  "54510":974,
  "54530":1116,
  "54550":927,
  "54580":920,
  "54590":913,
  "54710":1083,
  "54720":1049,
  "54750":1020,
  "54770":1003,
  "54800":920,
  "54820":1043,
  "54850":1160,
  "54915":1429,
  "54920":1350,
  "54930":1069,
  "54940":995,
  "54950":1005,
  "54960":1083,
  "55100":1297,
  "55120":987,
  "55300":1022,
  "55320":982,
  "55330":866,
  "55400":1115,
  "55420":1394,
  "55510":622,
  "55610":690,
  "55700":974,
  "55800":663,
  "55910":600,
  "56100":1094,
  "56120":911,
  "56140":770,
  "56210":770,
  "56310":713,
  "56330":742,
  "56350":638,
  "56410":777,
  "56440":735,
  "56510":869,
  "56550":822,
  "56610":831,
  "56640":797,
  "56710":801,
  "56730":774,
  "56800":594,
  "57100":1548,
  "57120":1254,
  "57130":1733,
  "57170":1142,
  "57200":1172,
  "57210":1023,
  "57220":700,
  "57230":796,
  "57310":905,
  "57510":1165,
  "57600":1181,
  "57710":1157,
  "57810":926,
  "58130":653,
  "58140":617,
  "58150":697,
  "58160":707,
  "58170":684,
  "58175":724,
  "58180":606,
  "58200":724,
  "58220":724,
  "58260":641,
  "58300":659,
  "58320":623,
  "58350":642,
  "58360":632,
  "58390":607,
  "58410":789,
  "58420":728,
  "58430":717,
  "58450":722,
  "58500":790,
  "58520":637,
  "58550":699,
  "58570":630,
  "58580":622,
  "58610":644,
  "58620":629,
  "58650":606,
  "58680":587,
  "58690":597,
  "58700":619,
  "58720":615,
  "58770":626,
  "58810":674,
  "58830":670,
  "58850":663,
  "58900":686,
  "58910":708,
  "58920":661,
  "58940":657,
  "59100":863,
  "59130":812,
  "59210":774,
  "59310":711,
  "59320":768,
  "59410":803,
  "59510":848,
  "59520":801,
  "59530":792,
  "59610":802,
  "59710":790,
  "59720":780,
  "59730":754,
  "59800":805,
  "59810":773,
  "59820":777,
  "60100":1859,
  "60120":1806,
  "60150":1439,
  "60200":1925,
  "60220":1663,
  "60280":1120,
  "60320":1808,
  "60420":1376,
  "60450":1226,
  "60510":1904,
  "60550":1571,
  "60560":1875,
  "60640":1168,
  "60720":1532,
  "60760":1267,
  "60800":1384,
  "61100":1140,
  "61110":881,
  "61120":882,
  "61140":843,
  "61150":714,
  "61160":844,
  "61170":805,
  "61180":803,
  "61220":840,
  "61230":836,
  "61250":809,
  "61270":823,
  "61280":829,
  "61290":759,
  "61300":1030,
  "61310":974,
  "61330":967,
  "61340":800,
  "61350":839,
  "61360":871,
  "61370":842,
  "61380":757,
  "61400":1081,
  "61410":849,
  "61430":830,
  "61440":805,
  "61450":822,
  "61460":823,
  "61470":888,
  "61500":815,
  "61520":722,
  "61550":823,
  "61560":648,
  "61600":822,
  "61630":779,
  "61640":728,
  "61650":731,
  "61680":783,
  "61710":833,
  "61720":778,
  "61730":721,
  "61760":761,
  "61800":1154,
  "61810":835,
  "61820":845,
  "61840":779,
  "61850":879,
  "61860":849,
  "61880":748,
  "61910":669,
  "61920":645,
  "61940":708,
  "61950":661,
  "61960":598,
  "61980":499,
  "62100":1382,
  "62130":922,
  "62150":986,
  "62160":962,
  "62165":958,
  "62170":876,
  "62175":816,
  "62185":799,
  "62190":946,
  "62200":947,
  "62220":904,
  "62230":892,
  "62240":808,
  "62260":814,
  "62280":787,
  "62290":823,
  "62295":854,
  "62300":994,
  "62310":821,
  "62340":825,
  "62350":862,
  "62370":1280,
  "62375":840,
  "62380":861,
  "62395":790,
  "62410":811,
  "62420":664,
  "62430":808,
  "62435":762,
  "62440":800,
  "62470":773,
  "62500":826,
  "62510":1008,
  "62520":837,
  "62540":823,
  "62570":806,
  "62600":945,
  "62610":787,
  "62620":845,
  "62630":820,
  "62640":822,
  "62660":867,
  "62710":934,
  "62720":921,
  "62730":1021,
  "62740":998,
  "62750":809,
  "62760":789,
  "62800":937,
  "62810":768,
  "62830":928,
  "62840":821,
  "62860":926,
  "62870":820,
  "62880":816,
  "62900":1109,
  "62920":829,
  "62940":973,
  "62950":897,
  "62990":793,
  "63100":932,
  "63120":773,
  "63130":770,
  "63150":765,
  "63160":790,
  "63210":700,
  "63230":786,
  "63250":819,
  "63300":1136,
  "63320":762,
  "63330":792,
  "63340":795,
  "63350":791,
  "63355":716,
  "63360":787,
  "63370":781,
  "63400":878,
  "63410":837,
  "63430":745,
  "63450":746,
  "63500":549,
  "63510":708,
  "63530":670,
  "63540":780,
  "63600":840,
  "63610":818,
  "63640":758,
  "63660":766,
  "63680":783,
  "63700":849,
  "63770":787,
  "63780":762,
  "63800":696,
  "63830":708,
  "63880":744,
  "63890":729,
  "63900":726,
  "63910":775,
  "63920":739,
  "63950":683,
  "64100":870,
  "64140":731,
  "64200":1024,
  "64210":734,
  "64220":737,
  "64230":858,
  "64240":725,
  "64250":726,
  "64260":596,
  "64300":753,
  "64320":716,
  "64350":653,
  "64370":703,
  "64440":692,
  "64450":697,
  "64460":735,
  "64480":736,
  "64490":687,
  "64510":745,
  "64530":723,
  "64550":732,
  "64610":673,
  "64700":764,
  "64720":752,
  "64740":726,
  "64760":768,
  "64770":745,
  "64810":710,
  "64820":685,
  "64830":715,
  "64840":702,
  "64850":699,
  "64900":856,
  "64930":737,
  "65100":2399,
  "65130":1496,
  "65170":3018,
  "65200":1857,
  "65230":1561,
  "65280":1759,
  "65300":2121,
  "65320":1610,
  "65350":1486,
  "65370":966,
  "65380":1179,
  "65410":1544,
  "65450":1737,
  "65460":1509,
  "65470":1337,
  "65480":1529,
  "65520":1506,
  "65610":1632,
  "65630":1774,
  "65650":1421,
  "65710":1587,
  "65730":1443,
  "65760":1372,
  "65800":1437,
  "65870":1337,
  "65920":1377,
  "65930":1368,
  "65970":1307,
  "66100":1401,
  "66140":1117,
  "66160":999,
  "66200":1108,
  "66210":1027,
  "66220":1029,
  "66230":957,
  "66240":1007,
  "66260":919,
  "66270":974,
  "66280":984,
  "66290":1021,
  "66295":984,
  "66300":694,
  "66320":971,
  "66330":863,
  "66340":896,
  "66350":837,
  "66360":894,
  "66370":914,
  "66400":1428,
  "66420":993,
  "66430":1117,
  "66440":1062,
  "66450":989,
  "66460":1012,
  "66470":888,
  "66500":1097,
  "66510":1155,
  "66520":1043,
  "66530":1247,
  "66540":1050,
  "66550":1073,
  "66560":1017,
  "66580":1034,
  "66590":1029,
  "66600":1019,
  "66640":994,
  "66660":974,
  "66680":938,
  "66710":1000,
  "66730":923,
  "66800":1185,
  "66810":1004,
  "66820":963,
  "66830":1114,
  "66840":1022,
  "66850":1012,
  "66900":1080,
  "66910":1066,
  "66930":995,
  "66950":1018,
  "66970":1011,
  "66980":1011,
  "66999":938,
  "67100":2017,
  "67200":1799,
  "67300":1851,
  "67400":1652,
  "67410":1358,
  "67500":1208,
  "67600":1473,
  "67700":1972,
  "67800":977,
  "67900":1862,
  "68100":1101,
  "68150":1042,
  "68210":1072,
  "68220":1039,
  "68230":1045,
  "68240":1017,
  "68300":1232,
  "68320":1112,
  "68370":963,
  "68380":946,
  "68390":1113,
  "68410":1020,
  "68500":1050,
  "68530":1203,
  "68550":1155,
  "68555":1291,
  "68560":1213,
  "68570":1580,
  "68580":1984,
  "68600":1312,
  "68620":1353,
  "68630":1274,
  "68660":806,
  "68690":977,
  "68700":923,
  "68750":1012,
  "68800":1149,
  "68810":1167,
  "68820":1053,
  "68830":1004,
  "68840":963,
  "68870":1156,
  "68910":1124,
  "68920":1025,
  "68930":1026,
  "68940":994,
  "68970":935,
  "68999":946,
  "69100":920,
  "69150":837,
  "69170":845,
  "69300":745,
  "69310":783,
  "69330":791,
  "69340":771,
  "69410":793,
  "69420":744,
  "69440":785,
  "69450":756,
  "69510":814,
  "69550":724,
  "69570":784,
  "69600":1074,
  "69660":860,
  "69700":832,
  "69730":1055,
  "69750":795,
  "69820":830,
  "69830":798,
  "69850":817,
  "69910":762,
  "69920":823,
  "69950":850,
  "69970":682,
  "69980":845,
  "70100":2682,
  "70110":2797,
  "70150":1665,
  "70200":1680,
  "70210":1355,
  "70240":1572,
  "70260":1728,
  "70280":1381,
  "70300":2500,
  "70340":1658,
  "70400":1578,
  "70420":1468,
  "70460":1224,
  "70500":1593,
  "70600":2418,
  "70620":1904,
  "70700":1579,
  "70780":1639,
  "70800":1834,
  "70820":2025,
  "70840":2587,
  "70870":1607,
  "70900":2019,
  "70910":1642,
  "70940":1511,
  "71130":1011,
  "71150":990,
  "71160":1210,
  "71170":985,
  "71200":626,
  "71210":872,
  "71240":862,
  "71260":835,
  "71280":785,
  "71310":1213,
  "71330":946,
  "71360":926,
  "71380":914,
  "71460":979,
  "71470":1033,
  "71480":1034,
  "71490":931,
  "71520":961,
  "71570":1021,
  "71610":1039,
  "71640":900,
  "71650":945,
  "71660":908,
  "71670":896,
  "71680":928,
  "71690":927,
  "71720":1059,
  "71730":1016,
  "71740":950,
  "71745":982,
  "71750":1107,
  "71760":928,
  "71775":938,
  "71800":1511,
  "71820":1091,
  "71840":1048,
  "71850":1420,
  "71870":1091,
  "71890":1062,
  "71910":1006,
  "71920":958,
  "71950":909,
  "71960":926,
  "72100":847,
  "72140":784,
  "72210":673,
  "72220":693,
  "72300":660,
  "72310":700,
  "72330":703,
  "72350":703,
  "72360":671,
  "72380":686,
  "72400":709,
  "72430":719,
  "72490":670,
  "72510":681,
  "72530":709,
  "72550":750,
  "72570":706,
  "72600":738,
  "72630":696,
  "72710":722,
  "72740":680,
  "72810":708,
  "72840":682,
  "72930":690,
  "72950":696,
  "72980":674,
  "73100":867,
  "73110":751,
  "73120":757,
  "73200":576,
  "73230":639,
  "73250":611,
  "73300":783,
  "73310":695,
  "73320":677,
  "73350":681,
  "73360":707,
  "73410":620,
  "73460":562,
  "73470":657,
  "73500":742,
  "73600":675,
  "73620":628,
  "73640":609,
  "73645":620,
  "73670":628,
  "73710":663,
  "73730":621,
  "73770":667,
  "73810":646,
  "73830":667,
  "73850":613,
  "73900":664,
  "73970":618,
  "73990":661,
  "74100":1414,
  "74120":1206,
  "74130":1130,
  "74140":888,
  "74150":879,
  "74160":922,
  "74170":936,
  "74200":811,
  "74230":796,
  "74240":771,
  "74250":686,
  "74270":787,
  "74300":761,
  "74340":783,
  "74345":749,
  "74360":791,
  "74380":681,
  "74390":664,
  "74420":822,
  "74470":778,
  "74490":706,
  "74510":975,
  "74520":862,
  "74540":810,
  "74550":782,
  "74590":820,
  "74595":832,
  "74610":817,
  "74630":825,
  "74640":788,
  "74670":818,
  "74680":812,
  "74700":787,
  "74720":860,
  "74740":792,
  "74770":798,
  "74840":787,
  "74940":837,
  "74980":782,
  "75500":780,
  "75530":944,
  "75650":688,
  "75680":572,
  "75700":618,
  "75710":676,
  "75740":597,
  "75770":584,
  "75790":580,
  "75840":683,
  "75890":603,
  "75930":537,
  "75940":645,
  "75970":715,
  "75990":638,
  "76100":668,
  "76120":733,
  "76130":919,
  "76150":664,
  "76280":786,
  "76620":794,
  "76780":778,
  "76850":755,
  "76940":811,
  "77110":765,
  "77120":777,
  "77140":765,
  "77220":789,
  "77240":828,
  "77320":807,
  "77330":814,
  "77350":846,
  "77380":804,
  "77430":812,
  "77460":824,
  "77480":767,
  "77520":786,
  "77570":839,
  "77580":793,
  "77600":837,
  "77630":836,
  "77690":809,
  "77700":799,
  "77770":754,
  "77800":936,
  "77910":828,
  "77930":805,
  "77960":826,
  "78200":820,
  "78210":736,
  "78250":807,
  "78300":518,
  "78310":910,
  "78400":865,
  "78480":764,
  "78500":700,
  "78610":756,
  "78710":737,
  "78850":890,
  "78870":888,
  "78880":764,
  "78900":752,
  "79100":920,
  "79130":537,
  "79140":668,
  "79150":668,
  "79160":657,
  "79180":660,
  "79190":634,
  "79230":640,
  "79255":652,
  "79265":648,
  "79330":640,
  "79350":607,
  "79410":647,
  "79480":663,
  "79520":626,
  "79600":690,
  "79620":666,
  "79630":665,
  "79660":671,
  "79680":620,
  "79690":622,
  "79700":602,
  "79810":650,
  "79820":648,
  "79830":648,
  "79860":629,
  "79885":615,
  "79895":617,
  "79910":629,
  "79940":632,
  "80100":2144,
  "80110":2071,
  "80130":1496,
  "80140":1796,
  "80160":1444,
  "80170":1567,
  "80200":2096,
  "80220":2443,
  "80230":1812,
  "80260":1580,
  "80330":1868,
  "80400":1509,
  "80510":1279,
  "80710":1948,
  "80770":1309,
  "80780":1322,
  "80790":760,
  "80850":1283,
  "80910":1233,
  "81100":1299,
  "81120":831,
  "81160":738,
  "81200":792,
  "81210":802,
  "81220":720,
  "81230":660,
  "81235":657,
  "81260":622,
  "81270":690,
  "81280":458,
  "81290":695,
  "81295":619,
  "81320":657,
  "81330":663,
  "81350":650,
  "81360":659,
  "81390":667,
  "81420":634,
  "81430":598,
  "81450":641,
  "81460":580,
  "81470":561,
  "81560":696,
  "81570":653,
  "81590":668,
  "81650":582,
  "81660":644,
  "81700":816,
  "81720":555,
  "81750":644,
  "81810":730,
  "81820":668,
  "81850":678,
  "81860":647,
  "81950":663,
  "81970":598,
  "82110":663,
  "82120":583,
  "82140":539,
  "82160":550,
  "82170":569,
  "82180":585,
  "82200":784,
  "82210":726,
  "82220":729,
  "82290":634,
  "82300":437,
  "82310":539,
  "82335":569,
  "82350":585,
  "82360":568,
  "82380":568,
  "82395":577,
  "82430":628,
  "82460":614,
  "82490":574,
  "82500":863,
  "82510":675,
  "82580":649,
  "82590":621,
  "82600":589,
  "82655":630,
  "82660":648,
  "82670":601,
  "82675":660,
  "82685":602,
  "82710":593,
  "82730":517,
  "82750":555,
  "82760":559,
  "82770":524,
  "82815":597,
  "82820":600,
  "82830":594,
  "82840":553,
  "82850":598,
  "82865":563,
  "82870":552,
  "82880":552,
  "82900":646,
  "82915":599,
  "82960":533,
  "82967":514,
  "82980":543,
  "83100":744,
  "83130":790,
  "83140":749,
  "83150":726,
  "83160":665,
  "83320":756,
  "83330":679,
  "83340":741,
  "83400":691,
  "83430":747,
  "83450":741,
  "83460":765,
  "83480":716,
  "83500":674,
  "83550":705,
  "83630":750,
  "83660":693,
  "83700":766,
  "83720":737,
  "83750":734,
  "83760":736,
  "83780":721,
  "83825":695,
  "83830":667,
  "83835":702,
  "83840":673,
  "83855":672,
  "83870":659,
  "83880":646,
  "83900":732,
  "83910":623,
  "83915":760,
  "83940":678,
  "83950":688,
  "83960":651,
  "83985":759,
  "84100":1435,
  "84460":1014,
  "84540":1153,
  "84650":1031,
  "84770":1190,
  "84880":1102,
  "85100":1467,
  "85120":808,
  "85140":860,
  "85150":811,
  "85160":823,
  "85180":880,
  "85200":722,
  "85210":754,
  "85230":735,
  "85310":788,
  "85320":783,
  "85340":863,
  "85410":833,
  "85430":838,
  "85450":765,
  "85470":718,
  "85500":1158,
  "85540":819,
  "85560":524,
  "85580":760,
  "85620":741,
  "85630":899,
  "85640":819,
  "85660":769,
  "85710":721,
  "85730":720,
  "85800":962,
  "85820":799,
  "85840":701,
  "85900":707,
  "85930":644,
  "85940":686,
  "85980":732,
  "86100":947,
  "86110":906,
  "86160":866,
  "86170":863,
  "86210":865,
  "86220":887,
  "86230":769,
  "86240":813,
  "86300":945,
  "86350":795,
  "86360":832,
  "86400":850,
  "86440":927,
  "86460":821,
  "86470":799,
  "86480":742,
  "86510":804,
  "86530":787,
  "86550":865,
  "86600":1216,
  "86650":862,
  "86680":920,
  "86690":794,
  "86710":648,
  "86790":747,
  "86800":846,
  "86810":971,
  "86850":717,
  "86870":770,
  "86900":1031,
  "86980":718,
  "87100":1436,
  "87150":1188,
  "87200":1452,
  "87250":1367,
  "87300":1089,
  "87400":987,
  "87500":939,
  "87700":1143,
  "87800":932,
  "87830":1137,
  "87850":1042,
  "87900":894,
  "87910":1060,
  "87930":1010,
  "87950":1022,
  "87970":985,
  "88120":840,
  "88200":1017,
  "88270":973,
  "88300":639,
  "88310":859,
  "88340":747,
  "88350":798,
  "88380":875,
  "88400":926,
  "88440":990,
  "88460":838,
  "88470":1039,
  "88490":971,
  "88530":911,
  "88600":1283,
  "88610":1655,
  "88620":1079,
  "88640":1087,
  "88670":1054,
  "88690":954,
  "88730":856,
  "88740":852,
  "88760":869,
  "88820":923,
  "88900":752,
  "88930":845,
  "88940":831,
  "89110":520,
  "89140":563,
  "89200":483,
  "89210":461,
  "89320":556,
  "89330":520,
  "89400":598,
  "89430":564,
  "89440":572,
  "89540":555,
  "89560":535,
  "89600":717,
  "89610":621,
  "89640":617,
  "89660":555,
  "89670":560,
  "89680":557,
  "89730":518,
  "89740":531,
  "89760":520,
  "89770":502,
  "89780":537,
  "89800":481,
  "89830":548,
  "89840":474,
  "89920":526,
  "90100":2668,
  "90120":2416,
  "90130":2333,
  "90140":2462,
  "90150":1495,
  "90220":1723,
  "90230":2355,
  "90240":1585,
  "90250":1459,
  "90310":1652,
  "90400":3097,
  "90410":1887,
  "90420":2003,
  "90440":1781,
  "90450":1724,
  "90460":1831,
  "90470":1676,
  "90480":1457,
  "90500":1867,
  "90510":2517,
  "90520":2065,
  "90530":2124,
  "90540":1848,
  "90550":1341,
  "90560":1230,
  "90570":1616,
  "90580":1047,
  "90590":1307,
  "90620":1495,
  "90630":1745,
  "90650":1628,
  "90660":1342,
  "90670":1471,
  "90800":1423,
  "90810":1585,
  "90820":1506,
  "90830":1596,
  "90840":1438,
  "90850":1603,
  "90860":1442,
  "90900":1477,
  "90910":1495,
  "90940":1625,
  "91100":1072,
  "91110":1044,
  "91140":943,
  "91150":917,
  "91200":987,
  "91210":1021,
  "91240":919,
  "91260":919,
  "91300":988,
  "91310":936,
  "91410":1115,
  "91430":1112,
  "91500":1001,
  "91510":1061,
  "91560":957,
  "91600":803,
  "91620":960,
  "91630":940,
  "91640":955,
  "91660":1009,
  "91670":1218,
  "91700":692,
  "91710":956,
  "91720":813,
  "91730":875,
  "91740":854,
  "91750":834,
  "91760":873,
  "91780":888,
  "91790":932,
  "91800":1136,
  "91900":1244,
  "91910":1572,
  "91930":1134,
  "91950":1071,
  "91980":1124,
  "92100":1278,
  "92120":1054,
  "92130":1064,
  "92140":1269,
  "92150":857,
  "92160":905,
  "92180":960,
  "92210":817,
  "92220":730,
  "92230":734,
  "92240":754,
  "92260":673,
  "92320":730,
  "92330":678,
  "92350":708,
  "92400":716,
  "92430":565,
  "92440":647,
  "92450":642,
  "92470":628,
  "92500":702,
  "92520":648,
  "92530":696,
  "92600":639,
  "92610":652,
  "92620":666,
  "92640":662,
  "92650":665,
  "92700":550,
  "92810":652,
  "92830":640,
  "92910":635,
  "92920":595,
  "92930":716,
  "93100":885,
  "93140":866,
  "93160":815,
  "93170":822,
  "93180":805,
  "93187":837,
  "93190":788,
  "93195":909,
  "93220":889,
  "93225":926,
  "93240":916,
  "93250":873,
  "93270":960,
  "93277":912,
  "93280":926,
  "93290":899,
  "93350":880,
  "93390":899,
  "93400":739,
  "93420":867,
  "93440":795,
  "93470":840,
  "93540":919,
  "93590":891,
  "93600":1214,
  "93630":962,
  "93680":1000,
  "93730":1098,
  "93760":930,
  "93780":847,
  "93820":1140,
  "93825":1558,
  "93840":1130,
  "93850":989,
  "93920":1121,
  "93940":1103,
  "93990":979,
  "93999":1248,
  "94100":928,
  "94200":667,
  "94300":746,
  "94400":868,
  "94430":800,
  "94450":1082,
  "94460":733,
  "94500":884,
  "94600":984,
  "94700":612,
  "94720":1074,
  "94830":455,
  "94900":768,
  "95100":1058,
  "95110":1107,
  "95130":1028,
  "95160":944,
  "95200":1084,
  "95210":1126,
  "95220":1195,
  "95225":1034,
  "95230":1131,
  "95255":985,
  "95260":898,
  "95270":984,
  "95300":912,
  "95310":1128,
  "95315":1026,
  "95325":988,
  "95330":965,
  "95340":1022,
  "95350":1219,
  "95355":935,
  "95365":1038,
  "95370":1008,
  "95375":1041,
  "95400":1672,
  "95410":1331,
  "95420":1236,
  "95430":1105,
  "95440":1145,
  "95450":1527,
  "95460":1113,
  "95470":1209,
  "95500":1164,
  "95520":1136,
  "95530":1074,
  "95540":1014,
  "95560":1085,
  "95590":1049,
  "95600":764,
  "95610":1003,
  "95615":1015,
  "95620":1055,
  "95630":1042,
  "95635":1001,
  "95640":927,
  "95645":952,
  "95655":1031,
  "95660":927,
  "95670":890,
  "95675":901,
  "95680":947,
  "95690":884,
  "95700":1126,
  "95710":989,
  "95720":1047,
  "95750":964,
  "95760":985,
  "95770":1020,
  "95780":999,
  "95790":964,
  "95800":1000,
  "95830":1019,
  "95840":954,
  "95850":917,
  "95860":944,
  "95900":667,
  "95920":1306,
  "95930":1184,
  "95940":1140,
  "95950":1111,
  "95970":2024,
  "95980":1930,
  "95990":1153,
  "96100":2069,
  "96190":1630,
  "96200":1867,
  "96300":1845,
  "96320":1242,
  "96400":1501,
  "96440":972,
  "96460":1662,
  "96500":866,
  "96600":1155,
  "96700":1238,
  "96800":1513,
  "96900":1787,
  "96910":1301,
  "96930":1247,
  "96960":1085,
  "97110":746,
  "97130":807,
  "97140":711,
  "97160":815,
  "97170":758,
  "97220":813,
  "97240":658,
  "97250":664,
  "97280":685,
  "97290":683,
  "97310":650,
  "97320":599,
  "97330":623,
  "97335":608,
  "97340":640,
  "97370":631,
  "97380":694,
  "97390":650,
  "97420":639,
  "97430":521,
  "97470":658,
  "97510":644,
  "97520":625,
  "97530":623,
  "97540":588,
  "97580":639,
  "97590":594,
  "97610":712,
  "97615":704,
  "97620":685,
  "97625":680,
  "97630":675,
  "97635":720,
  "97645":627,
  "97650":684,
  "97655":693,
  "97665":636,
  "97670":683,
  "97675":653,
  "97680":625,
  "97685":630,
  "97690":643,
  "97700":762,
  "97715":655,
  "97720":703,
  "97760":674,
  "97765":689,
  "97770":748,
  "97780":724,
  "97785":642,
  "97810":591,
  "97815":601,
  "97820":659,
  "97840":680,
  "97850":627,
  "97870":610,
  "97880":664,
  "97890":685,
  "97895":664,
  "97900":609,
  "97920":667,
  "97925":633,
  "97940":653,
  "97950":653,
  "97960":639,
  "97965":632,
  "97970":650,
  "97980":629,
  "98100":710,
  "98120":565,
  "98230":673,
  "98310":697,
  "98350":671,
  "98360":679,
  "98400":636,
  "98420":623,
  "98440":496,
  "98450":625,
  "98500":775,
  "98510":772,
  "98530":1107,
  "98550":783,
  "98560":773,
  "98570":788,
  "98580":782,
  "98600":669,
  "98620":673,
  "98630":664,
  "98660":674,
  "98710":672,
  "98720":665,
  "98760":667,
  "98770":665,
  "98780":672,
  "98790":662,
  "98800":676,
  "98810":660,
  "98820":657,
  "98830":652,
  "98840":656,
  "98850":670,
  "98900":630,
  "98920":667,
  "98930":667,
  "98950":663,
  "98960":643,
  "98970":663,
  "98980":652,
  "98995":665,
  "99100":1267,
  "99110":1368,
  "99120":1308,
  "99130":2017,
  "99135":1339,
  "99140":1386,
  "99150":1322,
  "99160":1325,
  "99170":1238,
  "99180":1391,
  "99190":1256,
  "99195":1190,
  "99230":1160,
  "99240":1166,
  "99250":1063,
  "99270":1166,
  "99280":1181,
  "99290":1142,
  "99300":1273,
  "99310":1223,
  "99320":1222,
  "99340":1170,
  "99360":1166,
  "99400":1245,
  "99410":1176,
  "99420":1131,
  "99430":1078,
  "99440":1085,
  "99450":1178,
  "99460":1117,
  "99470":1096,
  "99490":1090,
  "99510":1145,
  "99520":1175,
  "99530":1180,
  "99540":1194,
  "99550":1203,
  "99555":1188,
  "99560":1256,
  "99600":943,
  "99610":1180,
  "99620":1128,
  "99640":1003,
  "99645":971,
  "99650":1287,
  "99655":1017,
  "99660":1174,
  "99665":952,
  "99670":1032,
  "99690":992,
  "99695":998,
  "99710":1251,
  "99720":1178,
  "99740":1291,
  "99750":1206,
  "99760":1206,
  "99770":1191,
  "99800":1145,
  "99830":1817,
  "99860":1122,
  "99870":1219,
  "99885":1129,
  "99910":1085,
  "99930":1071,
  "99940":1044,
  "99950":1121,
  "99980":1139,
  "99990":1147,
  // lisää postinumeroita ja hintoja tarpeen mukaan
};



// Hinta-arvion laskeminen
const calculatePriceEstimation = (zipCode, size) => {
  const pricePerSquareMeter = pricesPerSquareMeter[zipCode];
  if (!pricePerSquareMeter) return 'Hinta-arvio ei saatavilla'; // Jos postinumeroa ei löydy

  const estimation = pricePerSquareMeter * size * 1.2; // Hinta-arvio kerrotaan 1.2:lla
  const roundedEstimation = Math.round(estimation / 1000) * 1000; // Pyöristetään tuhanteen
  return roundedEstimation.toLocaleString('fi-FI') + '€'; // Käytetään tuhaterotinta ja lisätään €-merkki
};



// Komponentti, joka näyttää hinta-arvion
const PriceEstimationField = ({ record }) => (
  <span>{calculatePriceEstimation(record.zipCode, record.size)}</span>
);

 

const rowStyle = (record, index) => {
    return record.status === "OPEN" ? { backgroundColor: '#90ee90' } : {};
};

const styles = {
    boldText: {
        fontWeight: 'bold'
    },
};

const WelcomeCard = () => {
    const storedProfile = localStorage.getItem('profile');
    const name = storedProfile ? JSON.parse(storedProfile).fullName : '';
  
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" component="h2">
            Tervetuloa salaoveen {name.split(" ")[0]}
          </Typography>
          <p>Tässä näkymässä näet uudet avoimet vertailusi. Huomioithan, että vertailun saavuttua sinulla on tunti aikaa tehdä mahdolliset muutokset tarjoukseesi. Mikäli et ehdi tehdä muutoksia tässä ajassa, palvelu käyttää automaattisesti vakiotarjoustasi.
    
          Jos vertailupyyntöjä ei ole vielä saatavilla, ne alkavat ilmestyä rekisteröitymisprosessinne valmistuttua. Huomioittehan myös, että lomamoodissa ollessanne ette saa vertailupyyntöjä.</p>    
        </CardContent>
      </Card>
    );
  }

export const OpenEnquiry = (props) => {
  const isMobile = window.innerWidth <= 768;
  const [cards, setCards] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Tilamuuttuja varoituksen näyttämiseen
  const navigate = useNavigate();
 
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Tila dialogin hallintaan
  
  const handleRowClick = (event, record) => {
    event.stopPropagation(); // Estää oletustoiminnan, kuten navigoinnin
    setSelectedRecord(record);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Sulkee modal
  };







  return (
    <>
<WelcomeCard />
<br></br>
    <List {...props} title="Uudet Vertailu">
    {isMobile ? (
       <>

          <SimpleList
            primaryText={record => `${record.city}, ${record.zipCode}`}
            secondaryText={record => `${record.size}m2`}
            tertiaryText={record => record.comment}
            onRowClick={handleRowClick}
            leftAvatar={record => (
              <img
                src={Array.isArray(record.images) && record.images.length > 0
                    ? record.images[0]
                    : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
                alt="Kuva"
                width={50}
                height={50}
              />
            )}
          />
          </>
        ) : (
      
          

      <Datagrid  rowStyle={rowStyle}>
        <FunctionField
            label="Kuva"
            render={record =>
                <img
                    src={Array.isArray(record.images) && record.images.length > 0
                        ? record.images[0]
                        : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
                    alt="Kuva"
                    width={200}
                />
            }
            style={styles.boldText}
        />
    <FunctionField
        label="Alue"
        render={record =>
            record.status === "REJECT"
                ? <div style={{ backgroundColor: 'black', height: '20px' }}></div>
                : <span style={styles.boldText}>{record.city}, {record.zipCode} {findLocationByZipCode(record.zipCode)}</span>
        }
    />
 <FunctionField
        label="Hinta-arvio"
        render={record =>
            record.status === "REJECT"
                ? <div style={{ backgroundColor: 'black', height: '20px' }}></div>
                : <div style={styles.boldText}>
                    ~<PriceEstimationField record={record} />
                  </div>
        }
    />

        <FunctionField 
            label="Koko" 
            render={record => <span style={styles.boldText}>{`${record.size}m2`}</span>}
        />
          <FunctionField
            label="Lisätiedot"
            render={record =>
                record.status === "REJECT"
                    ? <div style={{ backgroundColor: 'black', height: '20px' }}></div>
                    : <span style={styles.boldText}>{record.comment}</span>
            }
        />
      
      <FunctionField
  label="Päivämäärä"
  render={(record) => {
    if (!record.createdAt) return <span style={styles.boldText}>Ei aikaleimaa</span>;
    const date = new Date(Number(record.createdAt));
    if (isNaN(date.getTime())) return <span style={styles.boldText}>Virheellinen päivämäärä</span>;
    return <span style={styles.boldText}>{date.toLocaleDateString('fi-FI')}</span>; // Muotoilee päivämäärän suomalaisen standardin mukaan
  }}
/>
        <FunctionField
            label="Status"
            render={(record) => {
                let status;
                switch (record.status) {
                    case "OPEN":
                        status = "UUSI";
                        break;
                    case "CLOSED":
                        status = "Päättynyt";
                        break;
                    case "REJECT":
                        status = "Hylätty";
                        break;
                    case "MARK_AS_SOLD":
                        status = "Myyty";
                        break;
                    default:
                        status = record.status;
                }
                return <span style={styles.boldText}>{status}</span>;
            }}
        />
        <FunctionField
  render={record => (
    <BidFormModal record={record} />
  )}
/>
      </Datagrid>
      )}
    </List>
    {selectedRecord && modalOpen && (
        <BidFormModal record={selectedRecord} open={modalOpen} onClose={handleCloseModal} />
        )}
    </>
  );
};

export default OpenEnquiry;


