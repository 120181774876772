import { addUserProfileOverrides } from "./myDataProvider";
import decodeJwt from 'jwt-decode';

const authProvider = {
  login: ({ email, password }) => {
      // Rakenna URL kyselyparametreilla
      const query = new URLSearchParams({ email, password }).toString();
      const url = `https://api.salaovi.fi/VendorUser/Login?${query}`;
      const request = new Request(url, {
          method: 'GET',
          headers: new Headers(),
      });
      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }
              return response.json();
          })
          .then(({ data: { accessToken, type } }) => {
            console.log("authProvider.login: Käyttäjän tiedot saatu", { accessToken, type });
            const decodedToken = decodeJwt(accessToken);
            localStorage.setItem('token', accessToken);
            // Oletetaan, että käyttäjän rooli (type) palautetaan kirjautumisvastauksessa
        // Tallenna käyttäjän rooli localStoragessa
        localStorage.setItem('permissions', type);
        return type;

          })
          .catch(() => {
              throw new Error('Network error');
          });
  },

  getIdentity: async () => {

  
    
      try {
        const responseProfile = await addUserProfileOverrides().getUserProfile();
    
        if (responseProfile && responseProfile.data) {
          const { id, fullName, avatar, type } = responseProfile.data;
          // Voit myös tallentaa tämän tiedon local storageen tarvittaessa
          // localStorage.setItem("profile", JSON.stringify({ id, fullName, avatar }));
          return Promise.resolve({ id, fullName, avatar, type });
        } else {
          console.error("getIdentity: Profiili palvelimelta ei sisällä dataa");
          return Promise.reject(new Error('Käyttäjäprofiilia ei löydy.'));
        }
      } catch (error) {
        console.error("getIdentity: Virhe haettaessa profiilia palvelimelta", error);
        return Promise.reject(new Error('Käyttäjäprofiilia ei löydy.'));
      }
    },

    getPermissions: () => {
      const role = localStorage.getItem('permissions');
      return role ? Promise.resolve(role) : Promise.reject();
    },

  checkError: (error) => {
    // Tarkistaa, onko virhe sellainen joka vaatii uloskirjautumista
    if (error.status === 401 || error.status === 403) {
      // Redirect to the login page (or do whatever you think is appropriate)
      return Promise.reject();
    }
    return Promise.resolve();
  },
  
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('permissions');
    return Promise.resolve();
},
  // ...
};

export default authProvider;