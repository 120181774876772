import * as React from 'react';
import { UserMenu, MenuItemLink, Logout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Avatar } from '@material-ui/core';

// Luo tyylit
const useStyles = makeStyles({
  avatar: {
    width: '50px',  // Suurentaa kuvan leveyttä
    height: '50px', // Suurentaa kuvan korkeutta
  },
});

// Mukautettu UserAvatar-komponentti
const UserAvatar = (props) => {
  const classes = useStyles();
  return <Avatar {...props} className={classes.avatar} />;
};

const MyUserMenu = (props) => {
  return (
    <UserMenu {...props} avatar={<UserAvatar />} >
      <MenuItemLink
        to="/profiili"
        primaryText="Muokkaa profiilia"
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        to="/maksutavat"
        primaryText="Lompakko"
        leftIcon={<AccountBalanceWalletIcon />}
      />
      <MenuItemLink
        to="/transactions"
        primaryText="Laskutus"
        leftIcon={<ReceiptIcon />}
      />
      <Logout />
    </UserMenu>
  );
};

export default MyUserMenu;

