import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

const ContentContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

export const HelpEdit = () => {
  // HTML-tietosi muuttujassa, käyttäen template literals -syntaksia
  const htmlContent = `
  <p><img style="width:100%" src="https://www.salaovi.fi/wp-content/uploads/2020/07/undraw_Notify_re_65on.png" alt="mail"></p><p>Asiakaspalvelu palvelee osoitteessa</p><p></p><p><a href="https://tuki.salaovi.fi" rel="noopener noreferrer" target="_blank"><strong>tuki.salaovi.fi</strong></a></p><p></p><p><strong>Puhelinpalvelu</strong></p><p>+358 046 800 0085 (ark.10-16)</p>  `;

  return <ContentContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default HelpEdit;
