import * as React from "react";
import { List, Datagrid, FunctionField, SimpleList  } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const rowStyle = (record, index) => {
    return record.status === "REJECT" ? { backgroundColor: '#ffcccc' } : {};
};

const WelcomeCard = () => {
    const storedProfile = localStorage.getItem('profile');
    const name = storedProfile ? JSON.parse(storedProfile).fullName : 'Vieras';
  
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">
            Kaikki vertailut
          </Typography>
<p>Täältä löydätte kaikki vertailuihinne liittyvät yksityiskohdat ja asiakastiedot. Muistakaa vahvistaa toimeksiantosopimukset 'Ilmoita' -painikkeella ennen kohteen julkaisua. </p>        
</CardContent>
      </Card>
    );
  }

const CreateRelatedListingButton = ({ record }) => {
    if (!record || !record.enquiryId) return null;

    const enquiryId = record.enquiryId._id;
    const userId = record.userId._id;
    const clientName = record.enquiryId.name;
    const clientEmail = record.enquiryId.email; 
    const address = record.enquiryId.address;
    const commission = record.commisionEnd.toString();
    const description = record.enquiryId.comment;
    const apartmentSize = record.enquiryId.size;
    const images = record.enquiryId.images;
   
    // Lisää tähän muita tietoja, jotka haluat alustaa, esim.:
    // const clientAddress = record.enquiryId.address;
    // const clientPhone = record.enquiryId.contactNumber;
    // ...

    // Täytä muut kentät tarpeen mukaan.
    const initialValues = {
        enquiryId,
        userId,
        clientName,
        clientEmail,
        address,
        commission,
        description,
        apartmentSize,
        images
        // Lisää tähän muita kenttiä, esim.:
        // address: clientAddress,
        // contactNumber: clientPhone,
        // ...
    };
    


    return (
        <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/allproposals/create"
  state={{ record: initialValues }}
        >
            Ilmoita
        </Button>
    );
};



export const AgencyEnquiryList = (props) => {
    const [cards, setCards] = useState([]); // Alustetaan kortit tilaan
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
 
  return (
    <>

<WelcomeCard />
    <br></br>
<List {...props} pagination={null} title="Kaikki vertailut" >
{isMobile ? (
  <>
 
        <SimpleList
          primaryText={record => `${record.enquiryId.name}`}
          secondaryText={record => `${record.enquiryId.address}`}
          tertiaryText={record => `${record.enquiryId.size}m2`}
          leftAvatar={record => (
            <img
              src={Array.isArray(record.enquiryId.images) && record.enquiryId.images.length > 0
                    ? record.enquiryId.images[0]
                    : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
              alt="Kuva"
              width={50}
              height={50}
            />
          )}
        />
        </>
      ) : (

  <Datagrid rowStyle={rowStyle} bulkActionButtons={false}>
      <FunctionField
  label="Kuva"
  render={record =>
    <img
      src={Array.isArray(record.enquiryId.images) && record.enquiryId.images.length > 0
        ? record.enquiryId.images[0]
        : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
      alt="Kuva"
      width={100}
    />
  }
/>
<FunctionField
    label="Valikoitunut välittäjä"
    render={record =>
        record.agentId && (
            <strong>{record.agentId.fullName || ""}</strong>
        )
    }
/>

<FunctionField
    label="Osoite"
    render={record =>
        record.enquiryId && record.enquiryId.address
    }
/>

<FunctionField
    label="Nimi"
    render={record =>
        record.enquiryId && record.enquiryId.name
    }
/>

<FunctionField
    label="Sähköposti"
    render={record =>
        record.status === "REJECT"
            ? <div style={{ backgroundColor: 'black', height: '20px' }}></div>
            : <a href={`mailto:${record.enquiryId.email}`}>{record.enquiryId.email}</a>
    }
/>


        <FunctionField label="Koko" render={record => `${record.enquiryId.size}m2`}/>

        <FunctionField
    label="Puhelinnumero"
    render={record =>
        record.status === "REJECT"
            ? <div style={{ backgroundColor: 'black', height: '20px' }}></div>
            :  `${record.countryCode || "0"}${record.enquiryId.contactNumber || ""}`
    }
/>


             <FunctionField
                label="Päivämäärä"
                render={(record) => {
                    if (!record.enquiryId.timeStamp) return "Ei aikaleimaa";
                    const date = new Date(Number(record.enquiryId.timeStamp));
                    if (isNaN(date.getTime())) return "Virheellinen päivämäärä";
                    return date.toLocaleString();
                }}
            />


        <FunctionField
                label="Status"
                render={(record) => {
                    switch (record.status) {
                        case "OPEN":
                            return "Avoin";
                            case "ACCEPT":
                                return "Voitettu";
                        case "CLOSED":
                            return "Päättynyt";
                        case "REJECT":
                            return "Hylätty";
                        case "MARK_AS_SOLD":
                            return "Myyty";
                        default:
                            return record.status; // Palauttaa alkuperäisen arvon, jos se ei ole 'OPEN' tai 'CLOSED'
                    }
                }}
            />


<FunctionField
    label="Toimeksianto"
    render={record => (
        (record.status === "OPEN" || record.status === "ACCEPT") && 
        <CreateRelatedListingButton record={record} />
    )}
/>

         
      </Datagrid>
       )}
    </List>
    </>
  );
};

export default AgencyEnquiryList;

