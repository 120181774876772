import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRedirect, TabbedForm, FormTab, Toolbar, Title, usePermissions, FormDataConsumer   } from 'react-admin';
import { TextField, Button, Switch, FormControlLabel, createTheme, ThemeProvider } from '@mui/material';
import ProfileSidebar from "./ProfileSidebar";
import Slider from "@mui/material/Slider";
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import {makeStyles} from '@material-ui/core';
import {useFormContext} from "react-hook-form";


const useStyles = makeStyles({
  title: {
    marginBottom: '2em',
  },
  imageContainer: {
    position: 'relative',
    marginBottom: '2em',
  },
  image: {
    width: '100px',
    margin: 'auto',
  },
  input1: {
    display: 'none',
  },
  label1: {
    fontSize: '1rem',
    fontWeight:'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'inline-block',
    overflow: 'hidden',
    padding: '5px',
    color: '#77C3EC',
    border: '2px solid #add8e6',
    marginTop: '10px',
    textAlign: 'center',
    fontFamily:'"Helvetica"',
    width:'70%'
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginTop: '10px',
    marginBottom: '20px',
  },
 
  uploadingText: {
    marginTop: '10px',
  },
  ehdot: {
textAlign:'left',
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // tai määritä tarkka korkeus, jos tarpeen
  },
  sigPad: {
    border: '2px solid #000',
    borderRadius: '5px',
    width: '100%;' ,
    height: '100px;', 
  },
  editform: {
    '@media (max-width: 768px)': {
      height: '900px', 
    },
  },
  textField: {
    width: '70%', // Oletusleveys työpöytäympäristössä
    '@media (max-width: 768px)': {
      width: '100%', // Leveys mobiililaitteilla
    }}
    
});

const theme = createTheme({

    components: {
      // Style overrides for TextField
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: '18px',
          },
        },
      },
      // Style overrides for Button
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '6px 16px',
            textTransform: 'none', // Ei suurikirjaimia
            fontWeight: 'bold',
          },
        },
      },
      // Tässä kohtaa MuiSlider tulee componentsin sisälle
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#1976d2', // Pääväri sliderille
          },
          thumb: {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgba(25, 118, 210, 0.16)`, // Sininen halo kun thumbia hoveroidaan tai on fokusoitu
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px rgba(25, 118, 210, 0.16)`, // Sininen halo kun thumb on aktiivinen
            },
          },
          track: {
            height: 9, // Tekee trackista korkeamman
            borderRadius: 4, // Pyöristetyt kulmat trackille
          },
          rail: {
            height: 12, // Yhtenäinen korkeus railille
            borderRadius: 4, // Pyöristetyt kulmat railille
            opacity: 0.5, // Railin läpinäkyvyys
            backgroundColor: '#b0bec5', // Railin väri
          },
          mark: {
            backgroundColor: '#1976d2', // Merkin väri, kun se ei ole aktiivinen
            height: 12,
            width: 1,
            top: 50
          },
          markActive: {
            backgroundColor: 'currentColor', // Aktiivisen merkin väri
          },
          valueLabel: {
            top: -12, // Säädä sijaintia ylöspäin, jotta label ei osu thumbiin
            right: -12, // Säädä sijaintia ylöspäin, jotta label ei osu thumbiin
            '& *': {
              background: 'transparent', // Poistaa taustan valueLabelistä
           
            },
          },
        },
      },
    },
  });
  
  const CustomToolbar = (props) => (
    <Toolbar {...props} >
.
    </Toolbar>
  );

const CustomEditPage = ({ id }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const [profile, setProfile] = useState({});
  const [filesData, setFilesData] = useState({
    profilePicture: { preview: '', isUploading: false },
    companyImage: { preview: '', isUploading: false }
    
  });
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [selectedCompanyImage, setSelectedCompanyImage] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useFormContext();

  
  // ...
  const handleFileChange = async (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilesData({ ...filesData, [type]: { preview: reader.result, isUploading: true } });
      };
      reader.readAsDataURL(file);
      
      // Ladataan kuva palvelimelle heti kun se on valittu
      await uploadImage(file, type);
    }
  };

  const uploadImage = async (file, type) => {
    const formData = new FormData();
    formData.append('pic', file);
    setFilesData({ ...filesData, [type]: { ...filesData[type], isUploading: true } });

    try {
      const response = await axios.post('https://api.salaovi.fi/User/uploadAttachment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.data && response.data.data.s3Url) {
        setProfile({ ...profile, [type]: response.data.data.s3Url });
        setFilesData({ ...filesData, [type]: { ...filesData[type], isUploading: false } });
        notify('Kuva ladattu onnistuneesti', 'info');
      } else {
        setFilesData({ ...filesData, [type]: { ...filesData[type], isUploading: false } });
        notify('Kuvan lataus epäonnistui', 'warning');
      }
    } catch (error) {
      setFilesData({ ...filesData, [type]: { ...filesData[type], isUploading: false } });
      notify(`Kuvan lataamisessa tapahtui virhe: ${error.message}`, 'error');
    }
  };

  // ...

  const renderFileInputField = (name, label, defaultImage) => {
    return (
      <div>
        <input
          type="file"
          id={name}
          accept="image/*"
          className={classes.input1}
          onChange={(e) => handleFileChange(name, e)}
          disabled={filesData[name].isUploading}
        />
        <label htmlFor={name} className={classes.label1}>
          {label}
        </label>
        <img
          src={filesData[name].preview || defaultImage}
          alt={`${label} esikatselu`}
          className={classes.imagePreview}
        />
        {filesData[name].isUploading && <p>Ladataan odota...</p>}
        {!filesData[name].isUploading && filesData[name].preview && (
          <p>Kuva ladattu!</p>
        )}
      </div>
    );
  };


    useEffect(() => {
        // Haetaan profiilin tiedot
        dataProvider.get('profiili', { id: id })
            .then(({ data }) => setProfile(data.data))
            
            .catch((error) => {
                notify(`Virhe: ${error.message}`, 'warning');
            });
            
    }, [id, dataProvider, notify]);

  // Muutettu handleChange-funktio
  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile({ ...profile, [name]: value });
  };
     // Tässä käsitellään kytkinten muutokset
     const handleToggleChange = (name) => (event) => {
        setProfile({ ...profile, [name]: event.target.checked });
    };

    const handleSubmit = async () => {
  

      

        if (!profile) {
            console.error('Profiiliobjektia ei ole määritelty');
            notify('Profiiliobjektia ei ole määritelty', 'warning');
            return;
        }

        let updatedProfile = {
          ...profile,
          profile_link: 'null',
          // Oletusarvot, jotka ovat voimassa kaikille käyttäjille
        };
 // Muokkaa muuttujaa ehtolauseiden perusteella
 if (permissions === 'AGENT') {
  updatedProfile = {
    ...updatedProfile,

  };
} else if (permissions === 'AGENCY') {
  updatedProfile = {
    ...updatedProfile,
    about: 'null',
    agency:"6080001e12ac3e0df4a35619",
    vacationMode: false
  };
}




    
        // Lista kentistä, jotka eivät ole sallittuja API:n toimesta
        const forbiddenFields = [
            '_id', 'facebookId', 'accessToken', 'type', 'dob', 'deviceType',
            'deviceToken', 'isBlocked', 'isActive', 'otp', 'otpVerify',
            'userType', 'isExist', 'upGrade', 'notification', 'timeStamp',
            '__v', 'superAgent', 'updatedAt', 'location', 'vat', 'tosAccepted','plan',
            'selectionCount', 'linkedAgency','agents', 'createdAt'
        ];
    
        // Filtteröidään sallitut kentät uuteen objektiin
        const filteredProfile = Object.keys(updatedProfile)
        .filter(key => !forbiddenFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = updatedProfile[key];
            return obj;
        }, {});
    
        // Muuta tässä koodissa minDistance ja legalRealtor kuten aiemmin neuvottu
        filteredProfile.minDistance = String(profile.minDistance);
        filteredProfile.maxDistance = String(profile.maxDistance);
        filteredProfile.legalRealtor = [profile.legalRealtor];
    

        if (permissions === 'AGENCY') {
          // Käytä toimistoprofiilien päivittämiseen tarkoitettua funktiota
          try {
              const response = await dataProvider.update('agencyprofile', { id: profile._id, data: filteredProfile });
              notify('Toimistoprofiili päivitetty onnistuneesti');
              // Mahdollinen redirect tai muu toiminto
          } catch (error) {
              console.error('Virhe toimistoprofiilin päivityksessä:', error);
              notify(`Virhe päivityksessä: ${error.message}`, 'warning');
          }
      } else {
          // Käytä tavallista käyttäjäprofiilien päivittämiseen tarkoitettua funktiota
          try {
              const response = await dataProvider.update('profiili', { id: profile._id, data: filteredProfile });
              notify('Profiili päivitetty onnistuneesti');
              // Mahdollinen redirect tai muu toiminto
          } catch (error) {
              console.error('Virhe profiilin päivityksessä:', error);
              notify(`Virhe päivityksessä: ${error.message}`, 'warning');
          }
      }
      console.log("Lähetettävä profiili ennen päivitystä:", filteredProfile);

      console.log("Lähetettävä profiili:", updatedProfile);
   

  };



      const handleAgencyChange = (e) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
        
    };

      const renderDropdownField = (name, label, value, handleChange) => {
        return (
          <TextField
            select
            name={name}
            className={classes.textField}
            required
            label={label}
            value={value}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
          >
            <option value="6080001e12ac3e0df4a35619">En työskentele</option>
            <option value="6075a6fff368204a35dc7ef0">RE/MAX</option>
            <option value="6075a70cf368204a35dc7ef2">Huom!</option>
            <option value="6075a728f368204a35dc7ef6">Kiinteistömaailma</option>
            <option value="6075a717f368204a35dc7ef3">Roof Group</option>
            <option value="6075a731f368204a35dc7ef7">Huoneistoketju</option>
            <option value="6075a721f368204a35dc7ef5">Sp-Koti</option>
            <option value="6075a71cf368204a35dc7ef4">Aninkainen</option>
            <option value="6075a739f368204a35dc7ef8">Glik</option>
            <option value="6075a706f368204a35dc7ef1">Kahdeksas päivä</option>
            <option value="6075a740f368204a35dc7ef9">Solid House</option>
            <option value="6076a4457c6ce14bfa9be56b">Kotijoukkue</option>
            <option value="6076a4fc7c6ce14bfa9be56c">Bo LKV</option>
            <option value="652d332b1467be0fbc71f0b0">Neliöt Liikkuu</option>
          </TextField>
        );
      };
      
    
const handleDistanceChange = (event, newValue) => {
    const newMaxDistance = String(Math.round(newValue * 1000));

    setProfile({ ...profile, maxDistance: newMaxDistance });
};
      
    const { fullName, address, profilePicture, JobTitle, companyName } =
    profile;
  return (
    <div style={{ display: "flex" }}>
      <ProfileSidebar
        fullName={fullName || "Tuntematon"} // Käytä dataa tai oletusarvoa
        address={address || "N/A"}
        companyName={companyName || "N/A"}
        JobTitle={JobTitle || "N/A"}
        profileImage={
          profilePicture ||
          "https://secure.gravatar.com/avatar/08c2a3635dca1dfc489e617d9e5d7026?s=64&d=mm&r=g"
        }
      />
      <div style={{ flexGrow: 1 }}>
      <Title title="Muokkaa Profiilia" />
      <ThemeProvider theme={theme}>
        <TabbedForm className={classes.editform}  record={profile}  toolbar={<CustomToolbar />} onSubmit={handleSubmit}>
          <FormTab label="Yleiset">
            <TextField  className={classes.textField} label="Nimi" name="fullName" value={profile.fullName || ''} onChange={handleChange} />
            <TextField  className={classes.textField}  label="Titteli" name="jobTitle" value={profile.jobTitle || ''} onChange={handleChange} />
            <TextField  className={classes.textField} label="Puhelinnumero" name="contactNumber" value={profile.contactNumber || ''} onChange={handleChange} />
            <TextField  className={classes.textField} label="Sähköposti" name="email" value={profile.email || ''} onChange={handleChange} />
            {permissions !== 'AGENCY' && (
              <>
                <TextField  className={classes.textField} label="Alottanut välittäjänä" name="startWorking" value={profile.startWorking || ''} onChange={handleChange} />
                
                <TextField  className={classes.textField} label="Lyhyt kuvaus sinusta" name="about" value={profile.about || ''} onChange={handleChange} multiline />
                <TextField  className={classes.textField} label="Vaihda Salasana" name="password" onChange={handleChange} multiline />
              </>
            )}
            {renderFileInputField('profilePicture', 'Päivitä profiilikuva', profile.profilePicture || 'default-profile-pic.jpg')}
            <Button onClick={handleSubmit} color="primary" variant="contained">Tallenna</Button>
          </FormTab>
         
            <FormTab label="Välitysliike">
              <TextField  className={classes.textField} label="Yrityksen nimi" name="companyName" value={profile.companyName || ''} onChange={handleChange} />
              <TextField className={classes.textField} label="Osoite" name="address" value={profile.address || ''} onChange={handleChange} />
              {permissions !== 'AGENCY' && (
                <>
              {renderDropdownField('agency', 'Työskenteletkö jossain näistä?', profile.agency || '', handleAgencyChange)}
              </>
              )}
              {renderFileInputField('companyImage', 'Päivitä yrityksen logo', profile.companyImage || 'default-company-logo.jpg')}
              <Button onClick={handleSubmit} color="primary" variant="contained">Tallenna</Button>
            </FormTab>
            {permissions !== 'AGENCY' && (
            <FormTab label="Asetukset">
            <div style={{ width: '70%' }}>
    <Typography id="distance-slider" gutterBottom>
      Etäisyys (km)
      <p></p>
    </Typography>
    <Slider
  value={profile.maxDistance / 1000 || ''} // Jaetaan arvo tuhannella näytettäväksi käyttöliittymässä
  onChange={handleDistanceChange}
  aria-labelledby="distance-slider"
  valueLabelDisplay="on" // Näyttää arvon liukukytkimessä
  min={10}
  max={100}
  valueLabelFormat={(value) => `${Math.round(value)} km`} // Poistetaan desimaalit ja lisätään 'km'
/>
<Typography id="distance-slider" gutterBottom>
      Kunka monen kilometrin päähän matkustat?
      <p></p>
    </Typography>
  </div>
  <FormControlLabel
  control={
    <Switch
      color="primary"
      checked={Boolean(profile.vacationMode)}
      onChange={handleToggleChange('vacationMode')}
    />
  }
  label="Lomatila"
/>
<FormControlLabel
  control={
    <Switch
      color="primary"
      checked={Boolean(profile.compete_with_agency)}
      onChange={handleToggleChange('compete_with_agency')}
    />
  }
  label="Kilpailetko saman välitysliikkeen sisällä?"
/>
<FormControlLabel
  control={
    <Switch
      color="primary"
      checked={Boolean(profile.legalRealtor)}
      onChange={handleToggleChange('legalRealtor')}
    />
  }
  label="Oletko laillistettu kiinteistönvälittäjä?"
/>

<Button onClick={handleSubmit} color="primary" variant="contained">Tallenna</Button>
<br></br>
            </FormTab>
           
            )}


<FormTab label="Arvostelut">
  <h2>Arvostelut</h2>
  {profile.reviews && profile.reviews.length > 0 ? (
    profile.reviews.map((review, index) => (
      <div key={index}>
        <h5>Arvostelu </h5>
        {review.comment && (
          <p>Arvostelu: {review.comment}</p>
        )}
        {review.rating && (
          <p>{Array(review.rating).fill(<span>★</span>)}</p>
        )}
        {review.reviewerName && (
          <p>Käyttäjä: {review.reviewerName}</p>
        )}
      </div>
    ))
  ) : (
    <p>Ei vielä arvosteluja</p>
  )}
</FormTab>

        </TabbedForm>

        </ThemeProvider>

        </div>
        </div>
    );
};

export default CustomEditPage;
