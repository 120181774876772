import {
    TextField,
    FormControlLabel,
    Switch,
  } from "@material-ui/core";
  import DeleteIcon from '@mui/icons-material/Delete';
  import { Button, Grid, IconButton } from "@mui/material";

const PastListingForm = ({ formData, setFormData }) => {

    const handleAddPastListing = () => {
      const newPastListing = {
        id: Date.now(), // Uniikki tunniste jokaiselle listaukselle
        address: '',
        size: '',
        price: '',
      };
      setFormData(prevData => ({
        ...prevData,
        pastListing: [...prevData.pastListing, newPastListing],
      }));
    };
  
    const handleRemovePastListing = (index) => {
      setFormData(prevData => ({
        ...prevData,
        pastListing: prevData.pastListing.filter((_, i) => i !== index),
      }));
    };
  
    const handleChange = (index, field, value) => {
      setFormData(prevData => {
        const newPastListings = [...prevData.pastListing];
        const updatedListing = { ...newPastListings[index], [field]: value };
        newPastListings[index] = updatedListing;
        return { ...prevData, pastListing: newPastListings };
      });
    };
    
  
    return (
      <div>
        <Button variant="contained" onClick={handleAddPastListing} style={{ marginBottom: '20px' }}>Lisää Myyty kohde</Button>
        {formData.pastListing.map((listing, index) => (
          <div key={listing.id} style={{ marginBottom: '10px' }}> 
            <TextField
              key={`address-${listing.id}`}
              label="Osoite. Katu 1, kaupunki"
              value={listing.address}
              onChange={(e) => handleChange(index, 'address', e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <TextField
              key={`size-${listing.id}`}
              label="Koko"
              value={listing.size}
              onChange={(e) => handleChange(index, 'size', e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <TextField
              key={`price-${listing.id}`}
              label="Hinta"
              type="number"
              value={listing.price}
              onChange={(e) => handleChange(index, 'price', e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <IconButton onClick={() => handleRemovePastListing(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
    );
  };

  export default PastListingForm;