import React from 'react';
import './components/Dashboard.css';

const Dashboard = () => {
    const dashboardStyle = {
      display: 'flex',
      flexWrap: 'wrap',
 
    };
  
    const largeGridContainerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    };
  
    const gridItemStyle = {
      margin: '5px',
      border: 'none',
      '@media (max-width: 768px)': {
        width: '100%', // full width on mobile
        height: 'auto', // adjust height as necessary
      },
    };
  
    const smallGridContainerStyle = {
      '@media (max-width: 768px)': {
        width: '100%', // full width on mobile
      },
    };
  
    return (
        <>
        <h2>Salaoven Takaovi v2.0</h2> 
      <div className="dashboard-container" style={dashboardStyle}>
        
      
          
      
      </div>
      </>
    );
  };
Dashboard.label = "Hallintapaneeli";
export default Dashboard;




      {/*    <div className="grid-item" style={{ width: '50%', height: '300px' }}>
            <MonthlyRevenueChart />
        </div>
        <div className="grid-item" style={{ width: '100%', height: '300px' }}>
            <MonthlyComparisonsChart />
        </div> */}