import * as React from 'react';
import axios from 'axios';

import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField as RaTextField, // react-admin TextField nimetään uudelleen
    DateField,
    Button,
    FunctionField,
    SimpleList
  } from 'react-admin';
  import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    InputAdornment // Tämä TextField tulee Material-UI:sta
  } from "@material-ui/core";
import { Typography } from '@mui/material';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useNavigate } from 'react-router-dom';



const WelcomeCard = () => {
  const storedProfile = localStorage.getItem('profile');
  const name = storedProfile ? JSON.parse(storedProfile).fullName : 'Vieras';

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          Myynnissä olevat kohteesi
        </Typography>
<p>Tällä sivulla voit tarkastella kaikkia niitä kohteita, jotka olet ilmoittanut saaneesi myyntiin. Kun kauppa on onnistuneesti toteutunut, ole hyvä ja merkitse kyseiset kohteet täällä myydyiksi. </p>        
</CardContent>
    </Card>
  );
}

  const MarkAsSoldModal = ({ open, handleClose, enquiryId, defaultCommission, defaultPrice }) => {
    const [cards, setCards] = useState([]); // Alustetaan kortit tilaan


    const navigate = useNavigate();
  

    


    const [commission, setCommission] = useState(defaultCommission.toString());
    const [price, setPrice] = useState('');

    useEffect(() => {
        setCommission(defaultCommission ? defaultCommission.toString() : '');
        setPrice(defaultPrice);
      }, [defaultCommission, defaultPrice]);
    
      const calculateOurCommission = (price, agentCommissionRate) => {
        const agentCommission = (price * agentCommissionRate) / 100; // Välittäjän komissio
        const ourBaseCommission = (agentCommission * 5) / 100; // Oma 5% komissio
        const ourFinalCommission = ourBaseCommission * 1.24; // Lisätään 24% ALV omaan komissioon
        return ourFinalCommission.toFixed(2); // Pyöristetään kahden desimaalin tarkkuudella
      };
      

  const handleSubmit = async () => {
    // Olettaen, että token on tallennettu local storageen tai tilaan
    const token = window.localStorage.getItem('token');

    try {
      const response = await axios.post('https://api.salaovi.fi/Vendor/markAsSold', {
        enquiryId,
        commission,
        price
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Käsittele onnistunut pyyntö
      if (response.status === 200) {
        // Sulje modal ja päivitä listaus
        handleClose();
        // Tässä voisi olla notifikaatio onnistumisesta
      }
    } catch (error) {
      // Käsittele virhetilanne
      console.error(error);
      // Tässä voisi olla notifikaatio virheestä
    }
  };


  return (
    <Dialog open={open}onClose={handleClose}  style={{ maxWidth: '500px',padding:'', margin: '5px auto' }} // Määritä tyyli suoraan komponentille
    PaperProps={{
      style: {
        padding: '10px', // Määritä padding Paper-komponentille
      },
    }}>
      <DialogTitle>Merkitse myydyksi</DialogTitle>
      <Typography style={{ padding:'10px 10px 10px 22px', margin: '5px auto' }}>Merkitse tähän todellinen myyntihinta sekä komissioprosenttisi.<p></p> Salaoven 5% komissio veloitetaan maksutavaltasi. Tiedot vahvistetaan hintaseurantapalvelusta. </Typography>
      <DialogContent>
        <br></br>
      <TextField
  autoFocus
  margin="dense"
  label="Komissio"
  type="number"
  value={commission}
  onChange={(e) => setCommission(e.target.value)}
  InputProps={{
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  }}
/>
<br></br>
<TextField
  margin="dense"
  label="Pyyntihinta"
  type="number"
  value={price}
  onChange={(e) => setPrice(e.target.value)}
  InputProps={{
    endAdornment: <InputAdornment position="end">€</InputAdornment>,
  }}
/>
<Typography>
    Laskutettava komissio: {calculateOurCommission(price, commission)}€
  </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Peruuta
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Lähetä
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export const AllListing = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [commission, setCommission] = useState(''); // Tai jokin alkuarvo
    const [price, setPrice] = useState(''); // Tai jokin alkuarvo
    const isMobile = window.innerWidth <= 768;
  
    const handleOpenModal = (record) => {
        if (record && record.enquiryId && record.enquiryId._id) {
          setSelectedEnquiry(record.enquiryId._id);
          setCommission(record.commission.toString()); 
          setPrice(record.price);
          setOpen(true);
        } else {
        }
      };
      
      
  const rowStyle = (record, index) => {
    return record.status === "MARK_AS_SOLD" ? { backgroundColor: '#90ee90' } : {};
};

  const handleCloseModal = () => {

    setOpen(false);
  };

  const renderMarkAsSoldButton = (record) => {
    // Muista tarkistaa molemmat ehdot erikseen
    if (record.status === 'OPEN' || record.status === 'ACCEPT') {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation(); // Estä eventin kupliminen ylöspäin
            handleOpenModal(record);
          }}
        >
          Merkitse myydyksi
        </Button>
      );
    }
    return null;
  };


  
    return (

      <>

      <WelcomeCard />
    <br></br>
    <List {...props} sort={{ field: 'id', order: 'DESC' }}  title="Myytävät kohteet" >
    {isMobile ? (
        <>

           <SimpleList
           primaryText={record => record.address}
           secondaryText={record => `${record.userId.clientName}`}
           tertiaryText={record => new Intl.NumberFormat('fi-FI').format(record.price) + '€'}
           leftAvatar={record => (
            <img
              src={Array.isArray(record.enquiryId.images) && record.enquiryId.images.length > 0
                    ? record.enquiryId.images[0]
                    : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
              alt="Kuva"
              width={50}
              height={50}
            />
           )}
         />
         </>
      ) : (

        <Datagrid  rowStyle={rowStyle}>
        <FunctionField
  label="Kuva"
  render={record =>
    <img
      src={Array.isArray(record.enquiryId.images) && record.enquiryId.images.length > 0
        ? record.enquiryId.images[0]
        : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"}
      alt="Kuva"
      width={150}
    />
  }
/>

            <FunctionField  render={record => ` ${record.clientName}`} label="Asiakas" />
            <FunctionField label="Puhelinnumero" render={record => `+358 ${record.userId.contactNumber}`}/>
            <FunctionField render={record => ` ${record.address}`}  label="Osoite" />

            <FunctionField 
  label="Pyyntihinta" 
  render={record => new Intl.NumberFormat('fi-FI').format(record.price) + '€'} 
/>
            <FunctionField label="Komissio" render={record => `${record.commission}%`}/>
            <FunctionField label="Koko" render={record => `${record.apartmentSize}m2`}/>
            <DateField source="timeStamp"  label="Aika" showTime />
            <FunctionField
                label="Status"
                render={(record) => {
                    switch (record.status) {
                        case "OPEN":
                            return "Myynnissä";
                        case "CLOSED":
                            return "Päättynyt";
                        case "REJECT":
                            return "Hylätty";
                        case "MARK_AS_SOLD":
                            return "MYYTY";
                            case "ACCEPT":
                                return "Myynnissä";
                        default:
                            return record.status; // Palauttaa alkuperäisen arvon, jos se ei ole 'OPEN' tai 'CLOSED'
                    }
                }}
            />
           <MarkAsSoldModal
    open={open}
    handleClose={handleCloseModal}
    enquiryId={selectedEnquiry}
    defaultCommission={commission} // Nämä ovat nyt ajan tasalla
    defaultPrice={price}
/>
             <FunctionField
          label="Toiminnot"
          render={renderMarkAsSoldButton}
        />
        </Datagrid>
          )}
     
    </List>
    </>
  );
};
