
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from "@emotion/styled";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';

// CSS styles extracted from the provided HTML model
const ContentContainer = styled.div `
             img + div {
             display: none !important; /* Hides image downloading in Gmail */
             }
             @media screen and (max-width: 600px) {
             /** Gmail **/
             *[class="Gmail"] {
             display: none !important
             }
             /** Wrapper **/
             .Wrapper {
             max-width: 600px !important;
             min-width: 320px !important;
             width: 100% !important;
             border-radius: 0 !important;
             }
             .Section {
             width: 100% !important;
             }
             .Section--last {
             border-bottom-left-radius: 0 !important;
             border-bottom-right-radius: 0 !important;
             }
             .Callout {
             border-radius: 0 !important;
             }
             /** Notice **/
             .Notice {
             border-bottom-left-radius: 0 !important;
             border-bottom-right-radius: 0 !important;
             }
             /** Header **/
             .Header .Header-left,
             .Header .Header-right {
             border-top-left-radius: 0 !important;
             border-top-right-radius: 0 !important;
             }
             /** Content **/
             .Content {
             width: auto !important;
             }
             /** Divider **/
             .Divider--kill {
             display: none !important;
             height: 0 !important;
             width: 0 !important;
             }
             /** Spacer **/
             .Spacer--gutter {
             width: 20px !important;
             }
             .Spacer--kill {
             height: 0 !important;
             width: 0 !important;
             }
             .Spacer--emailEnds {
             height: 0 !important;
             }
             /** Target **/
             .Target img {
             display: none !important;
             height: 0 !important;
             margin: 0 !important;
             max-height: 0 !important;
             min-height: 0 !important;
             mso-hide: all !important;
             padding: 0 !important;
             width: 0 !important;
             font-size: 0 !important;
             line-height: 0 !important;
             }
             .Target::before {
             content: '' !important;
             display: block !important;
             }
             /** Header **/
             .Header-area {
             width: 100% !important;
             }
             .Header-left,
             .Header-left::before,
             .Header-right,
             .Header-right::before {
             height: 156px !important;
             width: auto !important;
             background-size: 252px 156px !important;
             }
             .Header-left {
             background-image: url('https://salaovi.fi/wp-content/assets/Left.png') !important;
             background-position: bottom right !important;
             }
             .Header-right {
             background-image: url('https://salaovi.fi/wp-content/assets/Right.png') !important;
             background-position: bottom left !important;
             }
             .Header-icon,
             .Header-icon::before {
             width: 96px !important;
             height: 156px !important;
             background-size: 96px 156px !important;
             }
             .Header-icon {
             width: 96px !important;
             height: 156px !important;
             background-image: url('https://salaovi.fi/wp-content/assets/twelve_degree_icon@2x.png') !important;
             background-position: bottom center !important;
             }
             /** Table **/
             .Table-content {
             width: auto !important;
             }
             .Table-rows {
             width: 100% !important;
             }
             }
             @media screen and (max-width: 599px) {
             /** Data Blocks **/
             .DataBlocks-item {
             display: block !important;
             width: 100% !important;
             }
             .DataBlocks-spacer {
             display: block !important;
             height: 12px !important;
             width: auto !important;
             }
             }
             span.Delink a {
             color: inherit !important;
             text-decoration: none !important;
             }
             a {
             text-decoration: none !important;
             }
             /* overrides addresses, numbers, etc. being linked */
             span.apple-override-header a {
             color: #ffffff !important;
             text-decoration: none !important;
             }
             span.apple-override-hidden a {
             color: #084c71 !important;
             text-decoration: none !important;
             }
             span.apple-override-dark a {
             color: #292e31 !important;
             text-decoration: none !important;
             }
             span.apple-override-light a {
             color: #77858c !important;
             text-decoration: none !important;
             }
             /* [override] prevents Yahoo Mail breaking media queries */
             /* retina */
             @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
             body[override] span.retina img {
             }
             body[override] td.icon span.retina {
             background-size: 72px 72px !important;
             display: block !important;
             height: 72px !important;
             width: 72px !important;
             }
             body[override] table.card td.card-type span.retina {
             display: block !important;
             height: 16px !important;
             }
             /* default */
             body[override] table.card-default td.card-type span.retina {
             background-size: 22px 16px !important;
             width: 22px !important;
             }
             body[override] table.card-default tr.card-dark td.card-type span.retina {
             background-size: 22px 16px !important;
             width: 22px !important;
             }
             /* visa */
             body[override] table.card-visa td.card-type span.retina {
             background-size: 36px 16px !important;
             width: 36px !important;
             }
             body[override] table.card-visa tr.card-dark td.card-type span.retina {
             background-size: 36px 16px !important;
             width: 36px !important;
             }
             /* mastercard */
             body[override] table.card-mastercard td.card-type span.retina {
             background-size: 75px 16px !important;
             width: 75px !important;
             }
             body[override] table.card-mastercard tr.card-dark td.card-type span.retina {
             background-size: 75px 16px !important;
             width: 75px !important;
             }
             /* amex */
             body[override] table.card-amex td.card-type span.retina {
             background-size: 45px 16px !important;
             width: 45px !important;
             }
             body[override] table.card-amex tr.card-dark td.card-type span.retina {
             background-size: 45px 16px !important;
             width: 45px !important;
             }
             /* discover */
             body[override] table.card-discover td.card-type span.retina {
             background-size: 57px 16px !important;
             width: 57px !important;
             }
             body[override] table.card-discover tr.card-dark td.card-type span.retina {
             background-size: 57px 16px !important;
             width: 57px !important;
             }
             /* jcb */
             body[override] table.card-jcb td.card-type span.retina {
             background-size: 19px 16px !important;
             width: 19px !important;
             }
             body[override] table.card-jcb tr.card-dark td.card-type span.retina {
             background-size: 19px 16px !important;
             width: 19px !important;
             }
             /* diners */
             body[override] table.card-diners td.card-type span.retina {
             background-size: 20px 16px !important;
             width: 20px !important;
             }
             body[override] table.card-diners tr.card-dark td.card-type span.retina {
             background-size: 20px 16px !important;
             width: 20px !important;
             }
             }
             /* tablets */
             @media all and (max-device-width: 768px) {
             body[override] span.retina img {
             }
             body[override] td.font-large, body[override] td.font-large span, body[override] td.font-large a {
             font-size: 18px !important;
             line-height: 25px !important;
             }
             body[override] td.font-medium, body[override] td.font-medium span, body[override] td.font-medium a {
             font-size: 16px !important;
             line-height: 23px !important;
             }
             body[override] td.font-small, body[override] td.font-small span, body[override] td.font-small a {
             font-size: 15px !important;
             line-height: 21px !important;
             }
             body[override] td.icon span.retina {
             background-size: 72px 72px !important;
             display: block !important;
             height: 72px !important;
             width: 72px !important;
             }
             body[override] td.title, body[override] td.title span, body[override] td.title a {
             font-size: 24px !important;
             line-height: 28px !important;
             }
             body[override] table.card td.card-type span.retina {
             display: block !important;
             height: 19px !important;
             }
             /* default */
             body[override] table.card-default td.card-type span.retina {
             background-size: 25px 19px !important;
             width: 25px !important;
             }
             body[override] table.card-default tr.card-dark td.card-type span.retina {
             background-size: 25px 19px !important;
             width: 25px !important;
             }
             /* visa */
             body[override] table.card-visa td.card-type span.retina {
             background-size: 43px 19px !important;
             width: 43px !important;
             }
             body[override] table.card-visa tr.card-dark td.card-type span.retina {
             background-size: 43px 19px !important;
             width: 43px !important;
             }
             /* mastercard */
             body[override] table.card-mastercard td.card-type span.retina {
             background-size: 87px 19px !important;
             width: 87px !important;
             }
             body[override] table.card-mastercard tr.card-dark td.card-type span.retina {
             background-size: 87px 19px !important;
             width: 87px !important;
             }
             /* amex */
             body[override] table.card-amex td.card-type span.retina {
             background-size: 53px 19px !important;
             width: 53px !important;
             }
             body[override] table.card-amex tr.card-dark td.card-type span.retina {
             background-size: 53px 19px !important;
             width: 53px !important;
             }
             /* discover */
             body[override] table.card-discover td.card-type span.retina {
             background-size: 70px 19px !important;
             width: 70px !important;
             }
             body[override] table.card-discover tr.card-dark td.card-type span.retina {
             background-size: 70px 19px !important;
             width: 70px !important;
             }
             /* jcb */
             body[override] table.card-jcb td.card-type span.retina {
             background-size: 22px 19px !important;
             width: 22px !important;
             }
             body[override] table.card-jcb tr.card-dark td.card-type span.retina {
             background-size: 22px 19px !important;
             width: 22px !important;
             }
             /* diners */
             body[override] table.card-diners td.card-type span.retina {
             background-size: 23px 19px !important;
             width: 23px !important;
             }
             body[override] table.card-diners tr.card-dark td.card-type span.retina {
             background-size: 23px 19px !important;
             width: 23px !important;
             }
             /* */
             body[override] table.card td.card-digits, body[override] table.card td.card-digits span {
             font-size: 16px !important;
             line-height: 16px !important;
             }
             body[override] td.summary-total {
             font-size: 20px !important;
             line-height: 25px !important;
             }
             }
             /* mobile */
             @media all and (max-device-width: 500px) {
             body[override] table.width, body[override] td.width {
             width: 100% !important;
             }
             body[override] td.temp-padding, body[override] td.temp-padding div.clear {
             display: none !important;
             width: 0 !important;
             }
             body[override] td.banner {
             height: 186px !important;
             }
             body[override] td.subbanner-item {
             -moz-box-sizing: border-box !important;
             -webkit-box-sizing: border-box !important;
             box-sizing: border-box !important;
             display: block !important;
             padding-left: 10px !important;
             padding-right: 10px !important;
             text-align: center !important;
             width: 100% !important;
             }
             body[override] tr.summary-item td.summary-padding, body[override] tr.summary-item td.summary-padding div.clear {
             width: 17px !important;
             }
             body[override] a.browser-link {
             display: block !important;
             }
             }
          `;


          export const PopupReceipt = ({  selectedRecord  }) => {{      
            
            
            const savePdf = () => {
               // Valitse elementti, jonka haluat tallentaa PDF:ksi
               const element = document.getElementById('pdf-content');
           
               html2canvas(element).then((canvas) => {
                 const imgData = canvas.toDataURL('image/png');
           
                 // Luo uusi jsPDF-instanssi
                 const pdf = new jsPDF({
                   orientation: 'portrait',
                   unit: 'px',
                   format: 'a5',
                 });
           
                 // Lisää kuva PDF:ään
                 pdf.addImage(imgData, 'PNG', 0, 0);
           
                 // Tallenna PDF
                 pdf.save('salaovi-kuitti.pdf');
               });
             };
            
             const calculateVAT = (amount) => {
              const VAT_PERCENTAGE = 0.24; // 24%
              const preTaxAmount = amount / (1 + VAT_PERCENTAGE); // Lasketaan veroton summa
              const vatAmount = amount - preTaxAmount; // Lasketaan arvonlisäveron määrä
              return vatAmount.toFixed(2); // Pyöristetään kahteen desimaaliin
            };
            
             

          const htmlContent = ` <body class="Email" style="margin: 0;padding: 0;border: 0;background-color: #f1f5f9;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;min-width: 100% !important;width: 100% !important;">
          <div class="Background" style="min-width: 100%;width: 100%;">
            <table class="Wrapper" align="center" style="border: 0;border-collapse: collapse;margin: 0 auto !important;padding: 0;max-width: 500px 600px;width: 100%;">
              <tbody>
              <tr>
                <td style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;">
                  <table class="Divider Divider--small Divider--kill" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;"><tbody><tr><td class="Spacer Spacer--divider" height="20" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
      
                  <div class="Shadow" style="border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;box-shadow: 0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07);">
                    <table dir="ltr" class="Section Header" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Header-left Target" style="background-color: #084c71;border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-size: 0;line-height: 0px;mso-line-height-rule: exactly;background-size: 100% 100%;border-top-left-radius: 5px;" align="right" height="156" valign="bottom" width="252">
                          <a href="#" style="pointer-events: none;">
                            <img alt="" height="156" width="252" src="https://salaovi.fi/wp-content/assets/Left.png" style="display: block;border: 0;line-height: 100%;width: 100%;">
                          </a>
                        </td>
                        <td class="Header-icon Target" style="background-color: #084c71;border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-size: 0;line-height: 0px;mso-line-height-rule: exactly;background-size: 100% 100%;" align="center" height="156" valign="bottom" width="96">
                          <a href="https://salaovi.fi" target="_blank" style="-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;outline: 0;text-decoration: none;">
                            <img alt="" height="156" width="96" src="https://salaovi.fi/wp-content/assets/twelve_degree_icon@2x.png" style="display: block;border: 0;line-height: 100%;width: 100%;">
                          </a>
                        </td>
                        <td class="Header-right Target" style="background-color: #084c71;border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-size: 0;line-height: 0px;mso-line-height-rule: exactly;background-size: 100% 100%;border-top-right-radius: 5px;" align="left" height="156" valign="bottom" width="252">
                          <a href="#" style="pointer-events: none;">
                            <img alt="" height="156" width="252" src="https://salaovi.fi/wp-content/assets/Right.png" style="display: block;border: 0;line-height: 100%;width: 100%;">
                          </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div id="pdf-content">
                    <table class="Section Title" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content Title-copy Font Font--title" align="center" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #32325d;font-size: 24px;line-height: 32px;">
                              Maksukuitti myyjältä <span dir="ltr">Salaovi Oy</span>
                        </td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
                    <table class="Section Divider" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="8" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <table class="Section Title" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content Title-copy Font Font--title" align="center" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 15px;line-height: 18px;">
                          Maksukuitti #${selectedRecord.reference}
                        </td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
                    <table class="Section Divider" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="24" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <table class="Section DataBlocks" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;width: 100%;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;">
                          <table class="DataBlocks DataBlocks--three" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;width: 100%;">
                            <tbody>
                            <tr>
                              <td class="DataBlocks-item" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;">
                                <table style="border: 0;border-collapse: collapse;margin: 0;padding: 0;">
                                  <tbody>
                                  <tr>
                                    <td class="Font Font--caption Font--uppercase Font--mute Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 12px;line-height: 16px;white-space: nowrap;font-weight: bold;text-transform: uppercase;">
                                          Maksettu summa
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="Font Font--body Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;white-space: nowrap;">
                                    ${selectedRecord.amount} €
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
      
                              <td class="Spacer DataBlocks-spacer" width="20" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                              <td class="DataBlocks-item" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;">
                                <table style="border: 0;border-collapse: collapse;margin: 0;padding: 0;">
                                  <tbody>
                                  <tr>
                                    <td class="Font Font--caption Font--uppercase Font--mute Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 12px;line-height: 16px;white-space: nowrap;font-weight: bold;text-transform: uppercase;">
                                          Maksupäivämäärä
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="Font Font--body Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;white-space: nowrap;"><div>
                                      <div>${new Date(selectedRecord.createdAt).toLocaleDateString('fi-FI')}
                                      </div>
                                    </div></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
      
                                  <td class="Spacer DataBlocks-spacer" width="20" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                                  <td class="DataBlocks-item" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;">
                                    <table style="border: 0;border-collapse: collapse;margin: 0;padding: 0;">
                                      <tbody>
                                      <tr>
                                        <td class="Font Font--caption Font--uppercase Font--mute Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 12px;line-height: 16px;white-space: nowrap;font-weight: bold;text-transform: uppercase;">
                                              Maksutapa
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="Font Font--body Font--noWrap" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;white-space: nowrap;">Korttimaksu</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
      
                    <table class="Section Divider" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="32" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <table class="Section Copy" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content Font Font--caption Font--uppercase Font--mute Delink" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 12px;line-height: 16px;font-weight: bold;text-transform: uppercase;">
                          Yhteenveto
                        </td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      <tr><td class="Spacer Spacer--divider" colspan="3" height="12" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr>
                      </tbody>
                    </table>
      
                    <table class="Section Table" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--kill" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;">
                          <table class="Table-body" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;width: 100%;background-color: #f6f9fc;border-radius: 4px;">
                            <tbody>
                            <tr><td class="Spacer Spacer--divider" colspan="3" height="4" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr>
                            <tr>
                              <td class="Spacer Spacer--gutter" width="20" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                              <td class="Table-content" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 432px;">
                                <table class="Table-rows" width="432" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;">
                                  <tbody>
                                  <tr>
                                    <td class="Table-divider Spacer" colspan="3" height="6" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                                  </tr>
      
                                    <!-- item -->
        <tr>
          <td class="Table-description Font Font--body" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;">
            ${selectedRecord.customerId?.fullName || "salaovi lompakko"}

          </td>
          <td class="Spacer Table-gap" width="8" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
          <td class="Table-amount Font Font--body" align="right" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;">
          ${selectedRecord.amount} €
          </td>
        </tr>
        <!-- /item -->
        <tr>
          <td class="Table-divider Spacer" colspan="3" height="6" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
        </tr>
      
      <tr>
        <td class="Table-divider Spacer" colspan="3" height="6" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
      </tr>
      
      <tr>
        <td class="Table-description Font Font--body" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;">
            Arvonlisävero 24%
          </td>
         <td class="Spacer Table-gap" width="8" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
          <td class="Table-amount Font Font--body" align="right" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;">
          ${calculateVAT(selectedRecord.amount)} €</td>
      </tr>
      
      <tr>
        <td class="Table-divider Spacer" colspan="3" height="8" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
      </tr>
      
      
      <!-- Subtotal row -->
      
      <!-- Discount rows -->
      
      
      <!-- Taxes rows -->
      
      <!-- Total row -->
      <tr>
        <td class="Table-description Font Font--body Font--alt" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;font-weight: bold;">
          Maksettu summa
        </td>
        <td class="Spacer Table-gap" width="8" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
        <td class="Table-amount Font Font--body Font--alt" align="right" valign="top" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #525f7f;font-size: 15px;line-height: 24px;font-weight: bold;">
        ${selectedRecord.amount} €
        </td>
      </tr>
      <tr>
        <td class="Table-divider Spacer" colspan="3" height="12" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
      </tr>
      <!-- /breakdown -->
      
                                  </tbody>
                                </table>
                              </td>
                              <td class="Spacer Spacer--gutter" width="20" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                            </tr>
                            <tr>
                              <td class="Spacer Spacer--divider" colspan="3" height="4" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="Spacer Spacer--kill" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
      
      
                    <table class="Section Separator" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Spacer" bgcolor="e6ebf1" height="1" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
      
                   
      
                    <table class="Section Divider" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="32" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <table class="Section Separator" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Spacer" bgcolor="e6ebf1" height="1" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
                    <table class="Section Divider" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="32" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <!-- footer -->
                    <table class="Section Copy" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        
                      </tbody>
                    </table>
      
                    <table class="Section Divider Divider--small" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;"><tbody><tr><td class="Spacer Spacer--divider" height="0" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
      
                    <table class="Section Copy" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;">
                      <tbody>
                      <tr>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                        <td class="Content Footer-legal Font Font--caption Font--mute" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;width: 472px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;mso-line-height-rule: exactly;vertical-align: middle;color: #8898aa;font-size: 12px;line-height: 16px;">
      
                          <center><p> Myyjä <span dir="ltr"><a href="https://salaovi.fi" style="-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;outline: 0;text-decoration: none;color: #556cd6;"> Salaovi Oy&nbsp;</a></span></p></center>
                        <p><center>Salaovi Oy  3164381-8  Kalasatamankatu 9, 00580 Helsinki&nbsp;</center></p></td>
                        <td class="Spacer Spacer--gutter" width="64" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
      
      
                    <table class="Section Section--last Divider Divider--large" width="100%" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;background-color: #ffffff;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"><tbody><tr><td class="Spacer Spacer--divider" height="24" style="border: 0;border-collapse: collapse;margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #ffffff;font-size: 1px;line-height: 1px;mso-line-height-rule: exactly;">&nbsp;</td></tr></tbody></table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      
          <!-- /background -->
          </div>
        </body>

       `;




  return (
    
    <>
      <Button  
      color="primary"onClick={savePdf}>Tallenna PDF</Button>
        <ContentContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />
     

       
      
     
    </>
  );
}};

export default PopupReceipt;
