import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNotify, Title } from "react-admin";
import {
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Button, Grid, IconButton } from "@mui/material";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import PastListingForm from "./components/pastListingForm";






const uploadVideo = async (videoFile, setFormData, setUploading) => {
    setUploading(true);
  
    
    const formData = new FormData();
    formData.append('pic', videoFile);
  
    try {
      // Oletetaan, että käytetään axios-kirjastoa
      const response = await axios({
        method: 'post',
        url: 'https://api.salaovi.fi/User/uploadAttachment',
        data: formData,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Tarkistetaan onnistuiko pyyntö
      if (response.status === 200 && response.data.message === "Success") {
        setFormData(prevFormData => ({
          ...prevFormData,
          videoLink: response.data.data.s3Url
        }));
      } else {
        console.error('Server response error:', response);
      }
    } catch (error) {
      console.error('Error uploading video:', error);
    } finally {
      setUploading(false);
    }
  };
  
  



// Muuntaa prosenttiosuuden liukukytkimen arvoksi.
const percentageToValue = (percentage) => (percentage / 100) * (5 - 2) + 2;
const valueToPercentage = (value) => ((value - 2) / (5 - 2)) * 100;
// Luo kaksiarvoinen liukukytkin komponentti
const CommissionSlider = ({ value, onChange, label }) => {


  const handleChange = (event, newValue) => {

    // Pyöristää arvot kahden desimaalin tarkkuuteen
    const roundedValues = newValue.map((value) => {
      const percentage = valueToPercentage(value);
      // Käytetään toFixed muuttamaan merkkijonoksi ja pyöristämään kahteen desimaaliin ja sitten muunnetaan se takaisin numeroksi
      return isNaN(percentage) ? 0 : Number(percentage.toFixed(2));
    });

    // Lähetetään ylös pyöristetyt prosenttiosuudet
    onChange(roundedValues);
  };

  return (
    <>
      {label && (
        <Typography id="commission-slider-label" gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        value={value.map(percentageToValue)}
        min={2}
        max={5}
        step={0.1}
        valueLabelDisplay="on" 
        onChange={handleChange}
        valueLabelFormat={(value) => value.toFixed(1) + "%"}
      />
    </>
  );
};





export const DefaultProposal = (record) => {
  const [cards, setCards] = useState([]); // Alustetaan kortit tilaan


  const navigate = useNavigate();


  
  const notify = useNotify();


  const storedProfile = JSON.parse(localStorage.getItem("profile"));
  const userId = storedProfile ? storedProfile.id : "";
  const [formData, setFormData] = useState({
    commisionStart: "",
    commisionEnd: "",
    videoLink: "",
    message: "",
    pastListing: [{
      address: "",
      size: "",
      price: "",
    }],
    photography: "",
    homeStaging: "",
    ownWebsite: "",
    postCards: "",
    virtualTour: "",
    landScaping: "",
    market: "",
  });





  // useEffect-hook lokitukselle
  useEffect(() => {

  }, [formData]); // Riippuvuuslista, tässä tapauksessa kun formData päivittyy







  useEffect(() => {

    if (record && record._id) {
      setFormData((formData) => ({
        ...formData,
        enquiryId: record._id,
      }));
    } else {

    }
  }, [record]);







  const handleChange = (name) => (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.value,
    }));
  };
  const handleToggleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.checked });
  };  

  // Muunna provisiot liukukytkimen prosenttiosuuksiksi
  const commissionValues = [
    valueToPercentage(formData.commisionStart),
    valueToPercentage(formData.commisionEnd),
  ];







  const handleSliderChange = (name) => (newValue) => {
    // Muunnetaan prosentit arvoiksi välillä 2-5 ja pyöristetään ne kahden desimaalin tarkkuuteen
    const newValues = newValue.map((percentage) => {
      const value = percentageToValue(percentage);
      // Pyöristetään arvo kahden desimaalin tarkkuuteen
      return Math.round(value * 100) / 100;
    });
  
    // Asetetaan muunnetut ja pyöristetyt arvot formDataan
    setFormData((formData) => ({
      ...formData,
      commisionStart: newValues[0],
      commisionEnd: newValues[1],
    }));
  };





  const [uploading, setUploading] = useState(false);
  const handleSave = async () => {
    const token = window.localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.salaovi.fi/Vendor/templateProposal",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        notify("Tarjouspohja päivitetty!");
      } else {
        notify("Jotain meni pieleen");
      }
    } catch (error) {
      console.error("Virhe lähettäessä tarjousta:", error);
      notify("Jotain meni pieleen ");
    }
  };

  
  


  useEffect(() => {
      const fetchData = async () => {
        const token = window.localStorage.getItem("token");
        try {
          const response = await axios.post(
            "https://api.salaovi.fi/Vendor/listProposal",
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `${token}`,
              },
            }
          );

       

          if (response.status === 200 && response.data && response.data.data) {
            const innerData = response.data.data;

            setFormData((prevData) => ({
              ...prevData,
              commisionStart: innerData.commisionStart || "",
              commisionEnd: innerData.commisionEnd || "",
              videoLink: innerData.videoLink || "",
              message: innerData.message || "",
              pastListing: innerData.pastListing || [],
              photography: typeof innerData.photography === 'boolean' ? innerData.photography : false,
              homeStaging: typeof innerData.homeStaging === 'boolean' ? innerData.homeStaging : false,
              ownWebsite: typeof innerData.ownWebsite === 'boolean' ? innerData.ownWebsite : false,
              postCards: typeof innerData.postCards === 'boolean' ? innerData.postCards : false,
              virtualTour: typeof innerData.virtualTour === 'boolean' ? innerData.virtualTour : false,
              landScaping: typeof innerData.landScaping === 'boolean' ? innerData.landScaping : false,
              market: typeof innerData.market === 'boolean' ? innerData.market : false,
            }));
          } else {
            console.error(
              "Väärä statuskoodi API-vastauksessa:",
              response.status
            );
            notify("Jotain meni pieleen tietojen haussa");
          }
        } catch (error) {
          console.error("Virhe haettaessa tietoja:", error);
          notify("Jotain meni pieleen tietojen haussa");
        }
      };

      fetchData();
    }, []);


  return (
<>
<Title title="Vakiotarjous" />
    <div style={{ maxWidth: '800px', padding:'20px', margin: '0 auto' }}> 
     <Typography variant="h5">Päivitä vakiotarjoustasi</Typography>
  <br></br>
  <Typography variant="p">Tämä on tarjouspohjasi ja sitä käytetään vakiotarjouksenasi mikäli et ehdi reagoida tarjouspyyntöön tunnin sisällä</Typography>
  <br></br><br></br>
        <Typography variant="body1">
    Videotervehdys (ei pakollinen)
  </Typography>

   
    {/* Latauspalkki ja video - vain toinen näkyy kerrallaan */}
    {uploading ? (
      // Latauspalkki, voit asettaa 'value'-attribuuttiin latauksen edistymisen
      <progress value="0" max="100"></progress> // Oletusarvoja ja maksimiarvoa voi säätää
    ) : (
      // Video-esikatseluruutu
      <div style={{ marginTop: '20px' }}>
        <video 
          width="100%" 
          controls 
          poster={formData.videoLink ? "" : "https://salaovi.s3.amazonaws.com/1699813435945.jpg"
        } // Jos videoLink on tyhjä, näytä placeholder-kuva
        >
          {formData.videoLink && <source src={formData.videoLink} type="video/mp4" />}
          Valitettavasti selaimesi ei tue videotoistoa.
        </video>
      </div>
    )}
            <input type="file" onChange={(e) => {
  const file = e.target.files[0];
  if (file) {
    uploadVideo(file, setFormData, setUploading);
  }
}} />
<p></p>
          <CommissionSlider
            label="Palkkiotarjous (%)"
            value={[
              valueToPercentage(formData.commisionStart),
              valueToPercentage(formData.commisionEnd),
            ]}

            onChange={handleSliderChange("commissionRange")}
          />
          <TextField
            label="Viesti"
            fullWidth
            multiline
            rows={6} // Voit asettaa tämän suuremmaksi jos haluat isomman tekstilaatikon
            value={formData.message}
            onChange={handleChange("message")}
          />
          <p></p>
<Typography variant="body1">
    Tarjous Sisältää
  </Typography>
  <Grid container spacing={1}>
  {/* Ensimmäinen palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.photography}
          onChange={handleToggleChange("photography")}
          color="primary"
        />
      }
      label="Ammattivalokuvaus"
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.homeStaging}
          onChange={handleToggleChange("homeStaging")}
          color="primary"
        />
      }
      label="Stailaus"
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.ownWebsite}
          onChange={handleToggleChange("ownWebsite")}
          color="primary"
        />
      }
      label="Somemarkkinointi"
    />
  </Grid>

  {/* Toinen palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.postCards}
          onChange={handleToggleChange("postCards")}
          color="primary"
        />
      }
      label="Esitteet, flyerit ym."
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.virtualTour}
          onChange={handleToggleChange("virtualTour")}
          color="primary"
        />
      }
      label="Virtuaaliesittely"
    />
    
  </Grid>

  {/* Kolmas palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.market}
          onChange={handleToggleChange("market")}
          color="primary"
        />
      }
      label="Kulukorvaukset"
    />
   <FormControlLabel
      control={
        <Switch
          checked={formData.landScaping}
          onChange={handleToggleChange("landScaping")}
          color="primary"
        />
      }
      label="Asiakirjat"
    />
  </Grid>
</Grid>
<Typography variant="body1">
    Vanhat kohteesi (max. 5kpl)
    <p></p>
  </Typography>
  <PastListingForm
  formData={formData}
  setFormData={setFormData}
/>



    
          <br></br> <br></br>
           <Button  variant="contained" onClick={handleSave} color="primary">
            Tallenna
          </Button>
  
   

    </div>
    </>
  );
};

export default DefaultProposal;
