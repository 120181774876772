import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import axios from 'axios';

const AddFundsForm = ({ onBalanceChange  }) => {
  const [amount, setAmount] = useState('');
  const notify = useNotify();

  const handleDeposit = async () => {
    const token = localStorage.getItem('token'); // Oletetaan, että token on tallennettu localStorageen nimellä 'token'
    if (!token) {
      notify('Tokenia ei löydy, kirjaudu sisään.', 'warning');
      return;
    }

    try {
      const response = await axios.post('https://api.salaovi.fi/User/Deposit', {
        amount
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Tarkista vastauksesta onnistuminen ja ilmoita käyttäjälle
      if (response.status === 200) {
        notify('Varat lisätty onnistuneesti.', 'info');
        setAmount(''); // Tyhjennä summa-kenttä
        onBalanceChange(); // Kutsu funktiota, joka päivittää saldon
      } else {
        // Jos palvelin palauttaa virheen, näytä se käyttäjälle
        notify(response.data.message || 'Virhe lisättäessä varoja.', 'error');
      }
    } catch (error) {
      // Jos pyyntö epäonnistuu, näytä virheilmoitus
      notify(error.message || 'Virhe lisättäessä varoja.', 'error');
    }
  };

  return (
    <div>
      <div className="input-group">
        <input
          className="StripeElement2"
          name="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Summa"
          type="number" 
        />
        <span className="currency-symbol">€</span>
      </div>
      <button className="add-funds-button" onClick={handleDeposit} disabled={!amount}>
        Lisää varoja
      </button>
    </div>
  );
};

export default AddFundsForm;
