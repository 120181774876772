import React from 'react';
import { List, Datagrid, TextField, EmailField, ImageField, FunctionField, DateField, useNotify, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@mui/material';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
// ... muut importit pysyvät samoina



const WelcomeCard = () => {
    const storedProfile = localStorage.getItem('profile');
    const name = storedProfile ? JSON.parse(storedProfile).fullName : 'Vieras';
  
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">
            Välittäjärosteri
          </Typography>
  <p>Tässä voitte tarkastella ja hallinnoida yritykseenne rekisteröityjä kiinteistönvälittäjiä. Varmistakaa, että välittäjän rekisteröity yrityksen nimi vastaa tarkasti omaanne jotta välittäjä näkyy listalla. Voitte lisätä välittäjän omaan rosteriinne painamalla lisää. Aktiivisessa rosterissa olevat välittäjät näkyvät vihreänä. Huomatkaa, että kaikki rosteriin lisättyjien välittäjien liidipalkkiot ja komissiot laskutetaan yrityksenne maksutavalta. </p>        
  </CardContent>
      </Card>
    );
  }

const useStyles = makeStyles({
  button: {
    margin: '5px',
    backgroundColor: 'green',
    color: 'white',
  },
  onVacation: {
    backgroundColor: 'red',
    color: 'white',
  },
  notOnVacation: {
    backgroundColor: 'green',
    color: 'white',
  },
  roundImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  removeButton: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
    },
  },
});

const rowStyle = (record, index) => {
  if (record.selectionCount !== null && record.selectionCount !== undefined) {
    return { backgroundColor: 'lightgreen' };
  }
  return {};
};

export const AgencyAgents = props => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();


  const addAgentToAgency = async (agentId, isRemove) => {
    const token = localStorage.getItem('token');
    const url = `https://api.salaovi.fi/User/addAgentsToAgency`;
    try {
      const response = await axios.post(url, {
        [isRemove ? 'removeAgents' : 'newAgents']: [agentId]
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        notify(isRemove ? 'Agentti poistettu onnistuneesti' : 'Agentti lisätty onnistuneesti', 'info');
        refresh(); // Päivittää listan
      } else {
        notify('Virhe lisättäessä/poistettaessa agenttia', 'warning');
      }
    } catch (error) {
      notify(`Virhe lähetettäessä/poistettaessa agenttia: ${error.message}`, 'warning');
    }
  };

  return (
    <>
    <WelcomeCard />
    <br></br>
    <List title="Välittäjärosteri" {...props}>
      <Datagrid  rowClick="show" rowStyle={rowStyle}>
      <FunctionField 
          label="Profiilikuva" 
          render={record => (
            <img 
              src={record.profilePicture} 
              alt={record.fullName} 
              className={classes.roundImage}
            />
          )}
        />
        
        <FunctionField
      label="Käyttäjä"
      render={record => `${record.jobTitle} ${record.fullName} `}
    />
        
        <FunctionField label="Puhelinnumero" render={record => `+358${record.contactNumber}`}/>
        <DateField source="createdAt" label="Liittynyt" />

        <FunctionField 
          label="Logo" 
          render={record => (
            <img 
              src={record.companyImage} 
              alt={record.companyImage} 
              className={classes.roundImage}
            />
          )}
        />
       <FunctionField label="Toiminto" render={record => (
            <Button 
              variant="contained"
              className={record.selectionCount >= 0 ? classes.removeButton : classes.addButton}
              onClick={() => addAgentToAgency(record._id, record.selectionCount >= 0)}
            >
              {record.selectionCount >= 0 ? 'Poista' : 'Lisää'}
            </Button>
          )} />
        <FunctionField label="Lomatila" render={record => (
          <Button 
            className={`${classes.button} ${record.vacationMode ? classes.onVacation : classes.notOnVacation}`}
          >
            {record.vacationMode ? 'Päällä' : 'ei'}
          </Button>
        )} />
        <TextField source="selectionCount" label="Vertailuja" />
      </Datagrid>
    </List>
    </>
  );
};
