import React, { useState, useEffect } from 'react';
import { MenuItemLink, usePermissions } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CustomLogout from './CustomLogout'; 
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import WalletIcon from '@mui/icons-material/Wallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

const MyMenu = ({ onMenuClick, logout }) => {
    const { permissions } = usePermissions();
    const [openLiikkeet, setOpenLiikkeet] = React.useState(true);
    const [openHallinta, setOpenHallinta] = React.useState(true);

    const handleClick = (setter) => () => setter(prevState => !prevState);




    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '30px 0 0  0 ', height: '95%' }}>
            {permissions === 'AGENT' &&(
            <>
            <MenuItemLink to="/openenquiry" primaryText="Uudet vertailut" leftIcon={<AssignmentIcon />} onClick={onMenuClick} />
            <MenuItemLink to="/allproposals" primaryText="Kaikki Vertailut" leftIcon={<CorporateFareIcon />}  onClick={onMenuClick} />
            <MenuItemLink to="/allListing" primaryText="Myytävät kohteet" leftIcon={<MapsHomeWorkIcon  />}  onClick={onMenuClick} />
            </>   
       )}

            {permissions === 'AGENCY' &&(
            <>
            <MenuItemLink to="/allagencyenquiry" primaryText="Välittäjien vertailut" leftIcon={<CorporateFareIcon />}  onClick={onMenuClick} />
        
            <MenuItemLink to="/allagencyListing" primaryText="Myytävät kohteet" leftIcon={<MapsHomeWorkIcon  />}  onClick={onMenuClick} />
            </>   
        )}

  
   
   
            {/* Dropdown-valikot */}
            <ListItem button onClick={handleClick(setOpenLiikkeet)}>
                <ListItemIcon>
                    {openLiikkeet ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary="Asetukset" />
            </ListItem>
            <Collapse in={openLiikkeet} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {permissions === 'AGENT' &&(
            <>
                    <MenuItemLink to="/vakiotarjous" primaryText="Vakiotarjous" leftIcon={<WorkIcon />}  onClick={onMenuClick} />
                    </>   
       )}
 {permissions === 'AGENCY' &&(
            <>

<MenuItemLink to="/agencyagents" primaryText="Välittäjärosteri" leftIcon={<RecentActorsIcon />}  onClick={onMenuClick} />

            </>   
            )}
    
                    <MenuItemLink to="/transactions" primaryText="Laskutus" leftIcon={<ReceiptIcon />}  onClick={onMenuClick} />
                    <MenuItemLink to="/maksutavat" primaryText="Lompakko" leftIcon={<WalletIcon />}  onClick={onMenuClick} />
                    
                </List>
            </Collapse>

            <ListItem button onClick={handleClick(setOpenHallinta)}>
                <ListItemIcon>
                    {openHallinta ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary="Tuki" />
            </ListItem>
            <Collapse in={openHallinta} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <MenuItemLink to="/tuki" primaryText="Tukikeskus" leftIcon={<ContactPhoneIcon />} onClick={onMenuClick} />
                    <MenuItemLink to="/opas" primaryText="Käyttöopas" leftIcon={<HelpCenterIcon />} onClick={onMenuClick} />
                </List>
            </Collapse> 

            <div style={{ marginTop: 'auto' }}>
            <MenuItemLink to="/profiili" primaryText="Muokkaa Profiilia" leftIcon={<UserIcon />}  onClick={onMenuClick} />
                <CustomLogout onClick={onMenuClick} />
                {logout}
                </div>
        </div>
    );
};

export default MyMenu;
