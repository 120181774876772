export default {
    
    ra: {
        action: {
            delete: 'Poista',
            show: 'Näytä',
            list: 'Lista',
            save: 'Tallenna',
            create: 'Luo',
            edit: 'Muokkaa',
            sort: 'Lajittele',
            cancel: 'Peruuta',
            undo: 'Peru',
            refresh: 'Päivitä',
            add: 'Lisää',
            remove: 'Poista',
            confirm: 'Vahvista',
            expand: 'Laajenna',
            close: 'Sulje',
            unselect: 'Poista valinta',
            export:'VIE (.csv)',
            back:'Palaa takaisin'
        },
        

        boolean: {
            true: 'Kyllä',
            false: 'Ei',
        },
        page: {
            list: '%{name} Lista',
            edit: '%{name} #%{id}',
            show: '%{name} #%{id}',
            create: 'Luo %{name}',
            dashboard: 'Hallintapaneeli',
            not_found: 'Ei löytynyt',
            loading: 'Lataa',
            error: 'Jotain meni pieleen',
            empty:'Ei vielä kohteita',
            invite:'Odota rauhassa',
        },
        input: {
            file: {
                upload_several:
                    'Vedä ja pudota tiedostoja ladataksesi ne tai napsauta valitaksesi useita tiedostoja.',
                upload_single: 'Vedä ja pudota tiedosto ladataksesi se tai napsauta valitaksesi se.',
            },
            image: {
                upload_several:
                    'Vedä ja pudota kuvia ladataksesi ne tai napsauta valitaksesi useita kuvia.',
                upload_single:
                    'Vedä ja pudota kuva ladataksesi se tai napsauta valitaksesi se.',
            },
        },
        message: {
            yes: 'Kyllä',
            no: 'Ei',
            are_you_sure: 'Oletko varma?',
            about: 'Tietoja',
            not_found:
                'Joko kirjoitit väärän URL:n tai seurasit väärää linkkiä.',
            invalid_form:'Täytä kaikki pakolliset kentät',
            error:'Yritä uudelleen'
        },
        navigation: {
            no_results: 'Tuloksia ei löytynyt',
            no_more_results:
                'Sivunumero %{page} on ulkopuolella. Kokeile edellistä sivua.',
            page_out_of_boundaries: 'Sivunumero %{page} on ulkopuolella',
            page_out_from_end: 'Viimeisen sivun jälkeen ei voi siirtyä',
            page_out_from_begin: 'Sivunumero ei voi olla ennen 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} / %{total}',
            page_rows_per_page: 'Riviä per sivu:',
            next: 'Seuraava',
            prev: 'Edellinen',
        },
        auth: {
            username: 'Käyttäjänimi',
            password: 'Salasana',
            sign_in: 'Kirjaudu sisään',
            sign_in_error: 'Kirjautuminen epäonnistui, yritä uudelleen',
            logout: 'Kirjaudu ulos',
            auth_check_error:'Et ole kirjautunut sisään'
        },
        notification: {
            updated: 'Kohde päivitettiin',
            created: 'Kohde luotiin',
            deleted: 'Kohde poistettiin',
            item_doesnt_exist: 'Kohdetta ei ole olemassa',
            http_error: 'Palvelinvirhe',
            data_provider_error: 'dataProvider virhe. Katso konsoli lisätietoja varten.',
            canceled: 'Toiminto peruutettiin',
            logged_out: 'Istuntosi on päättynyt, yhdistä uudelleen',
        },
        validation: {
            required: 'Pakollinen',
            minLength: 'Tulee olla vähintään %{min} merkkiä',
            maxLength: 'Tulee olla enintään %{max} merkkiä',
            minValue: 'Tulee olla vähintään %{min}',
            maxValue: 'Tulee olla enintään %{max}',
            number: 'Tulee olla numero',
            email: 'Tulee olla kelvollinen sähköposti',
            oneOf: 'Tulee olla yksi seuraavista: %{options}',
            regex: 'Tulee vastata erityistä muotoa (regexp): %{pattern}',
        },

        configurable: {
            customize:'Muokkaa',
        },
    },


};
