import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, makeStyles, } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; // Varmista, että tämä on asennettu
import axios from 'axios';
import { Link } from 'react-router-dom';

// ... useStyles ja muut määritykset
const useStyles = makeStyles({
    root: {
      backgroundImage: 'url("https://www.salaovi.fi/wp-content/uploads/2020/10/1900x600_salaovi-hero-image_5.jpg")',
      backgroundSize: 'cover', // Kuvan koko
      backgroundPosition: 'center', // Kuvan sijainti
      backgroundColor: '#399ecc', // Sininen tausta
      height: '100vh', // Täysi korkeus
      display: 'flex', // Käytetään flexboxia
      justifyContent: 'center', // Keskitys vaakasuunnassa
      alignItems: 'center' // Keskitys pystysuunnassa
    },
    paper: {
      maxWidth: '400px',
      textAlign: 'center',
      padding: '2em'
    },
    input: {
      marginBottom: '2em',
      width: '100%',
      height: '20px'
    },
    button: {
      width: '100%'
    },
    imageContainer: {
      position: 'relative',
  
    },
    image: {
      top: 0,
      left: 0,
      width: '300px',
    },
    link: {
        textDecoration: 'none', // Poistaa alleviivauksen

      },
   
  });
  
  const getErrorMessage = (errorResponse) => {
    if (errorResponse.type === "INCORRECT_EMAIL") {
      return "Annettu sähköpostiosoite on virheellinen.";
    }
    // Voit lisätä muita virhetarkistuksia tässä
    return "Tuntematon virhe. Ole hyvä ja yritä uudelleen.";
  };

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const [emailId, setEmailId] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const response = await axios.post('https://api.salaovi.fi/User/forgotPassword', { emailId });
      if (response.status === 200) {
        setMessageType('success');
        setMessage('Salasanan nollauslinkki on lähetetty sähköpostiisi.');
      } else {
        setMessageType('warning');
        setMessage('Jotain meni pieleen. Yritä uudelleen.');
      }
    } catch (error) {
      setMessageType('error');
      if (error.response && error.response.data) {
        setMessage(getErrorMessage(error.response.data));
      } else {
        setMessage('Virhe lähettäessä salasanan nollauslinkkiä. Tarkista verkkoyhteys.');
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.imageContainer}>
          <img src="https://www.salaovi.fi/wp-content/uploads/2020/08/salaovi.png" alt="Salaovi Logo" className={classes.image} />
        </div>
        <Typography variant="h5">Salaovi Partners<br></br></Typography>
        <Typography variant="body1">Lähetä salasanan nollauspyyntö<p></p></Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            className={classes.input}
            label="Sähköposti"
            name="emailId"
            type="email"
            value={emailId}
            onChange={e => setEmailId(e.target.value)}
            required
          />
          <Button className={classes.button} type="submit" color="primary" variant="contained">
            Lähetä 
          </Button>
          {message && <Alert severity={messageType}>{message}</Alert>}
          <Typography  variant="body1"><br></br>
  Eikö sinulla ole tiliä? <Link to="/register"style={{ textDecoration: 'none' }}>Rekisteröidy</Link>  <br></br>tai <Link to="/login"style={{ textDecoration: 'none' }}>Kirjaudu sisään</Link>
</Typography>
        </form>
      </Paper>
    </div>
  );
};

export default ForgotPasswordPage;
