// in src/MyLayout.js
import * as React from 'react';
import { forwardRef } from 'react';
import { Layout, UserMenu, useLogout, MenuItemLink } from 'react-admin';
import { MenuItem } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import AppBar from './AppBar'; 
import SettingsIcon from '@material-ui/icons/Settings'; // ikoni profiilille
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'; // ikoni lompakolle

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});

const MyUserMenu = () => (
    <UserMenu>
        <MyLogoutButton />
        <MenuItemLink
      to="/profiili"
      primaryText="Muokkaa profiilia"
      leftIcon={<SettingsIcon />}
    />
    <MenuItemLink
      to="/maksutavat"
      primaryText="Lompakko"
      leftIcon={<AccountBalanceWalletIcon />}
    />
    </UserMenu>
);

const MyAppBar = () => <AppBar userMenu={<UserMenu />} />;

const MyLayout = (props) => (
    <Layout {...props} appBar={MyAppBar} />
);

export default MyLayout;