import { fetchUtils } from 'ra-core';



const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}
const apiUrl = 'https://api.salaovi.fi/';


export const apiEndpoints = {
  allproposals:'Vendor/home',
  openenquiry:'Vendor/home',
  agentprofile:'Vendor/accessTokenLogin',
  getuser:'/User/getUserData',
  updateuser:'User/editProfile',
  transactions:'User/transactionHistory',
  allListing: 'Vendor/allListing',
  createListing: '/Vendor/createListing',
  allagencyListing:'/Agency/allListing',
  agencyagents:'User/listAgentsWithNoAgency',
  agencyprofile:'/User/updateAgencyProfile'
};

export const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  
  // Hae käyttäjäprofiili local storagesta tai palvelimelta
  getUserProfile(params) {
    const storedProfile = localStorage.getItem("profile");

    if (storedProfile) {
      return Promise.resolve({
        data: JSON.parse(storedProfile),
      });
    }

    // Ei profiilia vielä, haetaan se palvelimelta
    const url = `${apiUrl}Vendor/accessTokenLogin`;

    return httpClient(url, { method: 'POST' })
      .then(({ headers, json }) => {

        
        const { _id: id, fullName, profilePicture: avatar, type } = json.data.userData;

        // Tallennetaan profiili localStorageen tulevaisuutta varten
        localStorage.setItem("profile", JSON.stringify({ id, fullName, avatar, type }));

        return { data: { id, fullName, avatar, type } };
      })
      .catch(error => {
        console.error("Virhe haettaessa profiilia palvelimelta:", error);
        return Promise.reject(new Error('Käyttäjäprofiilia ei löydy.'));
      });
  },

  updateUserProfile(params) {
    const { _id: id, fullName, profilePicture: avatar, type } = params.data.userData;
    
    localStorage.setItem("profile", JSON.stringify({ id, fullName, avatar, type }));
    return Promise.resolve({ data: { id, fullName, avatar, type } });
  },
  


  

});

const myDataProvider = {

  getList: (resource, params) => {
    let url = `${apiUrl}${apiEndpoints[resource] || resource}`;
    let options = {};
  
    if (resource === "transactions") {
      options.method = 'GET';
      options.headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    } else if (resource === "allListing") {
      options.method = 'POST';
      options.body = new URLSearchParams({ status: 'CLIENT' }).toString();
      options.headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    } else if (resource === "allagencyListing") {
      options.method = 'POST';
      options.body = new URLSearchParams({ status: 'CLIENT' }).toString();
      options.headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    } else if (resource === "agencyagents") {
      // Ensimmäinen datalähde
      const url1 = 'https://api.salaovi.fi/User/listAgentsWithNoAgency';
      const options1 = {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json'})
      };
  
      // Toinen datalähde
      const url2 = 'https://api.salaovi.fi/User/getAgencyDetails';
      const options2 = {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json'})
      };
  
      return Promise.all([
        httpClient(url1, options1),
        httpClient(url2, options2)
      ]).then(([response1, response2]) => {
        let data1 = [];
        let data2 = [];
      
        if (response1.json.data && Array.isArray(response1.json.data)) {
          data1 = response1.json.data.map(item => ({ ...item, id: item._id }));
        }
      
        if (response2.json.data.agents && Array.isArray(response2.json.data.agents)) {
          data2 = response2.json.data.agents.map(agent => ({
            _id: agent.agentId._id,
            profilePicture: agent.agentId.profilePicture,
            fullName: agent.agentId.fullName,
            jobTitle: agent.agentId.jobTitle,
            selectionCount: agent.selectionCount,
            companyImage: agent.agentId.companyImage,
            companyName: agent.agentId.companyName,
            createdAt: agent.agentId.createdAt,
            email: agent.agentId.email,
            vacationMode: agent.agentId.vacationMode,
            contactNumber: agent.agentId.contactNumber,
            id: agent.agentId._id // Tärkeää lisätä id, jos _id käytössä
          }));
        }
      
        const combinedData = [...data1, ...data2];
      
        // Varmistetaan, että kaikilla kohteilla on 'id'-avain
        const filteredData = combinedData.filter(item => item.id !== undefined);
      

      
        return { data: filteredData, total: filteredData.length };
      });
      
    } else if (resource === "allproposals" || resource === "openenquiry" || resource === "allagencyenquiry") {
      // Käytetään välityspalvelinta näille resursseille
      url = `https://partners.salaovi.fi/api/${resource}`;
    options.method = 'GET';
    options.headers = new Headers({
      'Content-Type': 'application/json'
    });
    
    
  }
  return httpClient(url, options).then(({ headers, json }) => {
    let data = [];
    let total = 0;

    if (resource === "transactions") {
      if (json.data && Object.keys(json.data).length === 0) {
        data = [];
      } else if (json.data && Array.isArray(json.data)) {
        data = json.data;
      }
      total = data.length;
    } else {
      data = json.data || [];
      total = data.length;
      
    }

   
      data = data.map(entry => ({ ...entry, id: entry._id }));
    

    return { data, total };
  });
},
  
  


get: (resource, params) => {
  let url;
  if (resource === "profiili") {
    url = `${apiUrl}${apiEndpoints.getuser}`; // Tässä oletetaan, että käyttäjäprofiililla on yksilöllinen id parametrissa
  } else {
    // Tässä käsitellään muita resursseja
    url = `${apiUrl}${apiEndpoints[resource]}`;
  }
  return httpClient(url).then(({ json }) => {
    return {
      data: json,
    };
  });
},

update: (resource, params) => {
  let url;
  if (resource === "profiili") {
    url = `${apiUrl}${apiEndpoints.updateuser}`; // Käyttäen updateuser endpointtia
  } else {
    // Tässä käsitellään muita resursseja
    url = `${apiUrl}${apiEndpoints[resource]}`;
  }
    // Lokita lähetettävä pyyntö
    console.log(`Lähetetään päivityspyyntö resurssille ${resource} osoitteeseen ${url} seuraavilla parametreilla:`, params);

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(params.data),
  }).then(({ json }) => {
    console.log(`Saatu vastaus päivityspyyntöön resurssille ${resource}:`, json);
    return {
      data: { ...params.data, id: json.id },
    };
  }).catch(error => {
    // Lokita mahdollinen virhe
    console.error(`Virhe päivittäessä resurssia ${resource}:`, error);
    throw error; // Jotta virhe ei jää huomiotta
  });
},


  
create: (resource, params) => {
  let url = `${apiUrl}${apiEndpoints[resource] || '/' + resource}`;
  let options = {};

  // Tarkistetaan, onko kyseessä 'listing' resurssi
  if (resource === 'allproposals') {
    // Erityinen käsittely 'listing'-resurssille
    const url = `${apiUrl}/Vendor/createListing`;
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Oletetaan, että token on tallennettu local storageen nimellä 'token'
      }),
    };
    return httpClient(url, options)
      .then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
  } else {
    // Normaali käsittely muille resursseille
    const body = {
      // oletetaan että params.data sisältää tarvittavat tiedot
      ...params.data
    };
    options = {
      method: 'POST',
      body: JSON.stringify({
        enquiryId: params.data.enquiryId,
        userId: params.data.userId,
        // Lisätään muu data
        ...params.data,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Oletetaan, että token on tallennettu local storageen
      }),
    };
  }

  return httpClient(url, options).then(({ json }) => ({
    data: { ...params.data, id: json.id }, // Oletetaan, että API vastaa uuden luodun objektin ID:llä
  }));
},


  
  generalUpdate: (resource, params) => {
    // Etsi oikea API-endpoint tai käytä oletusta
    const updateUrl = `${apiUrl}${apiEndpoints[resource] || resource}`;
  
    return httpClient(updateUrl, {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(params.data),
    })
    .then(response => response.json())
    .then(jsonResponse => {
      if (jsonResponse.statusCode !== 200) {
        console.error("Unexpected response format or empty response");
        return { data: {} };
      }
  
      const Data = { 
        ...params.data, 
        id: params.id 
      };
      return { data: Data };
    })
    .catch(error => {
      console.error("Virhe pyynnössä:", error);
    });
  },

  // Handle authentication

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    return Promise.resolve();
  },

  // Check if the user is authenticated
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },

  // Handle errors
  checkError: (error) => {
    // Handle error logic here
    return Promise.reject(error);
  },

  // Get user permissions
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
export default {
  ...myDataProvider,
  ...addUserProfileOverrides(myDataProvider)
};