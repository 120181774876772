import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Button, TextField, Paper, Typography, makeStyles } from '@material-ui/core';
import { Link, useNavigate} from 'react-router-dom';



// Päivitetään tyylit
const useStyles = makeStyles({
  root: {
    backgroundImage: 'url("https://www.salaovi.fi/wp-content/uploads/2020/10/1900x600_salaovi-hero-image_5.jpg")',
    backgroundSize: 'cover', // Kuvan koko
    backgroundPosition: 'center', // Kuvan sijainti
    backgroundColor: '#399ecc', // Sininen tausta
    height: '100vh', // Täysi korkeus
    display: 'flex', // Käytetään flexboxia
    justifyContent: 'center', // Keskitys vaakasuunnassa
    alignItems: 'center' // Keskitys pystysuunnassa
  },
  paper: {
    maxWidth: '400px',
    textAlign: 'center',
    padding: '2em'
  },
  input: {
    marginBottom: '2em',
    width: '100%',
    height: '20px'
  },
  button: {
    width: '100%'
  },
  imageContainer: {
    position: 'relative',

  },
  image: {
    top: 0,
    left: 0,
    width: '300px',
  },
  link: {
    textDecoration: 'none', // Poistaa alleviivauksen

  },
 
});


const MyLoginPage = ({ theme }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const navigate = useNavigate();
    
    const handleSubmit = e => {
      e.preventDefault();
      login({ email, password })
        .then(() => {
          // Tässä kohtaa oletetaan, että 'permissions' on tallennettu localStorageen
          const role = localStorage.getItem('permissions');
          if (role === 'AGENCY') {
            navigate('/allagencyenquiry');
          } else {
            navigate('/openenquiry');
          }
        })
        .catch(() => {
          notify('Väärä käyttäjä tai salasana');
        });
  };


    return (
        <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.imageContainer}>
        <img src="https://www.salaovi.fi/wp-content/uploads/2020/08/salaovi.png" alt="Kirjautumissivu Header" className={classes.image} />  {/* Lisätty kuva */}
        </div>
                <Typography variant="h5">Salaovi Partners</Typography>
                <Typography variant="p">Kirjaudu sisään</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        className={classes.input}
                        label="Sähköposti"
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Salasana"
                        name="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button className={classes.button} type="submit" color="primary" variant="contained">
                        Kirjaudu sisään
                    </Button>
                    <Typography variant="body1"><br></br>
  Eikö sinulla ole tiliä? <Link to="/register"style={{ textDecoration: 'none' }}>Rekisteröidy</Link>  <br></br><Link to="/passwordreset"style={{ textDecoration: 'none' }}>Salasana hukassa?</Link>
</Typography>
                </form>
            </Paper>
        </div>
    );
};

export default MyLoginPage;

