import * as React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField, Button, SimpleList } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PopupReceipt from './PopupReceiptComponent';
import { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Typography } from "@mui/material";


// ALV:n laskeminen 24% oletuksella
const calculateVAT = (amount) => {
  const VAT_PERCENTAGE = 0.24; // 24%
  const preTaxAmount = amount / (1 + VAT_PERCENTAGE); // Lasketaan veroton summa
  const vatAmount = amount - preTaxAmount; // Lasketaan arvonlisäveron määrä
  return vatAmount.toFixed(2); // Pyöristetään kahteen desimaaliin
};




  export const Payments = (props) => {
    const [cards, setCards] = useState([]); // Alustetaan kortit tilaan

    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();
  


    const [open, setOpen] = React.useState(false); // Dialogin tila
    const [selectedRecord, setSelectedRecord] = React.useState({}); // Valitun tietueen tila
  
    // Avaa dialogin ja asettaa valitun tietueen
    const handleClickOpen = (record) => {
      setSelectedRecord(record);
      setOpen(true);
    };
  
    // Sulkee dialogin
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
    <List {...props}  title="Laskutus">
      
            {isMobile ? (
                <>
                <Typography variant="h6" gutterBottom>
                         Laskutus
                       </Typography>

        <SimpleList
          primaryText={record => `${record.amount} €`}
          secondaryText={record => record.customerId.fullName}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
          linkType={"show"}
        />
        </>
      ) : (
        <Datagrid>
          <FunctionField
label="Tarkoitus"
  render={record => (
    <span>
      {record.purpose === 'reversal' ? 'Hyvitys' : record.purpose === 'deposit' ? 'Deposit' : record.purpose === 'transfer' ? 'Veloitus' : ''}
    </span>
  )}
/>
      <FunctionField
    source="amount"
    label="Summa"
    render={record => (
        <span>
            {record.purpose === "deposit" || record.purpose === "reversal" ? '+' : '-'}
            {record.amount} €
        </span>
    )}
/>


        <FunctionField
  source="amount"
  label="ALV 24%"
  render={record => `${calculateVAT(record.amount)} €`}
/>

<FunctionField
    source="balance_after"
    label="Saldo"
    render={record => (
        <span>
            {record.balance_after} €
        </span>
    )}
/>

<FunctionField


  label="Asiakas"
  render={record => record.customerId && record.customerId.fullName ? record.customerId.fullName : 'Salaovi tiimi'}
/>
            <FunctionField label="Myyjä" render={() => 'Salaovi Oy'}/>
            <TextField source="reference" label="Viite" />
            <DateField source="createdAt" label="Päivämäärä" />
            <FunctionField
    label="Kuitti"
    render={record => (
      record.purpose === 'reversal' || record.purpose === 'deposit' ? (
        "Ei saatavilla"
      ) : (
        record.customerId && record.customerId.fullName ? (
          <Button variant="contained" color="primary" onClick={() => handleClickOpen(record)}>
            Näytä Kuitti
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={() => handleClickOpen(record)}>
            Näytä Kuitti
          </Button>
        )
      )
    )}
  />


        </Datagrid>
          )}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Kuitti</DialogTitle>
        <DialogContent>

<PopupReceipt  selectedRecord={selectedRecord}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
      
    </List>
  );

            }


export default Payments