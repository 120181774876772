import React from 'react';
import { useNotify } from 'react-admin';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddCardFormReg = ({ apiUrl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotify();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js ei ole vielä latautunut.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      notify(`Virhe: ${error.message}`, 'warning');
    } else {
      // Lähetä token ja muut tiedot palvelimelle
      const authorizationToken = window.localStorage.getItem('token');
      const cardData = {
        sourceId: token.id, // Oletetaan, että Stripe token.id sisältää sourceId:n
        country: 'Finland',
        exp_month: token.card.exp_month.toString(),
        exp_year: token.card.exp_year.toString(),
        last4: token.card.last4,
        type: token.card.brand.toLowerCase()
      };

      axios.post(`${apiUrl}/User/addCard`, cardData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'authorization': authorizationToken
        }
      })
      .then(response => {
        notify('Kortti lisätty onnistuneesti.', 'info');
       navigate('/'); // Korvaa '/uusi-osoite' haluamallasi polulla
      })
      .catch(error => {
        notify(`Virhe kortin lisäämisessä: ${error.response.data.message}`, 'error');
      });
    }
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <CardElement />
    </form>
     <button onClick={handleSubmit}  disabled={!stripe}>Lisää kortti</button>
     </>
  );
};

export default AddCardFormReg;
