import React from 'react';
import { Admin, Resource, EditGuesser, CustomRoutes} from 'react-admin';
import MyLoginPage from './MyLoginPage'; // Varmista, että tämä polku on oikea
import myDataProvider from './myDataProvider';
import authProvider from './authProvider';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/People';
import MyLayout from './MyLayout';
import { EnquiryList } from './EnquiryList';
import Dashboard from './Dashboard'; 
import MyMenu from './MyMenu'; 
import { ContactEdit} from './contactEdit';
import { TermsEdit } from './termsEdit';
import {HelpEdit} from './HelpEdit'
import OpenEnquiry from './OpenEnquiry';
import UserEdit from './EditUser';
import Payments from './Payments';
import AddCardPage from './AddCardPage';
import DefaultProposal from './DefaultProposal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RegistrationPage from './RegistrationPage';
import i18nProvider from './i18nProvider';
import { AllListing } from './AllListings';
import ListingCreate from './createListing';
import ForgotPasswordPage from './passwordreset';
import AgencyEnquiryList from './AgencyEnquiryList';
import { AllAgencyListing } from './AllAgencyListing';
import { AgencyAgents } from './AgencyAgents';
import CreateLinkPage from './CreateLinkPage';
import CompleteRegistration from './CompleteRegistration';
import myTheme from './theme';


const App = () => (


  
  
    <BrowserRouter>
      <Routes>
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/passwordreset" element={<ForgotPasswordPage />} />
      <Route path="/key-account" element={<CreateLinkPage />} />
      <Route path="/complete" element={<CompleteRegistration />}/>
      <Route path="*" element={

<Admin 
i18nProvider={i18nProvider}
      layout={MyLayout}
      title="Salaovi" 
      loginPage={MyLoginPage} 
      dataProvider={myDataProvider} 
      authProvider={authProvider}
      menu={MyMenu}
      theme={myTheme}
    >
<Resource
    name="openenquiry"
    options={{ label: 'Vertailut' }}
    list={OpenEnquiry}
    icon={PostIcon}
    
  />


        <CustomRoutes>
<Route path="/termsedit" element={<TermsEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />
<Route path="/tuki" element={<HelpEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />
<Route path="/opas" element={<ContactEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />
<Route path="/profiili/*" element={<UserEdit />} options={{ label: 'Muokkaa Profiilia' }} exact />
<Route path="/agencyprofile/*" element={<UserEdit />} options={{ label: 'Muokkaa Profiilia' }} exact />
<Route path="/maksutavat/*" element={<AddCardPage />} options={{ label: 'Muokkaa Profiilia' }} exact />
<Route path="/vakiotarjous/" element={<DefaultProposal />} options={{ label: 'Muokkaa Profiilia' }} exact />
        </CustomRoutes>

        
   
        <Resource name="allListing"  options={{ label: 'Kaikkikohteet' }} list={AllListing} />
        <Resource name="allagencyListing"  options={{ label: 'Kaikkikohteet' }} list={AllAgencyListing} />
        <Resource name="agencyagents"  options={{ label: 'agencyagents' }} list={AgencyAgents} />

<Resource name="transactions" list={Payments} />

  <Resource
    name="allproposals"
    options={{ label: 'Vertailut' }}
    create={ListingCreate}
    list={EnquiryList}
    icon={PostIcon}
    
  />

<Resource
    name="allagencyenquiry"
    options={{ label: 'Vertailut' }}
    create={ListingCreate}
    list={AgencyEnquiryList}
    icon={PostIcon}
    
  />



  </Admin>

} />
      </Routes>
    </BrowserRouter>
  );




export default App;