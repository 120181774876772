import React, { useState, useEffect, useRef } from 'react';
import { useNotify } from 'react-admin';
import { Button, TextField, Paper, Typography, Grid, makeStyles, FormControlLabel, Checkbox, Tabs, Tab, Box, MenuItem } from '@material-ui/core';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";
import Slider from "@mui/material/Slider";
import './register.css'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AddCardFormReg from './AddCardFormReg';
import './AddCardPage.css';
import DefaultCompleteProposal from './DefaultCompleteProposal';
import { Link } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import authProvider from './authProvider';
import Alert from '@material-ui/lab/Alert'; 
import AlertTitle from '@material-ui/lab/AlertTitle'; 
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { RegisterMailResponse } from './components/RegisterMail';


const stripePromise = loadStripe('pk_live_igV0AcZqYArf74RJ6nxicPbc00X214raKO');

const currentYear = new Date().getFullYear();
const earliestYear = currentYear - 50; // Oletetaan, että 50 vuotta on maksimiaika takautuvasti
const years = Array.from(new Array(51), (val, index) => currentYear - index);


// Päivitetään tyylit rekisteröintisivulle
const useStyles = makeStyles({
  root: {
    backgroundImage: 'url("https://www.salaovi.fi/wp-content/uploads/2020/10/1900x600_salaovi-hero-image_5.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundColor: '#399ecc',
    minHeight: '100vh', // muutetaan tämä 'height' sijasta 'min-height'
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    maxWidth: '800px',
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
    padding: '2em',

    
  },
  textField: {
    marginBottom: '1em',
    width: '100%',
  },
  input: {
    marginBottom: '1em',
    width: '100%',
  },
  InputAdornment: {
    marginBottom: '1em',
    width: '100%',
  },
  button: {
    width: '100%',
    marginTop: '1em',
  },
  title: {
    marginBottom: '2em',
  },
  imageContainer: {
    position: 'relative',
    marginBottom: '2em',
  },
  image: {
    width: '200px',
    margin: 'auto',
  },
  input1: {
    display: 'none',
  },
  label1: {
    fontSize: '1rem',
    fontWeight:'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'inline-block',
    overflow: 'hidden',
    padding: '15px',
    color: '#77C3EC',
    border: '2px solid #add8e6',
    marginTop: '10px',
    textAlign: 'center',
    fontFamily:'"Helvetica"',
    width:'80%'
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginTop: '10px',
  },
 
  uploadingText: {
    marginTop: '10px',
  },
 ehdot: {
    fontFamily: 'Arial, sans-serif', // Vaihda tarvittaessa
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#000000', // Musta teksti
    fontWeight: 'normal',
    textAlign:'left',
    '& h4': { // Tämä määrittelee tyylit kaikille <h4>-elementeille sisällä ehdot-luokassa
      fontWeight: 'bold',
      fontSize: '14px',
      marginTop: '20px', // Ylämarginaali
      marginBottom: '10px', // Alamarginaali
    },
    '& h3': { // Tämä määrittelee tyylit kaikille <h4>-elementeille sisällä ehdot-luokassa
      fontWeight: 'bold',
      fontSize: '14px',
      marginTop: '20px', // Ylämarginaali
      marginBottom: '10px', // Alamarginaali
    }
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // tai määritä tarkka korkeus, jos tarpeen
  },
  sigPad: {
    border: '2px solid #000',
    borderRadius: '5px',
    width: '100%;' ,
    height: '100px;', 
  },
  scrollableArea: {
    maxHeight: '400px', // Määritä haluamasi korkeus
    overflowY: 'auto'  // Sallii pystysuuntaisen vierityksen
  },
  typographyStyle: {
    paddingBottom:  '20px', // Esimerkki paddingista
    marginTop: '-20px', // Esimerkki elementin nostamisesta ylöspäin
    fontSize:'15px',
    lineHeight:'17px'
},
  
});






const renderPhoneField = (name, label, value, onChange, fullWidth) => {
  return (
    <TextField
    style={{ width: '100%' }}
    fullWidth={fullWidth}
      id={name}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">+358</InputAdornment>
        ),
      }}
      // Tässä voit lisätä muita props, kuten fullWidth, variant jne.
    />
  );
};


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  };


const CompleteRegistration= () => {
  const [alertState, setAlertState] = useState({ open: false, message: '', severity: '' });
  const classes = useStyles();
    const defaultProfileImage = 'https://www.salaovi.fi/wp-content/uploads/2021/04/dsadsdff.jpg'; // Oletusprofiilikuvan URL
    const defaultCompanyImage = 'https://www.salaovi.fi/wp-content/uploads/2021/04/LOGODUM.jpg'; // Oletusyrityskuvan URL
    const [isValidToken, setIsValidToken] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [signatureData, setSignatureData] = useState('');
    const [registrationType, setRegistrationType] = useState(null); // Tallentaa valitun rekisteröintityypin

    const [formData, setFormData] = useState({
      
      
    
        fullName: '',
        email: '',
        countryCode: '+358',
        contactNumber: '',
        vat: '',
        password: '',
        facebookId: '',
        companyName: '',
        jobTitle: '',
        lat: '',
        lng: '',
        address: '',
        city: '',
        zipCode: '',
        startWorking: '', // Oletetaan, että tämä on numeerinen arvo
        legalRealtor: false, // Boolean-arvo
        minDistance: "10000", // Numeerinen arvo
        maxDistance: 55000, // Numeerinen arvo
        profilePicture: '',
        deviceType: 'null',
        type: 'AGENT',
        deviceToken: 'null',
        companyImage: '',
        about: 'kirjoita esittely',
        app_version: 3, // Numeerinen arvo
        agency: '6080001e12ac3e0df4a35619',
        compete_with_agency: false, // Boolean-arvo
        profile_link: 'null',
        offer:''
      });

      useEffect(() => {

      }, [formData]);

      const showAlert = (message, severity) => {
        setAlertState({ open: true, message, severity });
    };
    
    const closeAlert = () => {
        setAlertState({ ...alertState, open: false });
    };



    const handleSkip = () => {
      // Tässä vaiheessa ohjataan käyttäjä etusivulle
      window.location.href = '/';
    };
    
      const handleTypeSelect = (type) => {
        setRegistrationType(type);
        setFormData(prevFormData => ({
          ...prevFormData,
          type: type,
          startWorking: type === 'AGENCY' ? 0 : prevFormData.startWorking,
          about: type === 'AGENCY' ? null : prevFormData.about
        }));
      };
 // Luo vuosien lista vuodesta 1970 nykyhetkeen
 const currentYear = new Date().getFullYear();
 const years = Array.from(new Array(currentYear - 1969), (val, index) => 1970 + index);
      const [autocomplete, setAutocomplete] = useState(null);
  
      const onLoad = (autocomp) => setAutocomplete(autocomp);
    
      const onPlaceChanged = () => {
        if (autocomplete !== null) {
          const place = autocomplete.getPlace();
          
          if (!place.geometry) {
            // Käyttäjä syötti paikan nimen, mutta valitsi ei mitään pudotusvalikosta
            return;
          }
     // Etsi tarvittavat komponentit place.address_components-objektista
     const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || '';
     const route = place.address_components.find(component => component.types.includes('route'))?.long_name || '';
     const locality = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';
 
     // Yhdistä komponentit muodostaaksesi täydellisen osoitteen
     const address = `${route} ${streetNumber}, ${locality}`.trim();

          // Päivitä kentät vastaamaan valittua paikkaa
          setFormData(prevFormData => ({
            ...prevFormData,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
           address: address, // Käytä yhdistettyä osoitetta
            city: place.address_components.find(component => component.types.includes('locality'))?.long_name || '',
            zipCode: place.address_components.find(component => component.types.includes('postal_code'))?.short_name || '',
          }));
        } else {

        }
      };
      
      


      const handleTabChange = (event, newValue) => {
        // Tässä tarkistetaan, voiko siirtyä uudelle välilehdelle
        if (newValue === tabValue - 1) {
          // Sallitaan siirtyminen seuraavalle välilehdelle
          setTabValue(newValue);
        }
        // Voit lisätä muut ehdot tähän tarpeen mukaan
      };

      const handleAgencyChange = (event) => {
        // Päivittää tilan uudella valitulla arvolla
        setFormData({
          ...formData,
          agency: event.target.value
        });
      };

      const handleFormChange = (name, value) => {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      };
      const handleBooleanChange = (event) => {
        const { name, checked } = event.target;
        setFormData({
          ...formData,
          [name]: checked
        });
      };
      const handleDistanceChange = (event, newValue) => {
        const newMaxDistance = String(Math.round(newValue * 1000));

        setFormData({ ...formData, maxDistance: newMaxDistance });
    };

   

    // Funktio, joka päivittää formDataa ja tarkistaa salasanan vahvuuden
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
  
      // Tarkista salasanan vahvuus, jos syötetty kenttä on 'password'
      if (name === 'password') {
        checkPasswordStrength(value);
      }
    };
  
    // Tämä funktio tarkistaa salasanan vahvuuden ja asettaa passwordStrength-tilan
    const checkPasswordStrength = (password) => {
      // Voit korvata tämän omalla logiikallasi salasanan vahvuuden tarkistamiseksi
      if (password.length < 8) {
        setPasswordStrength('Salasana on liian lyhyt');
      } else {
        setPasswordStrength('Salasana on tarpeeksi vahva');
      }
    };

    const renderPassField = (name, label, autoComplete, isPassword, handleChange, passwordStrength) => (
      <TextField
        type={isPassword ? "password" : "text"}
        label={label}
        name={name}
        value={name === 'password' ? formData.password : formData.confirmPassword}
        autoComplete={autoComplete}
        onChange={handleChange}
        helperText={name === 'password' ? passwordStrength : ''}
        fullWidth
        required
        margin="normal"
      />
    );
  
  const handleContactNumberChange = (event) => {
    const { value } = event.target; // Hae arvo suoraan event-objektista
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactNumber: value // Päivitä contactNumber uudella arvolla
    }));
  };



  const [alert, setAlert] = useState({ show: false, message: '', severity: '' });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const fullHtmlContent = RegisterMailResponse();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const notify = useNotify();


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Array.isArray(formData.legalRealtor)) {
        formData.legalRealtor = [formData.legalRealtor];
    }

    // Destructure formData to separate confirm fields and clean data without accessToken
    const { confirmEmail, confirmPassword, accessToken, facebookId, signature, offer, _id, emailMessage, __v, vat, tosAccepted, deviceType, type, deviceToken, app_version, token, ...cleanData } = formData;



    if (!formData.profilePicture) {
      setAlert({ show: true, message: 'Profiilikuva on pakollinen.', severity: 'error' });
      return;
    }
    if (!formData.companyImage) {
      setAlert({ show: true, message: 'Yrityksen kuva on pakollinen.', severity: 'error' });
      return;
    }
    if (!formData.signature) {
      const signatureSaved = await saveSignature();
      if (!signatureSaved) {
        setAlert({ show: true, message: 'Allekirjoitus vaaditaan.', severity: 'error' });
        return;
      }
    }


    // Add other validation checks as necessary

    // If everything is fine, reset the alert and proceed with form submission logic
    setAlert({ show: false, message: '', severity: '' });

    // Convert boolean values to strings
    cleanData.lat = String(cleanData.lat);
    cleanData.lng = String(cleanData.lng);
    cleanData.email = String(cleanData.email);

    // Create form body
    const formBody = Object.keys(cleanData).map(
        key => encodeURIComponent(key) + '=' + encodeURIComponent(cleanData[key])
    ).join('&');

   const bearerToken = localStorage.getItem('token');

   if (! bearerToken) {
    setAlert({ show: true, message: 'Tokenia ei löydy. Kirjaudu sisään uudelleen.', severity: 'error' });
    return;
  }
    try {
        const response = await axios.post('https://api.salaovi.fi/User/editProfile', cleanData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${ bearerToken}`
            }
          });
  
      if (response.status === 200) {
            setAlert({ show: true, message: 'Profiilin päivitys onnistui.', severity: 'success' });
            setTabValue((prevValue) => prevValue + 1);
        } else {
            setAlert({ show: true, message: 'Profiilin päivitys epäonnistui.', severity: 'error' });
        }
    } catch (error) {
        console.error('Error:', error);
        setAlert({ show: true, message: 'Profiilin päivitys epäonnistui. Yritä uudelleen.', severity: 'error' });
    }
};
 

useEffect(() => {
  if (alert.show) {
    // Jos haluat esimerkiksi piilottaa hälytyksen tietyssä ajassa:
    const timer = setTimeout(() => {
      setAlert({ show: false, message: '', severity: '' });
    }, 5000); // Piilottaa hälytyksen 5 sekunnin kuluttua

    // Muista aina siivota ajastimet!
    return () => clearTimeout(timer);
  }
}, [alert.show]);




    // Funktio, joka siirtää käyttäjän seuraavaan välilehteen
    const handleNext = () => {
        setTabValue((prevValue) => prevValue + 1);
      };
    
      // Funktio, joka siirtää käyttäjän edelliseen välilehteen
      const handleBack = () => {
        setTabValue((prevValue) => prevValue - 1);
      };

      const [filesData, setFilesData] = useState({
        profilePicture: { preview: '', isUploading: false },
        companyImage: { preview: '', isUploading: false },
      });
    
      // General function to upload a file
      const uploadFile = async (name, file) => {
        setFilesData(prev => ({ ...prev, [name]: { ...prev[name], isUploading: true } }));
    
        const uploadFormData = new FormData();
        uploadFormData.append('pic', file);
    
        try {
          const response = await axios({
            method: 'post',
            url: `https://api.salaovi.fi/User/uploadAttachment`,
            data: uploadFormData,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (response.status === 200 && response.data.message === "Success") {
            setFilesData(prev => ({
              ...prev,
              [name]: {
                ...prev[name],
                preview: response.data.data.s3Url,
                isUploading: false,
              },
            }));
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: response.data.data.s3Url,
            }));
          } else {
            console.error('Server response error:', response);
          }
        } catch (error) {
          console.error(`Error uploading ${name}:`, error);
        } finally {
          setFilesData(prev => ({ ...prev, [name]: { ...prev[name], isUploading: false } }));
        }
      };
    
      // General handler for file input change
      const handleFileChange = (name, event) => {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setFilesData(prev => ({ ...prev, [name]: { ...prev[name], preview: reader.result } }));
          };
          uploadFile(name, file);
        }
      };
    
      // Generalized render function for the file input field
      const renderFileInputField = (name, label, defaultImage) => {
        return (
          <div>
            <input
              type="file"
              id={name}
              accept="image/*"
              className={classes.input1}
              onChange={(e) => handleFileChange(name, e)}
              disabled={filesData[name].isUploading}
            />
            <label htmlFor={name} className={classes.label1}>
              {label}
            </label>
            <img
               src={filesData[name].preview || defaultImage}
              alt={`${label} esikatselu`}
              className={classes.imagePreview}
            />
            {filesData[name].isUploading && (
              <p className={classes.uploadingText}>Ladataan...</p>
            )}
          </div>
        );
      };
      const sigPadRef = useRef({});

      const clear = () => {
        sigPadRef.current.clear();
      };
      
      const saveSignature = async () => {
        if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
          const signatureImageDataURL = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
          const blob = await (await fetch(signatureImageDataURL)).blob();
          const filename = `${formData.fullName.replace(/\s+/g, '-')}-allekirjoitus.png`;
          const file = new File([blob], filename, { type: 'image/png' });
      
          try {
            await uploadFile('signature', file);
            // Oletetaan, että uploadFile päivittää formData.signature onnistuneesti
            return true;
          } catch (error) {
            console.error('Virhe allekirjoituksen tallennuksessa:', error);
            return false;
          }
        } else {
          setAlert({ show: true, message: 'Allekirjoitus vaaditaan.', severity: 'error' });
          return false;
        }
      };
      
      
   
      useEffect(() => {
        const fetchTokenData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
    
            if (token) {
                try {
                    const response = await axios.get(`https://partners.salaovi.fi/api/verify-token?token=${token}`);
                    if (response.data.valid && response.data.data.email && response.data.data.password) {
                        // Yhdistä saatu data olemassa olevaan formDataan
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            ...response.data.data
                        }));
                        setIsValidToken(true);
    
                        // Aseta registrationType, jos type on saatavilla formData:ssa
                        if (response.data.data.type) {
                            setRegistrationType(response.data.data.type);
                        }
    
                        // Kirjaa käyttäjä sisään
                        await authProvider.login({
                            email: response.data.data.email,
                            password: response.data.data.password,
                        });
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };
    
        fetchTokenData();
    }, [authProvider]); // Lisätty authProvider riippuvuuksiin, jos sitä käytetään funktion ulkopuolella
    

  const dynamicId = `autocomplete-${Date.now()}`;

  const renderTextField = (name, label, type = 'text', required = false) => (
    <Grid item xs={12} sm={6}>
      <TextField
        className={classes.input}
        label={label}
        name={name}
        type={type}
        value={formData[name]}
        onChange={handleChange}
        required={registrationType === 'AGENT'}
        fullWidth
      />
    </Grid>
  );
  const renderCheckbox = (name, label) => (
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData[name]}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </Grid>
  );
  const [passwordStrength, setPasswordStrength] = useState('');
  const firstName = formData.fullName.split(' ')[0];
  const renderDropdownField = (name, label, value, handleChange) => {
    return (
      <TextField
        select
        required={registrationType === 'AGENT'}
        label={label}
        value={value}
        onChange={handleChange}
        fullWidth
        SelectProps={{
          native: true,
        }}
      >
        <option value="6080001e12ac3e0df4a35619">En työskentele</option>
        <option value="6075a6fff368204a35dc7ef0">RE/MAX</option>
        <option value="6075a70cf368204a35dc7ef2">Huom!</option>
        <option value="6075a728f368204a35dc7ef6">Kiinteistömaailma</option>
        <option value="6075a717f368204a35dc7ef3">Roof Group</option>
        <option value="6075a731f368204a35dc7ef7">Huoneistoketju</option>
        <option value="6075a721f368204a35dc7ef5">Sp-Koti</option>
        <option value="6075a71cf368204a35dc7ef4">Aninkainen</option>
        <option value="6075a739f368204a35dc7ef8">Glik</option>
        <option value="6075a706f368204a35dc7ef1">Kahdeksas päivä</option>
        <option value="6075a740f368204a35dc7ef9">Solid House</option>
        <option value="6076a4457c6ce14bfa9be56b">Kotijoukkue</option>
        <option value="6076a4fc7c6ce14bfa9be56c">Bo LKV</option>
        <option value="6080001e12ac3e0df4a35619">Neliöt Liikkuu</option>
      </TextField>
    );
  };



  return (
 <>
        {/* ... lomakkeen ja muun sisällön renderöinti ... */}
        <Snackbar open={alertState.open} autoHideDuration={6000} onClose={closeAlert}>
            <MuiAlert elevation={6} variant="filled" onClose={closeAlert} severity={alertState.severity}>
                {alertState.message}
            </MuiAlert>
        </Snackbar>
   
    
 <div className={classes.root}>
      {registrationType === null ? (
       <>

     </>
      ) : (


      <Paper className={classes.paper}>
      <div className={classes.imageContainer}>
        <img src="https://www.salaovi.fi/wp-content/uploads/2020/08/salaovi.png" alt="Kirjautumissivu Header" className={classes.image} />  {/* Lisätty kuva */}
        </div>
        <Typography variant="h5" className={classes.title}>
        Tervetuloa! Mahtava saada teidät yhteistyökumppaniksi {firstName }.  Viimeistele vielä rekisteröitymisesi jotta saamme yhteistyön käyntiin!
        </Typography>
       
        <form onSubmit={handleSubmit}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Rekisteröintilomakkeen välilehdet">
            <Tab label="Profiili" />
     
            {registrationType === 'AGENT' && <Tab label="Vakiotarjous" />}
            <Tab label="Käyttöehdot" />
            <Tab label="Maksutapa" />
          
            
          </Tabs>
         
          <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
        

            <Grid item xs={12} sm={6}>
            {renderFileInputField('profilePicture', 'Lisää Profiilikuva', defaultProfileImage)}
            </Grid>
            <Grid item xs={12} sm={6}>
            {renderFileInputField('companyImage', 'Lisää Välitysliikkeen Logo', defaultCompanyImage)}
            </Grid>
            


            {registrationType === 'AGENT' && (
              <>
                          <Grid item xs={12} sm={12}>
  
  <br></br>
</Grid>

            <Grid item xs={12} sm={12}>
            <Slider
  value={formData.maxDistance / 1000 || ''} // Jaetaan arvo tuhannella näytettäväksi käyttöliittymässä
  onChange={handleDistanceChange}
  aria-labelledby="distance-slider"
  valueLabelDisplay="on" // Näyttää arvon liukukytkimessä
  min={10}
  max={100}
  valueLabelFormat={(value) => `${Math.round(value)} km`} // Poistetaan desimaalit ja lisätään 'km'
/>
<Typography id="distance-slider" gutterBottom>
     <h3> Kuinka monen kilometrin päästä toimistostasi pystyt ottaa asiakkaita?</h3>
      <p></p>
    </Typography>
            </Grid>
            </>
            )}
            </Grid>

            {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
          </TabPanel>

          <TabPanel value={tabValue} index={registrationType === 'AGENT' ? 2 : 1}>
          <Grid container spacing={2}>
        
          <Typography className={`${classes.ehdot} ${classes.scrollableArea}`} gutterBottom>
          <h2>Käyttöehdot</h2>

<h4>1. Palvelun tarkoitus</h4>
<p>Palvelun kautta saat tarjouspyyntöjä asiakkailta, jotka suunnittelevat asuntonsa myyntiä toimialueellasi. Tarjouspyynnön saapumisen jälkeen sinulla on yksi tunti aikaa muokata tarjoustasi. Mikäli et muokkaa tarjousta osallistut tarjouskilpaan vakiotarjouksella. Olet mukana jokaisella tarjouskierroksella josta sinulle tulee ilmoitus. Asiakkaalle valikoituminen tarkoittaa, että palvelu valitsi tarjouksesi 4 parhaan joukkoon asiakkaalle ja voitte sen jälkeen olla yhteydessä asiakkaan kanssa ja sopia mahdollisen hankintakäynnin. Asiakas voi kuitenkin valita välittäjänsä suoraan sovelluksesta ilman hankintakäyntiä.</p>
<h4>2. Valikoitumisperuste ja hinta</h4>
<p><b>Olet mukana jokaisella alueesi tarjouskierroksella vakiotarjouksellasi.</b> Sinulla on 1h aikaa muokata tarjousta sen saapumisen jälkeen. Asiakkaalle valikoituminen maksaa 25€. Mikäli vinkki johtaa kauppaan veloitetaan 5% komission palkkiostasi. Ilmoittamatta jäteyistä toimeksiannoista ja myynneistä peritään 500€ vakiokorvaus. Hintoihin lisätään ALV 24%. Valikoituminen veloitetaan automaattisesti maksutavaltasi. Saat tiedon valikoitumisesta sovellukseen sekä sähköpostiin. Muista tarkastaa, että viestit osoitteesta info@salaovi.fi eivät mene roskapostiin. </p>
<h4>3. Hyvitysperusteet ja sopimuksen kesto</h4>
<p>Jokainen toimitettu asiakas on vinkki. Emme takaa jokaiselle vinkille hankintakäyntiä tai asiakkaan tavoittamista mutta pyrimme kaikin keinoin siihen ja ohjeistamme myös asiakkaitamme tähän. Maksu hyvitetään teille sovellukseen kuitenkin aina mikäli asiakas ei olekaan myymässä asuntoaan. Palveluun liittyessä sitoudut osallistumaan tarjouskilpailuihin koko alueellasi.</p>
<br></br>
<h2>Ehdot palveluntarjoajalle</h2>
<br></br>
  <p>Näitä yleisiä ehtoja sovelletaan Salaoven (Salaovi oy:n) ja Palveluntarjoajan välillä, joka käyttää Salaoven ylläpitämää Alustaa omien palveluidensa tarjoamiseksi. Nämä ehdot koskevat kaikkea Palveluntarjoajan Alustan käyttöä. Sopimukseen ei sovelleta muita sopimusehtoja, elleivät Osapuolet ole nimenomaisesti kirjallisesti sopineet asiasta.</p>
  <p>Allekirjoittamalla tai muuten hyväksymällä Sopimuksen taikka hyväksymällä Sopimuksen sähköisen sopimuksentekoprosessin osana (esim. painamalla ”Hyväksyn”) Palveluntarjoaja hyväksyy nämä ehdot ja sitoutuu noudattamaan niitä.</p>
  <p>Mikäli hyväksytte nämä ehdot yrityksen tai muun oikeushenkilön puolesta, vakuutatte, että teillä on toimivalta sitouttaa kyseinen oikeushenkilö näihin ehtoihin.</p>
  <p>Palveluntarjoajalla ei ole oikeutta käyttää Alustaa, mikäli Palveluntarjoaja ei hyväksy näitä ehtoja</p>
  <h3><strong>1. Määritelmät</strong></h3>
  <p>Alusta tarkoittaa Salaoven verkkosivustoilla sekä mobiilisovelluksessa tarjoamaa palvelualustaa, jonka kautta Palveluntarjoaja voi tarjota omia palveluitaan ja Asiakas ostaa Palveluntarjoajan palveluita.</p>
  <p>Asiakas tarkoittaa luonnollista henkilöä, yritystä tai muuta oikeushenkilöä, joka tilaa Palveluntarjoajan palveluita Alustan kautta.</p>
  <p>Asiakas tarkoittaa kuluttajaa, joka suunnittelee asunnonmyyntiprosessin aloittamista ja tilaa vertailun salaovi.fi palvelusta. Yhteistyöyrityksellä ei ole yksinoikeutta Asiakkaaseen vaan sen saa lisäksi käyttöönsä mahdollisesti myös palvelun vertailemat 4 muuta palveluntarjoajaa.</p>
  <p>Immateriaalioikeudet tarkoittavat tekijänoikeuksia, lähioikeuksia (sisältäen mm. oikeudet tietokantaan ja luetteloon tai valokuvaan), patentteja, hyödyllisyysmalleja, mallioikeuksia, tavaramerkkejä, toiminimiä, liike- ja yrityssalaisuuksia, tietotaitoa tai muuta rekisteröityä tai rekisteröimätöntä immateriaalioikeutta.</p>
  <p>Komissio tarkoittaa Alustan kautta tehtyä Tilauksen hinnasta johdettavaa summaa, minkä Salaovi veloittaa Palveluntarjoajalta Sopimuksen mukaisesti.</p>
  <p>Osapuoli tarkoittaa Palveluntarjoajaa tai Salaovea (yhdessä ”Osapuolet”).</p>
  <p>Palveluntarjoajan Aineisto tarkoittaa Palveluntarjoajan Salaovelle toimittamaa aineistoa. Aineisto saattaa sisältää palvelunkuvauksia, hintoja, profiilitietoja, valokuvia, dataa, informaatiota, palautetta, ehdotuksia, tekstiä, sisältöä ja muuta materiaalia, jota ladataan, esitetään, toimitetaan, tarjotaan tai muuten välitetään tai tallennetaan Alustan yhteydessä tai siihen liittyen.</p>
  <p>Sopimus tarkoittaa näitä yleisiä ehtoja sekä Palveluntarjoajan Alustalla hyväksymiä muita ehtoja.</p>
  <p>Tilaus tarkoittaa Asiakkaan ja Palveluntarjoajan välillä Alustan kautta solmittua sitovaa tilausta ja sopimusta Palveluntarjoajan palveluiden ostamisesta ja toimittamisesta, johon sovelletaan Asiakkaan ja Palveluntarjoajan välistä sopimusta ja ehtoja.</p>
  <p>Liidillä tässä sopimuksessa tarkoitetaan kuluttajaa, joka on aloittamassa asunnonmyyntiprosessia ja tilannut kilpailutuksen salaovi.fi palvelusta. Yhteistyöyrityksellä ei ole yksinoikeutta liidiin vaan sen saa lisäksi käyttöönsä palvelun vertailemat 1-4 muuta yritystä.</p>
  <p>Salaovi tarkoittaa Salaovi palvelua eli Salaovi oy:ta</p>
  <p>Palveluntarjoaja tarkoittaa Sopimuksen hyväksynyttä Salaovi yhteistyökumppania, joka tarjoaa omia palveluitaan Salaoven ylläpitämän Alustan kautta Sopimuksen mukaisesti.</p>
  <h3>2. Yleistä</h3>
  <p>2.1 Salaovi tarjoaa Palveluntarjoajalle Alustan, jonka kautta Palveluntarjoaja voi myydä palveluitaan Asiakkaille. Salaovi toimii ainoastaan välittäjänä Asiakkaan ja Palveluntarjoajan välillä ja Palveluntarjoaja tekee aina erillisen Tilauksen Asiakkaan kanssa.</p>
  <p>2.2 Palveluntarjoaja valtuuttaa Salaoven veloittamaan maksuja Palveluntarjoajalta palvelusta. Tällaisia maksuja ovat mm. maksu liidistä/tarjouksen esittelemisestä asiakkaalle (25€) Salaovi voi käyttää kolmannen osapuolen maksupalveluita maksujen toteuttamiseksi.</p>
  <p>2.3 Selvyyden vuoksi todetaan, että Salaovi ei toimi maksupalveluntarjoajana Palveluntarjoajan ja Asiakkaiden välillä. Palveluntarjoaja kantaa luottotappioriskin kaikkiin maksuihin.</p>
  <p>2.4 Salaovi toimittaa Palveluntarjoajalle raportin, tilausvahvistuksen ja/tai kuitin Alustalla kulloinkin saatavilla olevien ominaisuuksien mukaisesti.</p>
  <p>2.5 Salaovi voi käyttää Palveluntarjoajan nimeä ja logoa referenssinä</p>
  <p>2.6 Palveluntarjoaja määrittää hinnan kullekin välitystarjoukselle itse hintahaarukkana. Hintatarjous ei ole sitova mutta sen noudattaminen on suotavaa. Salaovi käyttää vakiotarjousta pohjana kaikkiin saapuviin tarjouspyyntöihin, mikäli palveluntarjoaja ei muokkaa sitä tunnin kuluttua saapumisesta. Kaikki tarjoukset ovat luottamuksellisia ja ne ovat nähtävillä vain asiakkaalle sekä kiinteistönvälittäjälle. Asiakas voi hyväksyä tai hylätä tarjouksen suoraan applikaatiosta</p>
  <h3>3. Käyttöoikeus ja Alustan käyttäminen</h3>
  <p>3.1 Palveluntarjoaja myöntää Salaovelle ilman erillistä kustannusta maailmanlaajuisen, alilisensoitavan ja siirtokelpoisen lisenssin käyttää, muokata, tallentaa, levittää, näyttää, julkaista ja esittää Palveluntarjoajan Aineistoa Alustalla. Salaovella on oikeus määrittää Palveluntarjoajan ja Palveluntarjoajan Aineiston Alustalla saaman näkyvyyden ja liikenteen määrä suhteessa muihin Salaoviin palveluntarjoajiin.</p>
  <p>3.2 Salaovi myöntää Palveluntarjoajalle rajoitetun oikeuden käyttää Alustaa Asiakkaiden Alustalla tekemien Pyyntöjen vastaanottamiseksi ja käsittelemiseksi sekä muiden Salaoven erikseen kirjallisesti toimittamien Alustaa koskevien palvelukuvausten mukaisesti.</p>
  <p>3.3 Palveluntarjoajalla ei ole oikeutta jälleenmyydä tai levittää Alustaa, sen sisältöä tai sen osaa. Immateriaalioikeudet Alustalle ja Alustan käyttöanalytiikkaan kuuluvat Salaovelle. Sopimuksella ei siirretä Salaoviin Alustalle liittyviä Immateriaalioikeuksia tai muita Immateriaalioikeuksia Palveluntarjoajalle.</p>
  <p>3.4 Palveluntarjoaja on vastuussa Alustan käyttämiseen tarvittavien laitteiden ja yhteyksien hankkimisesta ja niiden kustannuksista.</p>
  <p>3.5 Palveluntarjoaja sitoutuu olemaan käyttämättä Alustaa laittomaan tai luvattomaan toimintaan.</p>
  <p>3.6 Palveluntarjoajan ei tule käyttää Alustaa minkään tiedon säilyttämiseen tai varmuuskopiointiin.</p>
  <p>3.7 Palvelun kautta tilatuista palveluista Alusta tallentaa laadunvalvonta-, laskutus- ja analytiikkatarkoituksiin tiedot muun muassa Käyttäjästä sekä palvelun kestosta ja ajankohdasta.</p>
  <p>3.8 Palveluntarjoaja varmistaa ja on vastuussa siitä, että sen tarjoamien palveluiden laatu noudattaa kaikkia soveltuvia lakeja, viranomaisen määräyksiä sekä sopimuksiin (mukaan lukien Tilauksiin) perustuvia Asiakkaisiin kohdistuvia velvoitteita. Palveluiden on noudatettava Salaoven antamia kohtuullisia ohjeita.</p>
  <p>3.9 Henkilö, joka allekirjoittaa tai hyväksyy Sopimuksen Palveluntarjoajan puolesta vakuuttaa, että hänellä on oikeus edustaa Sopimuksessa yksilöityä Palveluntarjoajaa. Palveluntarjoaja vakuuttaa, että Alustalle lisätyt tiedot, jotka perustuvat Palveluntarjoajan käyttäjätiliä koskeviin tietoihin, ovat paikkansapitäviä. Palveluntarjoaja sitoutuu pitämään yhteystietonsa sekä maksutietonsa ajan tasalla Alustalla.</p>
  <p>3.10 Palveluntarjoaja vastaa kaikista Palveluntarjoajan Alustalla julkaistavaan hinnastoon ja palvelukuvauksiin liittyvistä korjauksista ja päivityksistä. Palveluntarjoaja määrittelee itsenäisesti palveluidensa hinnat Alustalla. Alustalla ilmoitetut hinnat eivät saa olla korkeampia kuin hinnat, jotka Palveluntarjoaja veloittaa asiakkailta</p>
  <p>3.11 Palveluntarjoaja julkaisee kaiken tarvittavan tiedon Palveluntarjoajasta ja Palveluntarjoajan ja Asiakkaan välisestä sopimuksesta Alustalla soveltuvan lainsäädännön mukaisesti. Tällaiset tiedot sisältävät muun muassa tiedot yrityksestä ja Palveluntarjoajan yhteystiedot, tietoa tarjotun tuotteen ominaisuuksista, yksittäisen tuotteen hinnoista (sisältäen alv), toimitushinnoista ja tavoista, tilauksen vähimmäismäärästä, maksutavoista, toimitusajoista ja tarvittaessa valitusten käsittelystä.</p>
  <p>3.12 Salaovi ei takaa liidien määrää, kohteen tietojen oikeellisuutta, asiakkaan tavoittamista tai hankintakäyntejä kohteisiin. Asiakkaan tulee suunnitella asuntonsa myyntiä. Salaovi ei tarkista kohteen tietojen oikeellisuutta ennen tietojen välittämistä eteenpäin. Virheelliset asiakkaat tarkastetaan kuukausittain ilmoituksesta ja hyvitetään palveluntarjoajalle, mikäli asiakas todetaan virheelliseksi.</p>
  <h3>4. Käyttäjätilit</h3>
  <p>4.1 Voidakseen käyttää Alustaa, Palveluntarjoajan tulee luoda käyttäjätili Alustalla annettujen ohjeiden mukaisesti.</p>
  <p>4.2 Alustan käyttäjätilit ovat henkilökohtaisia kullekin Käyttäjälle. Palveluntarjoaja ei saa antaa Alustan käyttäjätiliään kolmansien käyttöön. Palveluntarjoaja on vastuussa kaikista toimista, joita Käyttäjä tekee Alustalla. Salaovi voi perustellusta syystä poistaa Käyttäjän käyttäjätilin tai estää Käyttäjän pääsyn Alustalle.</p>
  <p>4.3 Palveluntarjoajan tulee estää käytössään olevan Alustalle liittyvien käyttäjätilien luvaton käyttö ja pidettävä kirjautumistiedot salassa.</p>
  <p>4.4 Palveluntarjoajan on ilmoitettava Salaovelle välittömästi, mikäli käyttäjätilejä on käytetty luvattomasti.</p>
  <p>4.5 Palveluntarjoaja sitoutuu pitämään kaikki tilinsä tiedot luottamuksellisina ja on vastuussa kaikesta tilillään tapahtuvasta toiminnasta.</p>
  <p>4.6 Palveluntarjoajan on ilmoitettava Salaovelle oikeat ja ajantasaiset tiedot, joita tarvitaan käyttäjätilien luomiseen, asiakasyhteydenpitoon ja laskuttamiseen. Palveluntarjoajan on ilmoitettava Salaovelle välittömästi, mikäli nämä tiedot muuttuvat.</p>
  <p>4.7 Alustalle hyväksytään ainoastaan aktiivisesti työskenteleviä kiinteistönvälittäjiä tai myyntineuvottelijoita. Alustaa ei saa käyttää esimerkiksi ajanvaraaja, sihteeri tms.</p>
  <h3>5. Palveluntarjoajan Aineisto</h3>
  <p>5.1 Immateriaalioikeudet Palveluntarjoajan Aineistoon kuuluvat Palveluntarjoajalle. Salaovella on oikeus käyttää Palveluntarjoajan Aineistoa ilman korvausta (mukaan lukien Alustan ylläpitämiseksi, kehittämiseksi ja Palveluntarjoajan Aineiston esittämiseksi Alustalla ja markkinointitarkoituksia varten). Palveluntarjoaja on vastuussa siitä, että Palveluntarjoajan Aineistoa voidaan lainmukaisesti käyttää Sopimuksen mukaisiin tarkoituksiin.</p>
  <h3>6. Henkilötiedot</h3>
  <p>6.1 Salaovi kerää Palveluntarjoajan ja Asiakkaan tilausta ja Alustan käyttöä koskevia henkilötietoja, joita Salaovi tarvitsee Alustan tarjoamiseksi Asiakkaalle, Palveluntarjoajalle, Käyttäjille ja muihin Salaoviin tietosuojaselosteen mukaisiin tarkoituksiin. Tällaisia tietoja ovat muun muassa nimet, yhteystiedot ja maksutiedot. Salaovi käsittelee näitä tietoja rekisterinpitäjänä tietosuojaselosteensa mukaisesti. Tietosuojaseloste on saatavilla osoitteessa <a href="https://www.Salaovi.fi/tietosuojaseloste/">http://www.Salaovi.fi/tietosuojaseloste/</a> .</p>
  <p>6.2 Siltä osin kuin Salaovi tarjoaa Alustallaan toimintoja, joiden perusteella Palveluntarjoaja voi siirtää ja käsitellä rekisterinpitäjänä henkilötietoja Alustalla, esimerkiksi niin, että Palveluntarjoajan Aineisto sisältää Palveluntarjoajan asiakkaita koskevia henkilötietoja, on Palveluntarjoaja henkilötietojen rekisterinpitäjä ja Salaovi käsittelee, tarjoamalla Palveluntarjoajalle Alustan, näitä henkilötietoja Palveluntarjoajan lukuun henkilötietojen käsittelijänä Sopimuksen mukaisiin tarkoituksiin Sopimuksen voimassaolon aikana. Jos Salaovi käsittelee henkilötietoja edellä mainitusti Palveluntarjoajan lukuun, kyseiseen käsittelyyn sovelletaan Salaoven tietojenkäsittelyehtoja. Tietojenkäsittelyehdot ovat saatavilla osoitteessa <a href="https://www.Salaovi.fi/tietojenkasittelyehdot/">http://www.Salaovi.fi/tietojenkasittelyehdot/</a> .</p>
  <h3>7. Hinnat, maksaminen ja verot</h3>
  <p>7.1 Salaovi veloittaa Palveluntarjoajalta Komission ja mahdolliset muut Alustalla ilmoitetut vähennykset ja palvelumaksut jokaisesta Asiakkaasta, jonka Palveluntarjoaja on vastaanottanut Alustan kautta. Komissio lasketaan Alustan kautta myytyjen tuotteiden ja palveluiden arvonlisäverollisesta hinnasta, jonka Palveluntarjoaja on määritellyt välityspalkkioksi.</p>
  <p>7.3 Salaovi pidättää oikeuden muuttaa Komission suuruutta. Salaovi ilmoittaa muutoksista Palveluntarjoajalle viimeistään seitsemän (7) päivää ennen uusien maksujen voimaantuloa. Mikäli Palveluntarjoaja ei hyväksy uusia maksuja, Palveluntarjoajalla on oikeus irtisanoa Sopimus ilmoittamalla siitä Salaovelle kirjallisesti kahden viikon kuluessa siitä, kun Palveluntarjoaja on vastaanottanut ilmoituksen muutoksista. Jos Palveluntarjoaja jatkaa Alustan käyttöä muutosten voimaantulon jälkeen, katsotaan Palveluntarjoajan hyväksyneen uuden Komission.</p>
  <p>7.4 Palveluntarjoaja vastaa kaikista veroista ja kuluista, jotka liittyvät tämän tuotteisiin ja palveluihin, mukaan lukien Palveluntarjoajan palveluihin sisältyvän arvonlisäveron tilittämisen.</p>
  <p>7.5 Palveluntarjoajaa laskutetaan seuraavan taulukon mukaisesti. Asiakas valikoituu lisäksesi 2-4 palveluntarjoajalle hinta 25€. Salaoven myyntikomissio on 5% Alustan kautta toteutuneesta välityspalkkiosta. Maksunpalautuksissa 2€ käsittelykulu per maksu</p>
  <p>Hintoihin lisätään kulloinkin voimassa oleva ALV (24%)</p>
  <p>7.6 Palveluntarjoaja raportoi kaikista tapahtumista Alustalle. Salaovi laskuttaa komission näiden ilmoitusten perusteella. Palveluntarjoaja on velvollinen ilmoittamaan kuukauden tapahtumat viimeistään seuraavan kuukauden 1. päivään mennessä Alustalle. Ilmoituksen tulee sisältää tiedot asiakkaista, jotka ovat johtaneet toimeksiantosopimukseen sekä toteutuneen nettomyynnin. Ilmoittamatta jätetyistä tapahtumista laskutetaan 500€+alv tarkastusmaksu.</p>
  <h3>8. Keskeytykset ja muutokset</h3>
  <p>8.1 Salaovella on oikeus keskeyttää Alustan tarjoaminen huoltotoimenpiteiden vuoksi. Mikäli Salaovi keskeyttää Alustan tarjoamisen, Salaovi pyrkii ilmoittamaan keskeytyksestä Palveluntarjoajalle etukäteen tai, ellei tämä ole kohtuudella mahdollista, viipymättä sen jälkeen, kun Salaovi on saanut tiedon keskeyttämisen syystä. Alusta voi olla väliaikaisesti poissa käytöstä myös muista Salaoviin vaikutusmahdollisuuksien ulkopuolella olevista syistä.</p>
  <p>8.2 Salaovella on oikeus estää Palveluntarjoajan tai Käyttäjän pääsy Alustalle ilman etukäteisilmoitusta, mikäli Salaovi perustellusti epäilee, että Palveluntarjoaja tai Käyttäjä on rikkonut Sopimusta.</p>
  <p>8.3 Alusta tarjotaan ‘’sellaisena kuin se on’’ ja ‘’kuten se on saatavilla’’. Salaovi ei takaa Alustan jatkuvuutta, oikea-aikaisuutta, turvallisuutta, virheettömyyttä tai viruksettomuutta, eikä se anna takuita Alustan käytöllä mahdollisesti saavutettavista tuloksista tai Palvelutarjoajan Alustaa käyttämällä hankkimista tiedoista. Palveluntarjoaja ymmärtää ja hyväksyy, että Salaovi kehittää Alustaa ja siihen liittyvät ominaisuudet voivat muuttua Sopimuksen voimassaoloaikana.</p>
  <h3>9. Salassapito</h3>
  <p>9.1 Kumpikin Osapuoli sitoutuu pitämään salassa toiselta Osapuolelta saamansa luottamukselliset aineistot ja tiedot sekä olemaan käyttämättä niitä muuhun kuin Sopimuksen mukaiseen tarkoitukseen.</p>
  <p>9.2 Osapuolen on välittömästi lopetettava toiselta Osapuolelta saamansa luottamuksellisen aineiston ja tietojen käyttäminen sekä pyynnöstä palautettava tai hävitettävä kyseinen aineisto ja tiedot viimeistään, kun Sopimus päättyy tai pyynnöstä jo aiemmin, jos Osapuoli ei enää tarvitse kyseistä aineistoa tai tietoja Sopimuksen mukaiseen tarkoitukseen.</p>
  <p>9.3 Tässä̈ kohdassa sovittu salassapitovelvoite päättyy, kun 5 vuotta on kulunut Sopimuksen päättymisestä̈, ellei laissa edellytetä̈ pidempää̈ salassapitovelvoitetta.</p>
  <h3>10. Sopimuksen voimassaolo ja päättyminen</h3>
  <p>10.1 Sopimus tulee voimaan Sopimuksen hyväksymispäivämäärällä ja on toistaiseksi voimassa oleva. Kumpikin osapuoli voi irtisanoa Sopimuksen kirjallisesti noudattaen 7 päivän irtisanomisaikaa.</p>
  <p>10.2 Osapuolella on oikeus irtisanoa Sopimus kirjallisesti päättymään välittömästi, mikäli toinen Osapuoli rikkoo Sopimusta olennaisesti eikä korjaa rikkomustaan 7 päivän sisällä vastaanotettuaan asiaa koskevan korjauskehotuksen toiselta Osapuolelta. Lisäksi Salaovella on oikeus irtisanoa Sopimus (i) päättyväksi kymmenen (10) päivän kuluessa ilmoituksesta, mikäli Palveluntarjoaja on rikkonut Sopimuksen ehtoja tai (ii) päättymään välittömästi, mikäli Palveluntarjoaja lopettaa tai keskeyttää liiketoimintansa tai Palveluntarjoaja ei muuten ole kykenevä täyttämään velvollisuuksiaan Asiakkaita tai Salaovea kohtaan.</p>
  <p>10.3 Salaovi on oikeutettu keskeyttämään Sopimuksen täyttämisen väliaikaisesti tai irtisanomaan Sopimuksen, mikäli Palveluntarjoajan Asiakkaille tarjoamien tuotteiden ja/tai palveluiden laatu ei täytä Salaoviin asettamia kohtuullisia vaatimuksia.</p>
  <p>10.4 Sopimuksen päättyessä Palveluntarjoajan on lopetettava Alustan käyttö välittömästi.</p>
  <h3>11. Vastuunrajoitukset</h3>
  <p>11.1 Salaovi ei vastaa Asiakkaan ja Palveluntarjoajan väliseen Tilaukseen liittyvistä reklamaatioista tai vahingoista. Kyseisiin reklamaatioihin ja vahinkoihin sovelletaan Asiakkaan ja Palveluntarjoajan välillä Tilauksessa sovittuja ehtoja.</p>
  <p>11.2 Salaovi ei vastaa mistään välillisestä tai epäsuorasta vahingosta. Välillisenä vahinkona pidetään esimerkiksi saamatta jäänyttä voittoa tai vahinkoa, joka johtuu liikevaihdon menetyksestä, saatavuuden kärsimisestä tai tietojen häviämisestä. Sopimukseen perustuva Salaoviin vahingonkorvausvelvollisuus on yhteensä enintään 100% Salaoviin Sopimuksen mukaisesti vastaanottamista Komissioista vahinkoa edeltäneen kuuden (6) kuukauden aikana. Tämän kohdan mukaiset vastuunrajoitukset eivät koske vahinkoa, joka on aiheutettu tahallisesti tai törkeällä huolimattomuudella.</p>
  <p>11.3 Salaovi ei vastaa viivästyksestä tai vahingosta, joka johtuu sen kohtuullisten vaikutusmahdollisuuksien ulkopuolella olevasta esteestä, jonka seurauksia Salaovi ei kohtuudella olisi voinut välttää tai voittaa. Ylivoimaisena esteenä pidetään esimerkiksi kolmannen osapuolen ohjelmiston ja laitteiden virheet ja keskeytykset. Salaoven alihankkijaa kohdannut ylivoimainen este vapauttaa myös Salaoven vastuusta.</p>
  <p>11.4 Salaovi ei takaa Asiakkaiden määrää, tietojen oikeellisuutta, asiakkaan tavoittamista tai hankintakäyntejä kohteisiin. Salaovella on velvollisuus pyrkiä varmistamaan tietojen oikeellisuus parhaaksi katsomallaan tavalla</p>
  <h3>12. Sopimuksen muuttaminen</h3>
  <p>12.1 Salaovi voi muuttaa Sopimusta ilmoittamalla Palveluntarjoajalle muuttuneesta Sopimuksesta 30 päivää ennen uuden Sopimuksen voimaantuloa asiakastiedottein tai muutoin kirjallisesti, jolloin Palveluntarjoajalla on oikeus irtisanoa Sopimus ennen muutetun Sopimuksen voimaantuloa ilmoittamalla asiasta Salaovelle kirjallisesti.</p>
  <h3>13. Sopimuksen siirtäminen</h3>
  <p>13.1 Salaovella on oikeus siirtää Sopimus ja Sopimuksen mukaiset oikeutensa ja velvoitteensa konserniyhtiölleen tai Salaoven tätä Sopimusta koskevan liiketoiminnan siirron/kaupan siirronsaajalle/ostajalle ilman Palveluntarjoajan suostumusta.</p>
  <p>13.2 Palveluntarjoajalla ei ole oikeutta siirtää Sopimusta tai Sopimuksen mukaisia oikeuksia tai velvollisuuksia kolmannelle kokonaan tai osittain ilman Salaoven etukäteistä kirjallista hyväksyntää.</p>
  <h3>14. Ilmoitukset</h3>
  <p>14.1 Kaikki Sopimukseen liittyvät ilmoitukset tulee tehdä postitse tai sähköpostitse vastaanottavan Osapuolen Sopimuksessa mainittuun osoitteeseen. Jos Osapuolen yhteystiedot muuttuvat, on tästä ilmoitettava toiselle Osapuolelle ilman aiheetonta viivytystä.</p>
  <h3>15. Muut ehdot</h3>
  <p>15.1 Mikään Sopimuksessa ei muodosta tai katsota muodostavan osakkuutta, yhteisyritystä tai muuta suhdetta Osapuolten välille kuin Sopimuksessa nimenomaisesti sovittu sopimusperusteinen suhde.</p>
  <p>15.2 Salaovella on oikeus käyttää alihankkijoita Alustan tarjoamiseksi ja Sopimuksen mukaisten velvoitteidensa täyttämiseksi.</p>
  <p>15.3 Jos Sopimukseen sisältyvän lausekkeen katsotaan olevan täytäntöönpanokelvoton, ei se vaikuta Sopimuksen muiden lausekkeiden pätevyyteen.</p>
  <p>15.4 Sopimus muodostaa Osapuolten välisen koko sopimuksen siinä käsitellystä aiheesta.</p>
  <h3>16. Sovellettava laki ja riidanratkaisu</h3>
  <p>16.1 Sopimukseen sovelletaan Suomen lakia, lukuun ottamatta lainvalintaa koskevia säännöksiä.</p>
  <p>16.2 Sopimuksesta aiheutuvat riidat ratkaistaan lopullisesti välimiesmenettelyssä, Keskuskauppakamarin välimiesmenettelysääntöjen mukaisesti. Välimiesoikeus on yksijäseninen. Välimiesmenettelyn paikka on Helsinki ja sen kieli on suomi.</p>
</Typography>
<Grid item xs={12} sm={12}>
<h4>Allekirjoita Sopimus*</h4>
          <SignaturePad
            ref={sigPadRef}
            canvasProps={{ className: classes.sigPad }}
          />
          <div>
            <Button onClick={clear}>Tyhjennä</Button>
          </div>
          {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
        </Grid>
          
   
    
    <Grid item xs={12} sm={12}>
            <FormControlLabel
      control={
        <Checkbox
          checked={formData.tosAccepted}
          onChange={handleBooleanChange}
          name="tosAccepted" // Tämä nimi tulee vastata formData-tilan avainta
        />
      }
      label="Hyväksyn käyttöehdot"
    />
    </Grid>
    
            </Grid>
           
          </TabPanel>
          {registrationType === 'AGENT' && (
              <>
          <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
          <Typography gutterBottom>
<h2>Lisää vakiotarjouksesi</h2>  
<p>Tämä on tarjouspohjasi ja sitä käytetään vakiotarjouksenasi mikäli et ehdi reagoida tarjouspyyntöön tunnin sisällä</p> 
</Typography>
            
          <DefaultCompleteProposal setTabValue={setTabValue}/>

            </Grid>
          </TabPanel>
          </>
          )}
           <TabPanel value={tabValue} index={registrationType === 'AGENT' ? 3 : 2}>
          <div className={classes.centeredContainer}>

    <div  className="main-container">
       
                 <br></br>
                 <div className="container_card1">
                 <div className="addcard">
            <div className="add-card-container">
              <Elements options={{ locale: 'fi' }} stripe={stripePromise}>
                <h3>Lisää maksutapa</h3>
                <p>Maksutapa on pakollinen mikäli et ole välitysliikkeen alainen välittäjä tai laskutusasiakas</p> 
                <AddCardFormReg apiUrl={'https://api.salaovi.fi'} />
                <Button
    variant="contained"
    color="default"
    onClick={handleSkip} 
    style={{ marginLeft: '8px' }} // Lisää välilyönti 8 pikselin marginaalilla
    
  >
    Ohita
  </Button>
                <div className="wallet-balance2">
                <img 
    src="https://salaovi.s3.amazonaws.com/1699809565139.png"
    alt="Maksutapa" 
    style={{ maxWidth: '360px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
  />
                </div>
              </Elements>
            </div>
          </div>  
          </div>
          </div>

<p>Laskutusasiakas voit seuraavaksi <Link to="/" style={{  }}>kirjautua suoraan sisään</Link></p>

</div>
          </TabPanel>
          <>
          {tabValue > 0 && !isSubmitted && (
  <Button
    variant="text"
    color="default"
    onClick={handleBack}
  >
    Takaisin
  </Button>
)}
   {(tabValue === 0 ) && (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleNext}
    >
      Seuraava
    </Button>
  )}
   {tabValue === (registrationType === 'AGENT' ? 2 : 1) && (
        <Button
          variant="contained"
          color="primary"
          type="submit"
        
        >
          Lähetä
        </Button>
    
  )}

  <div style={{ marginTop: '28px' }}> {/* Säädä tarvittaessa */}
  <Typography gutterBottom>
    Onko Sinulla jo tili? <Link to="/login" style={{  }}>Kirjaudu sisään</Link>
    </Typography>
  </div>
  
</>
         
        </form>
      </Paper>
      )}
    </div>
    </>
  );
};







export default CompleteRegistration;
