import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core'; 
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  required,
  FunctionField,
  useNotify,
  useRedirect,
  SelectInput
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';



const ListingCreate = props => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const [initialValues, setInitialValues] = useState({});
  const [brochure, setBrochure] = useState(null);

const uploadBrochure = async (file) => {
  const uploadFormData = new FormData();
  uploadFormData.append('pic', file);

  try {
    const response = await axios.post('https://api.salaovi.fi/User/uploadAttachment', uploadFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 200 && response.data.message === "Success") {
      setBrochure(response.data.data.s3Url);
      notify('Myyntiesitteen lataus onnistui', 'info');
    } else {
      notify('Myyntiesitteen lataus epäonnistui', 'warning');
    }
  } catch (error) {
    console.error('Myyntiesitteen latausvirhe', error);
    notify('Myyntiesitteen lataus epäonnistui', 'error');
  }
};

const handleBrochureChange = event => {
  const { files } = event.target;
  if (files.length) {
    const file = files[0];
    uploadBrochure(file);
  }
};

// Lataa alustavat arvot ja kuvat
useEffect(() => {
  if (location.state && location.state.record) {
    const { record } = location.state;
    const updatedRecord = {
      ...record,
      commission: record.commission ? record.commission.toString() : '' // Varmistetaan, että commission on merkkijono
    };
    setInitialValues(updatedRecord);
    if (record.images && Array.isArray(record.images)) {
      setImages(record.images.map(image => ({ preview: image, isUploading: false })));
    }
  }
}, [location.state]);

  const uploadImage = async (file, index) => {
    const uploadFormData = new FormData();
    uploadFormData.append('pic', file);
  
    // Asetetaan latauksen tila kyseiselle kuvalle
    setImages(currentImages => currentImages.map((img, idx) => 
      idx === index ? { ...img, isUploading: true } : img
    ));
  
    try {
      const response = await axios.post('https://api.salaovi.fi/User/uploadAttachment', uploadFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 && response.data.message === "Success") {
        // Päivitetään onnistuneesti ladatun kuvan URL ja latauksen tila
        setImages(currentImages => currentImages.map((img, idx) => 
          idx === index ? { ...img, preview: response.data.data.s3Url, isUploading: false } : img
        ));
        notify('Kuvan lataus onnistui', 'info');
      } else {
        notify('Kuvan lataus epäonnistui', 'warning');
      }
    } catch (error) {
      // Poistetaan latauksen tila, jos latauksessa tapahtuu virhe
      setImages(currentImages => currentImages.map((img, idx) => 
        idx === index ? { ...img, isUploading: false } : img
      ));
      console.error('Kuvan latausvirhe', error);
      notify('Kuvan lataus epäonnistui', 'error');
    }
  };

  const handleCommissionChange = (event) => {
    const newCommissionValue = event.target.value;
    setInitialValues(currentValues => ({
      ...currentValues,
      commission: newCommissionValue.toString() // Muutetaan uusi arvo merkkijonoksi
    }));
  };
  
  const handleFileChange = event => {
    const { files } = event.target;
    if (files.length) {
      const file = files[0];
      const index = images.length;
      setImages(currentImages => [...currentImages, { preview: URL.createObjectURL(file), isUploading: false }]);
      uploadImage(file, index);
    }
  };
  return (
    <div style={{ maxWidth: '800px', padding:'20px', margin: '0 auto' }}> 
    <Create title="Luo myynti-ilmoitus" {...props}>
      <SimpleForm initialValues={initialValues}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
  <Typography variant="h5">Ilmoita toimeksiannosta</Typography>
  <br></br>
  <Typography variant="p">Toimeksiannot tulee ilmoittaa viimeistään kun kohde listataan myyntiin verkkoon</Typography>
  <Grid item xs={12}>
  
  {images.map((image, index) => (
    <div key={index} style={{ 
      margin: '10px', 
      width: index === 0 ? '100%' : '150px', 
      display: 'inline-block' 
    }}>
      <img 
        src={image.preview} 
        alt={`Esikatselukuva ${index}`} 
        style={{ 
          width: index === 0 ? '100%' : '150px',
          marginBottom: index === 0 ? '15px' : '0'
        }} 
      />
      {image.isUploading && <p>Ladataan...</p>}
    </div>
  ))}
  <br></br>
  <Button variant="contained" component="label">
    Lisää kuvia
    <input type="file" accept="image/*" hidden onChange={handleFileChange} />
  </Button>
</Grid>


</Grid>
{/* Lisää kentät alla */}
<Grid item xs={12}>
  <Typography variant="h6">Asiakkaan tiedot</Typography>
</Grid>
<Grid item xs={6}>
  <FunctionField
    source="clientName"
    render={record => <Typography>{record.clientName}</Typography>}
  />
</Grid>
<Grid item xs={6}>
<FunctionField
    source="clientEmail"
    render={record => <Typography>{record.clientEmail}</Typography>}
  />
</Grid>
<Grid item xs={12}>
<Typography variant="h6">Kohteen tiedot</Typography>
</Grid>
<Grid item xs={6}>
  <TextInput source="address" label="Osoite" validate={required()} fullWidth />
</Grid>
<Grid item xs={6}>
  <TextInput
    source="apartmentSize"
    label="Asunnon koko m2"
    validate={required()}
    fullWidth
    step="0.01" // Sallii desimaalit
  />
</Grid>
<Grid item xs={6}>
  <TextInput
    source="price"
    label="Hinta €"
    validate={required()}
    fullWidth
    step="0.01" // Sallii desimaalit
  />
</Grid>
<Grid item xs={6}>
  <TextInput source="zillowLink" label="Oikotie-linkki (mikäli julkaistu)" fullWidth />
</Grid>
<Grid item xs={6}>
<TextInput
      source="commission"
      label="Provisio %"
      validate={required()}
      fullWidth
      onChange={handleCommissionChange}
    />
</Grid>
<Grid item xs={6}>
<SelectInput
    source="homeType"
    label="Kiinteistön tyyppi"
    choices={[
      {id: 'Asunto-osake', name: 'Asunto-osake' },
      {id: 'Omakotitalo', name: 'Omakotitalo' },
      {id:'Tontti', name: 'Tontti' },
      {id:'Muu', name: 'Muu' },
    ]}
    validate={required()}
    fullWidth
  />
  
</Grid>
<Grid item xs={6}>
  <TextInput source="year" label="Rakennusvuosi" validate={required()} fullWidth />
</Grid>
<Grid item xs={6}>
  <TextInput source="description" label="Kuvaus" validate={required()} fullWidth />
</Grid>
<Grid item xs={6}>
<Typography variant="p">Myyntiesite (Mikäli olemassa)</Typography>
  {brochure && (
    <div style={{ margin: '10px' }}>
      <a href={brochure} target="_blank" rel="noopener noreferrer">Myyntiesite.pdf</a>
    </div>
  )}
  <br></br>
  <Button variant="contained" component="label">
    Lisää Esite
    <input type="file" accept=".pdf" hidden onChange={handleBrochureChange} />
  </Button>
</Grid>

        </Grid>
      </SimpleForm>
    </Create>
    </div>
  );
};

export default ListingCreate;
