import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";

const ContentContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

export const TermsEdit = () => {
  // HTML-tietosi muuttujassa, käyttäen template literals -syntaksia
  const htmlContent = `
  <h2>Yhteistyösopimus lyhyesti</h2>
  <h3>1. Palvelun tarkoitus</h3>
  <p>Palvelun kautta saat tarjouspyyntöjä asiakkaista, jotka suunnittelevat asuntonsa myyntiä toimialueellasi. Tarjouspyynnön saapumisen jälkeen sinulla on yksi tunti aikaa muokata tarjoustasi. Mikäli et muokkaa tarjousta osallistut tarjouskilpaan vakiotarjouksella. Olet mukana jokaisella tarjouskierroksella josta sinulle tulee ilmoitus. Asiakkaalle valikoituminen tarkoittaa, että Salovi valitsi tarjouksesi 4 parhaan joukkoon asiakkaalle ja voitte sen jälkeen olla yhteydessä asiakkaan kanssa ja sopia mahdollisen hankintakäynnin. Asiakas voi kuitenkin valita välittäjänsä suoraan sovelluksesta ilman hankintakäyntiä.</p>
  <h3>2. Valikoitumisperuste ja hinta</h3>
  <p><b>Olet mukana jokaisella alueesi tarjouskierroksella vakiotarjouksellasi.</b> Sinulla on 1h aikaa muokata tarjousta sen saapumisen jälkeen. Asiakkaalle valikoituminen maksaa 25€. Mikäli vinkki johtaa kauppaan veloitetaan 5% komission palkkiostasi. Ilmoittamatta jäteyistä toimeksiannoista ja myynneistä peritään 500€ vakiokorvaus. Hintoihin lisätään ALV 24%. Valikoituminen veloitetaan automaattisesti maksutavaltasi. Saat tiedon valikoitumisesta sovellukseen sekä sähköpostiin. Muista tarkastaa, että viestit eivät mene roskapostiin.</p>
  <h3>3. Hyvitysperusteet ja sopimuksen kesto</h3>
  <p>Jokainen toimitettu asiakas on vinkki. Emme takaa jokaiselle vinkille hankintakäyntiä tai asiakkaan tavoittamista mutta pyrimme kaikin keinoin siihen. Maksu hyvitetään teille sovellukseen kuitenkin aina mikäli asiakas ei olekaan myymässä asuntoaan. Palveluun liittyessä sitoudut osallistumaan tarjouskilpailuihin koko alueellasi. Tämän sopimuksen irtisanimisaika on 7 päivää</p>
 
  <h1>Ehdot palveluntarjoajalle</h1>
  <p>Näitä yleisiä ehtoja sovelletaan Salaoven (Salaovi oy:n) ja Palveluntarjoajan välillä, joka käyttää Salaoven ylläpitämää Alustaa omien palveluidensa tarjoamiseksi. Nämä ehdot koskevat kaikkea Palveluntarjoajan Alustan käyttöä. Sopimukseen ei sovelleta muita sopimusehtoja, elleivät Osapuolet ole nimenomaisesti kirjallisesti sopineet asiasta.</p>
  <p>Allekirjoittamalla tai muuten hyväksymällä Sopimuksen taikka hyväksymällä Sopimuksen sähköisen sopimuksentekoprosessin osana (esim. painamalla ”Hyväksyn”) Palveluntarjoaja hyväksyy nämä ehdot ja sitoutuu noudattamaan niitä.</p>
  <p>Mikäli hyväksytte nämä ehdot yrityksen tai muun oikeushenkilön puolesta, vakuutatte, että teillä on toimivalta sitouttaa kyseinen oikeushenkilö näihin ehtoihin.</p>
  <p>Palveluntarjoajalla ei ole oikeutta käyttää Alustaa, mikäli Palveluntarjoaja ei hyväksy näitä ehtoja</p>
  <h3><strong>1. Määritelmät</strong></h3>
  <p>Alusta tarkoittaa Salaoven verkkosivustoilla sekä mobiilisovelluksessa tarjoamaa palvelualustaa, jonka kautta Palveluntarjoaja voi tarjota omia palveluitaan ja Asiakas ostaa Palveluntarjoajan palveluita.</p>
  <p>Asiakas tarkoittaa luonnollista henkilöä, yritystä tai muuta oikeushenkilöä, joka tilaa Palveluntarjoajan palveluita Alustan kautta.</p>
  <p>Asiakas tarkoittaa kuluttajaa, joka suunnittelee asunnonmyyntiprosessin aloittamista ja tilaa vertailun salaovi.fi palvelusta. Yhteistyöyrityksellä ei ole yksinoikeutta Asiakkaaseen vaan sen saa lisäksi käyttöönsä mahdollisesti myös palvelun vertailemat 4 muuta palveluntarjoajaa.</p>
  <p>Immateriaalioikeudet tarkoittavat tekijänoikeuksia, lähioikeuksia (sisältäen mm. oikeudet tietokantaan ja luetteloon tai valokuvaan), patentteja, hyödyllisyysmalleja, mallioikeuksia, tavaramerkkejä, toiminimiä, liike- ja yrityssalaisuuksia, tietotaitoa tai muuta rekisteröityä tai rekisteröimätöntä immateriaalioikeutta.</p>
  <p>Komissio tarkoittaa Alustan kautta tehtyä Tilauksen hinnasta johdettavaa summaa, minkä Salaovi veloittaa Palveluntarjoajalta Sopimuksen mukaisesti.</p>
  <p>Osapuoli tarkoittaa Palveluntarjoajaa tai Salaovea (yhdessä ”Osapuolet”).</p>
  <p>Palveluntarjoajan Aineisto tarkoittaa Palveluntarjoajan Salaovelle toimittamaa aineistoa. Aineisto saattaa sisältää palvelunkuvauksia, hintoja, profiilitietoja, valokuvia, dataa, informaatiota, palautetta, ehdotuksia, tekstiä, sisältöä ja muuta materiaalia, jota ladataan, esitetään, toimitetaan, tarjotaan tai muuten välitetään tai tallennetaan Alustan yhteydessä tai siihen liittyen.</p>
  <p>Sopimus tarkoittaa näitä yleisiä ehtoja sekä Palveluntarjoajan Alustalla hyväksymiä muita ehtoja.</p>
  <p>Tilaus tarkoittaa Asiakkaan ja Palveluntarjoajan välillä Alustan kautta solmittua sitovaa tilausta ja sopimusta Palveluntarjoajan palveluiden ostamisesta ja toimittamisesta, johon sovelletaan Asiakkaan ja Palveluntarjoajan välistä sopimusta ja ehtoja.</p>
  <p>Liidillä tässä sopimuksessa tarkoitetaan kuluttajaa, joka on aloittamassa asunnonmyyntiprosessia ja tilannut kilpailutuksen salaovi.fi palvelusta. Yhteistyöyrityksellä ei ole yksinoikeutta liidiin vaan sen saa lisäksi käyttöönsä palvelun vertailemat 1-4 muuta yritystä.</p>
  <p>Salaovi tarkoittaa Salaovi palvelua eli Salaovi oy:ta</p>
  <p>Palveluntarjoaja tarkoittaa Sopimuksen hyväksynyttä Salaovi yhteistyökumppania, joka tarjoaa omia palveluitaan Salaoven ylläpitämän Alustan kautta Sopimuksen mukaisesti.</p>
  <h3>2. Yleistä</h3>
  <p>2.1 Salaovi tarjoaa Palveluntarjoajalle Alustan, jonka kautta Palveluntarjoaja voi myydä palveluitaan Asiakkaille. Salaovi toimii ainoastaan välittäjänä Asiakkaan ja Palveluntarjoajan välillä ja Palveluntarjoaja tekee aina erillisen Tilauksen Asiakkaan kanssa.</p>
  <p>2.2 Palveluntarjoaja valtuuttaa Salaoven veloittamaan maksuja Palveluntarjoajalta palvelusta. Tällaisia maksuja ovat mm. maksu liidistä/tarjouksen esittelemisestä asiakkaalle (25€) Salaovi voi käyttää kolmannen osapuolen maksupalveluita maksujen toteuttamiseksi.</p>
  <p>2.3 Selvyyden vuoksi todetaan, että Salaovi ei toimi maksupalveluntarjoajana Palveluntarjoajan ja Asiakkaiden välillä. Palveluntarjoaja kantaa luottotappioriskin kaikkiin maksuihin.</p>
  <p>2.4 Salaovi toimittaa Palveluntarjoajalle raportin, tilausvahvistuksen ja/tai kuitin Alustalla kulloinkin saatavilla olevien ominaisuuksien mukaisesti.</p>
  <p>2.5 Salaovi voi käyttää Palveluntarjoajan nimeä ja logoa referenssinä</p>
  <p>2.6 Palveluntarjoaja määrittää hinnan kullekin välitystarjoukselle itse hintahaarukkana. Hintatarjous ei ole sitova mutta sen noudattaminen on suotavaa. Salaovi käyttää vakiotarjousta pohjana kaikkiin saapuviin tarjouspyyntöihin, mikäli palveluntarjoaja ei muokkaa sitä tunnin kuluttua saapumisesta. Kaikki tarjoukset ovat luottamuksellisia ja ne ovat nähtävillä vain asiakkaalle sekä kiinteistönvälittäjälle. Asiakas voi hyväksyä tai hylätä tarjouksen suoraan applikaatiosta</p>
  <h3>3. Käyttöoikeus ja Alustan käyttäminen</h3>
  <p>3.1 Palveluntarjoaja myöntää Salaovelle ilman erillistä kustannusta maailmanlaajuisen, alilisensoitavan ja siirtokelpoisen lisenssin käyttää, muokata, tallentaa, levittää, näyttää, julkaista ja esittää Palveluntarjoajan Aineistoa Alustalla. Salaovella on oikeus määrittää Palveluntarjoajan ja Palveluntarjoajan Aineiston Alustalla saaman näkyvyyden ja liikenteen määrä suhteessa muihin Salaoviin palveluntarjoajiin.</p>
  <p>3.2 Salaovi myöntää Palveluntarjoajalle rajoitetun oikeuden käyttää Alustaa Asiakkaiden Alustalla tekemien Pyyntöjen vastaanottamiseksi ja käsittelemiseksi sekä muiden Salaoven erikseen kirjallisesti toimittamien Alustaa koskevien palvelukuvausten mukaisesti.</p>
  <p>3.3 Palveluntarjoajalla ei ole oikeutta jälleenmyydä tai levittää Alustaa, sen sisältöä tai sen osaa. Immateriaalioikeudet Alustalle ja Alustan käyttöanalytiikkaan kuuluvat Salaovelle. Sopimuksella ei siirretä Salaoviin Alustalle liittyviä Immateriaalioikeuksia tai muita Immateriaalioikeuksia Palveluntarjoajalle.</p>
  <p>3.4 Palveluntarjoaja on vastuussa Alustan käyttämiseen tarvittavien laitteiden ja yhteyksien hankkimisesta ja niiden kustannuksista.</p>
  <p>3.5 Palveluntarjoaja sitoutuu olemaan käyttämättä Alustaa laittomaan tai luvattomaan toimintaan.</p>
  <p>3.6 Palveluntarjoajan ei tule käyttää Alustaa minkään tiedon säilyttämiseen tai varmuuskopiointiin.</p>
  <p>3.7 Palvelun kautta tilatuista palveluista Alusta tallentaa laadunvalvonta-, laskutus- ja analytiikkatarkoituksiin tiedot muun muassa Käyttäjästä sekä palvelun kestosta ja ajankohdasta.</p>
  <p>3.8 Palveluntarjoaja varmistaa ja on vastuussa siitä, että sen tarjoamien palveluiden laatu noudattaa kaikkia soveltuvia lakeja, viranomaisen määräyksiä sekä sopimuksiin (mukaan lukien Tilauksiin) perustuvia Asiakkaisiin kohdistuvia velvoitteita. Palveluiden on noudatettava Salaoven antamia kohtuullisia ohjeita.</p>
  <p>3.9 Henkilö, joka allekirjoittaa tai hyväksyy Sopimuksen Palveluntarjoajan puolesta vakuuttaa, että hänellä on oikeus edustaa Sopimuksessa yksilöityä Palveluntarjoajaa. Palveluntarjoaja vakuuttaa, että Alustalle lisätyt tiedot, jotka perustuvat Palveluntarjoajan käyttäjätiliä koskeviin tietoihin, ovat paikkansapitäviä. Palveluntarjoaja sitoutuu pitämään yhteystietonsa sekä maksutietonsa ajan tasalla Alustalla.</p>
  <p>3.10 Palveluntarjoaja vastaa kaikista Palveluntarjoajan Alustalla julkaistavaan hinnastoon ja palvelukuvauksiin liittyvistä korjauksista ja päivityksistä. Palveluntarjoaja määrittelee itsenäisesti palveluidensa hinnat Alustalla. Alustalla ilmoitetut hinnat eivät saa olla korkeampia kuin hinnat, jotka Palveluntarjoaja veloittaa asiakkailta</p>
  <p>3.11 Palveluntarjoaja julkaisee kaiken tarvittavan tiedon Palveluntarjoajasta ja Palveluntarjoajan ja Asiakkaan välisestä sopimuksesta Alustalla soveltuvan lainsäädännön mukaisesti. Tällaiset tiedot sisältävät muun muassa tiedot yrityksestä ja Palveluntarjoajan yhteystiedot, tietoa tarjotun tuotteen ominaisuuksista, yksittäisen tuotteen hinnoista (sisältäen alv), toimitushinnoista ja tavoista, tilauksen vähimmäismäärästä, maksutavoista, toimitusajoista ja tarvittaessa valitusten käsittelystä.</p>
  <p>3.12 Salaovi ei takaa liidien määrää, kohteen tietojen oikeellisuutta, asiakkaan tavoittamista tai hankintakäyntejä kohteisiin. Asiakkaan tulee suunnitella asuntonsa myyntiä. Salaovi ei tarkista kohteen tietojen oikeellisuutta ennen tietojen välittämistä eteenpäin. Virheelliset asiakkaat tarkastetaan kuukausittain ilmoituksesta ja hyvitetään palveluntarjoajalle, mikäli asiakas todetaan virheelliseksi.</p>
  <h3>4. Käyttäjätilit</h3>
  <p>4.1 Voidakseen käyttää Alustaa, Palveluntarjoajan tulee luoda käyttäjätili Alustalla annettujen ohjeiden mukaisesti.</p>
  <p>4.2 Alustan käyttäjätilit ovat henkilökohtaisia kullekin Käyttäjälle. Palveluntarjoaja ei saa antaa Alustan käyttäjätiliään kolmansien käyttöön. Palveluntarjoaja on vastuussa kaikista toimista, joita Käyttäjä tekee Alustalla. Salaovi voi perustellusta syystä poistaa Käyttäjän käyttäjätilin tai estää Käyttäjän pääsyn Alustalle.</p>
  <p>4.3 Palveluntarjoajan tulee estää käytössään olevan Alustalle liittyvien käyttäjätilien luvaton käyttö ja pidettävä kirjautumistiedot salassa.</p>
  <p>4.4 Palveluntarjoajan on ilmoitettava Salaovelle välittömästi, mikäli käyttäjätilejä on käytetty luvattomasti.</p>
  <p>4.5 Palveluntarjoaja sitoutuu pitämään kaikki tilinsä tiedot luottamuksellisina ja on vastuussa kaikesta tilillään tapahtuvasta toiminnasta.</p>
  <p>4.6 Palveluntarjoajan on ilmoitettava Salaovelle oikeat ja ajantasaiset tiedot, joita tarvitaan käyttäjätilien luomiseen, asiakasyhteydenpitoon ja laskuttamiseen. Palveluntarjoajan on ilmoitettava Salaovelle välittömästi, mikäli nämä tiedot muuttuvat.</p>
  <p>4.7 Alustalle hyväksytään ainoastaan aktiivisesti työskenteleviä kiinteistönvälittäjiä tai myyntineuvottelijoita. Alustaa ei saa käyttää esimerkiksi ajanvaraaja, sihteeri tms.</p>
  <h3>5. Palveluntarjoajan Aineisto</h3>
  <p>5.1 Immateriaalioikeudet Palveluntarjoajan Aineistoon kuuluvat Palveluntarjoajalle. Salaovella on oikeus käyttää Palveluntarjoajan Aineistoa ilman korvausta (mukaan lukien Alustan ylläpitämiseksi, kehittämiseksi ja Palveluntarjoajan Aineiston esittämiseksi Alustalla ja markkinointitarkoituksia varten). Palveluntarjoaja on vastuussa siitä, että Palveluntarjoajan Aineistoa voidaan lainmukaisesti käyttää Sopimuksen mukaisiin tarkoituksiin.</p>
  <h3>6. Henkilötiedot</h3>
  <p>6.1 Salaovi kerää Palveluntarjoajan ja Asiakkaan tilausta ja Alustan käyttöä koskevia henkilötietoja, joita Salaovi tarvitsee Alustan tarjoamiseksi Asiakkaalle, Palveluntarjoajalle, Käyttäjille ja muihin Salaoviin tietosuojaselosteen mukaisiin tarkoituksiin. Tällaisia tietoja ovat muun muassa nimet, yhteystiedot ja maksutiedot. Salaovi käsittelee näitä tietoja rekisterinpitäjänä tietosuojaselosteensa mukaisesti. Tietosuojaseloste on saatavilla osoitteessa <a href="https://www.Salaovi.fi/tietosuojaseloste/">http://www.Salaovi.fi/tietosuojaseloste/</a> .</p>
  <p>6.2 Siltä osin kuin Salaovi tarjoaa Alustallaan toimintoja, joiden perusteella Palveluntarjoaja voi siirtää ja käsitellä rekisterinpitäjänä henkilötietoja Alustalla, esimerkiksi niin, että Palveluntarjoajan Aineisto sisältää Palveluntarjoajan asiakkaita koskevia henkilötietoja, on Palveluntarjoaja henkilötietojen rekisterinpitäjä ja Salaovi käsittelee, tarjoamalla Palveluntarjoajalle Alustan, näitä henkilötietoja Palveluntarjoajan lukuun henkilötietojen käsittelijänä Sopimuksen mukaisiin tarkoituksiin Sopimuksen voimassaolon aikana. Jos Salaovi käsittelee henkilötietoja edellä mainitusti Palveluntarjoajan lukuun, kyseiseen käsittelyyn sovelletaan Salaoven tietojenkäsittelyehtoja. Tietojenkäsittelyehdot ovat saatavilla osoitteessa <a href="https://www.Salaovi.fi/tietojenkasittelyehdot/">http://www.Salaovi.fi/tietojenkasittelyehdot/</a> .</p>
  <h3>7. Hinnat, maksaminen ja verot</h3>
  <p>7.1 Salaovi veloittaa Palveluntarjoajalta Komission ja mahdolliset muut Alustalla ilmoitetut vähennykset ja palvelumaksut jokaisesta Asiakkaasta, jonka Palveluntarjoaja on vastaanottanut Alustan kautta. Komissio lasketaan Alustan kautta myytyjen tuotteiden ja palveluiden arvonlisäverollisesta hinnasta, jonka Palveluntarjoaja on määritellyt välityspalkkioksi.</p>
  <p>7.3 Salaovi pidättää oikeuden muuttaa Komission suuruutta. Salaovi ilmoittaa muutoksista Palveluntarjoajalle viimeistään seitsemän (7) päivää ennen uusien maksujen voimaantuloa. Mikäli Palveluntarjoaja ei hyväksy uusia maksuja, Palveluntarjoajalla on oikeus irtisanoa Sopimus ilmoittamalla siitä Salaovelle kirjallisesti kahden viikon kuluessa siitä, kun Palveluntarjoaja on vastaanottanut ilmoituksen muutoksista. Jos Palveluntarjoaja jatkaa Alustan käyttöä muutosten voimaantulon jälkeen, katsotaan Palveluntarjoajan hyväksyneen uuden Komission.</p>
  <p>7.4 Palveluntarjoaja vastaa kaikista veroista ja kuluista, jotka liittyvät tämän tuotteisiin ja palveluihin, mukaan lukien Palveluntarjoajan palveluihin sisältyvän arvonlisäveron tilittämisen.</p>
  <p>7.5 Palveluntarjoajaa laskutetaan seuraavan taulukon mukaisesti. Asiakas valikoituu lisäksesi 2-4 palveluntarjoajalle hinta 25€. Salaoven myyntikomissio on 5% Alustan kautta toteutuneesta välityspalkkiosta. Maksunpalautuksissa 2€ käsittelykulu per maksu</p>
  <p>Hintoihin lisätään kulloinkin voimassa oleva ALV (24%)</p>
  <p>7.6 Palveluntarjoaja raportoi kaikista tapahtumista Alustalle. Salaovi laskuttaa komission näiden ilmoitusten perusteella. Palveluntarjoaja on velvollinen ilmoittamaan kuukauden tapahtumat viimeistään seuraavan kuukauden 1. päivään mennessä Alustalle. Ilmoituksen tulee sisältää tiedot asiakkaista, jotka ovat johtaneet toimeksiantosopimukseen sekä toteutuneen nettomyynnin. Ilmoittamatta jätetyistä tapahtumista laskutetaan 500€+alv tarkastusmaksu.</p>
  <h3>8. Keskeytykset ja muutokset</h3>
  <p>8.1 Salaovella on oikeus keskeyttää Alustan tarjoaminen huoltotoimenpiteiden vuoksi. Mikäli Salaovi keskeyttää Alustan tarjoamisen, Salaovi pyrkii ilmoittamaan keskeytyksestä Palveluntarjoajalle etukäteen tai, ellei tämä ole kohtuudella mahdollista, viipymättä sen jälkeen, kun Salaovi on saanut tiedon keskeyttämisen syystä. Alusta voi olla väliaikaisesti poissa käytöstä myös muista Salaoviin vaikutusmahdollisuuksien ulkopuolella olevista syistä.</p>
  <p>8.2 Salaovella on oikeus estää Palveluntarjoajan tai Käyttäjän pääsy Alustalle ilman etukäteisilmoitusta, mikäli Salaovi perustellusti epäilee, että Palveluntarjoaja tai Käyttäjä on rikkonut Sopimusta.</p>
  <p>8.3 Alusta tarjotaan ‘’sellaisena kuin se on’’ ja ‘’kuten se on saatavilla’’. Salaovi ei takaa Alustan jatkuvuutta, oikea-aikaisuutta, turvallisuutta, virheettömyyttä tai viruksettomuutta, eikä se anna takuita Alustan käytöllä mahdollisesti saavutettavista tuloksista tai Palvelutarjoajan Alustaa käyttämällä hankkimista tiedoista. Palveluntarjoaja ymmärtää ja hyväksyy, että Salaovi kehittää Alustaa ja siihen liittyvät ominaisuudet voivat muuttua Sopimuksen voimassaoloaikana.</p>
  <h3>9. Salassapito</h3>
  <p>9.1 Kumpikin Osapuoli sitoutuu pitämään salassa toiselta Osapuolelta saamansa luottamukselliset aineistot ja tiedot sekä olemaan käyttämättä niitä muuhun kuin Sopimuksen mukaiseen tarkoitukseen.</p>
  <p>9.2 Osapuolen on välittömästi lopetettava toiselta Osapuolelta saamansa luottamuksellisen aineiston ja tietojen käyttäminen sekä pyynnöstä palautettava tai hävitettävä kyseinen aineisto ja tiedot viimeistään, kun Sopimus päättyy tai pyynnöstä jo aiemmin, jos Osapuoli ei enää tarvitse kyseistä aineistoa tai tietoja Sopimuksen mukaiseen tarkoitukseen.</p>
  <p>9.3 Tässä̈ kohdassa sovittu salassapitovelvoite päättyy, kun 5 vuotta on kulunut Sopimuksen päättymisestä̈, ellei laissa edellytetä̈ pidempää̈ salassapitovelvoitetta.</p>
  <h3>10. Sopimuksen voimassaolo ja päättyminen</h3>
  <p>10.1 Sopimus tulee voimaan Sopimuksen hyväksymispäivämäärällä ja on toistaiseksi voimassa oleva. Kumpikin osapuoli voi irtisanoa Sopimuksen kirjallisesti noudattaen 7 päivän irtisanomisaikaa.</p>
  <p>10.2 Osapuolella on oikeus irtisanoa Sopimus kirjallisesti päättymään välittömästi, mikäli toinen Osapuoli rikkoo Sopimusta olennaisesti eikä korjaa rikkomustaan 7 päivän sisällä vastaanotettuaan asiaa koskevan korjauskehotuksen toiselta Osapuolelta. Lisäksi Salaovella on oikeus irtisanoa Sopimus (i) päättyväksi kymmenen (10) päivän kuluessa ilmoituksesta, mikäli Palveluntarjoaja on rikkonut Sopimuksen ehtoja tai (ii) päättymään välittömästi, mikäli Palveluntarjoaja lopettaa tai keskeyttää liiketoimintansa tai Palveluntarjoaja ei muuten ole kykenevä täyttämään velvollisuuksiaan Asiakkaita tai Salaovea kohtaan.</p>
  <p>10.3 Salaovi on oikeutettu keskeyttämään Sopimuksen täyttämisen väliaikaisesti tai irtisanomaan Sopimuksen, mikäli Palveluntarjoajan Asiakkaille tarjoamien tuotteiden ja/tai palveluiden laatu ei täytä Salaoviin asettamia kohtuullisia vaatimuksia.</p>
  <p>10.4 Sopimuksen päättyessä Palveluntarjoajan on lopetettava Alustan käyttö välittömästi.</p>
  <h3>11. Vastuunrajoitukset</h3>
  <p>11.1 Salaovi ei vastaa Asiakkaan ja Palveluntarjoajan väliseen Tilaukseen liittyvistä reklamaatioista tai vahingoista. Kyseisiin reklamaatioihin ja vahinkoihin sovelletaan Asiakkaan ja Palveluntarjoajan välillä Tilauksessa sovittuja ehtoja.</p>
  <p>11.2 Salaovi ei vastaa mistään välillisestä tai epäsuorasta vahingosta. Välillisenä vahinkona pidetään esimerkiksi saamatta jäänyttä voittoa tai vahinkoa, joka johtuu liikevaihdon menetyksestä, saatavuuden kärsimisestä tai tietojen häviämisestä. Sopimukseen perustuva Salaoviin vahingonkorvausvelvollisuus on yhteensä enintään 100% Salaoviin Sopimuksen mukaisesti vastaanottamista Komissioista vahinkoa edeltäneen kuuden (6) kuukauden aikana. Tämän kohdan mukaiset vastuunrajoitukset eivät koske vahinkoa, joka on aiheutettu tahallisesti tai törkeällä huolimattomuudella.</p>
  <p>11.3 Salaovi ei vastaa viivästyksestä tai vahingosta, joka johtuu sen kohtuullisten vaikutusmahdollisuuksien ulkopuolella olevasta esteestä, jonka seurauksia Salaovi ei kohtuudella olisi voinut välttää tai voittaa. Ylivoimaisena esteenä pidetään esimerkiksi kolmannen osapuolen ohjelmiston ja laitteiden virheet ja keskeytykset. Salaoven alihankkijaa kohdannut ylivoimainen este vapauttaa myös Salaoven vastuusta.</p>
  <p>11.4 Salaovi ei takaa Asiakkaiden määrää, tietojen oikeellisuutta, asiakkaan tavoittamista tai hankintakäyntejä kohteisiin. Salaovella on velvollisuus pyrkiä varmistamaan tietojen oikeellisuus parhaaksi katsomallaan tavalla</p>
  <h3>12. Sopimuksen muuttaminen</h3>
  <p>12.1 Salaovi voi muuttaa Sopimusta ilmoittamalla Palveluntarjoajalle muuttuneesta Sopimuksesta 30 päivää ennen uuden Sopimuksen voimaantuloa asiakastiedottein tai muutoin kirjallisesti, jolloin Palveluntarjoajalla on oikeus irtisanoa Sopimus ennen muutetun Sopimuksen voimaantuloa ilmoittamalla asiasta Salaovelle kirjallisesti.</p>
  <h3>13. Sopimuksen siirtäminen</h3>
  <p>13.1 Salaovella on oikeus siirtää Sopimus ja Sopimuksen mukaiset oikeutensa ja velvoitteensa konserniyhtiölleen tai Salaoven tätä Sopimusta koskevan liiketoiminnan siirron/kaupan siirronsaajalle/ostajalle ilman Palveluntarjoajan suostumusta.</p>
  <p>13.2 Palveluntarjoajalla ei ole oikeutta siirtää Sopimusta tai Sopimuksen mukaisia oikeuksia tai velvollisuuksia kolmannelle kokonaan tai osittain ilman Salaoven etukäteistä kirjallista hyväksyntää.</p>
  <h3>14. Ilmoitukset</h3>
  <p>14.1 Kaikki Sopimukseen liittyvät ilmoitukset tulee tehdä postitse tai sähköpostitse vastaanottavan Osapuolen Sopimuksessa mainittuun osoitteeseen. Jos Osapuolen yhteystiedot muuttuvat, on tästä ilmoitettava toiselle Osapuolelle ilman aiheetonta viivytystä.</p>
  <h3>15. Muut ehdot</h3>
  <p>15.1 Mikään Sopimuksessa ei muodosta tai katsota muodostavan osakkuutta, yhteisyritystä tai muuta suhdetta Osapuolten välille kuin Sopimuksessa nimenomaisesti sovittu sopimusperusteinen suhde.</p>
  <p>15.2 Salaovella on oikeus käyttää alihankkijoita Alustan tarjoamiseksi ja Sopimuksen mukaisten velvoitteidensa täyttämiseksi.</p>
  <p>15.3 Jos Sopimukseen sisältyvän lausekkeen katsotaan olevan täytäntöönpanokelvoton, ei se vaikuta Sopimuksen muiden lausekkeiden pätevyyteen.</p>
  <p>15.4 Sopimus muodostaa Osapuolten välisen koko sopimuksen siinä käsitellystä aiheesta.</p>
  <h3>16. Sovellettava laki ja riidanratkaisu</h3>
  <p>16.1 Sopimukseen sovelletaan Suomen lakia, lukuun ottamatta lainvalintaa koskevia säännöksiä.</p>
  <p>16.2 Sopimuksesta aiheutuvat riidat ratkaistaan lopullisesti välimiesmenettelyssä, Keskuskauppakamarin välimiesmenettelysääntöjen mukaisesti. Välimiesoikeus on yksijäseninen. Välimiesmenettelyn paikka on Helsinki ja sen kieli on suomi.</p>  `;

  return <ContentContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};


export default TermsEdit;
