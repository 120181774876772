// AddCardPage.js
import React, { useState, useEffect } from 'react';
import { TabbedForm, Toolbar, Title, useNotify } from 'react-admin';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AddCardForm from './AddCardForm';
import './AddCardPage.css'; // Lisätty CSS-tiedosto ulkoasua varten
import axios from 'axios';
import Alert from '@material-ui/lab/Alert'; // Varmista, että tämä komponentti on asennettu
import AlertTitle from '@material-ui/lab/AlertTitle'; 
import AddFundsForm from './AddFundsForm';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@mui/material';


const WelcomeCard = () => {
  const storedProfile = localStorage.getItem('profile');
  const name = storedProfile ? JSON.parse(storedProfile).fullName : 'Vieras';

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          Lompakko
        </Typography>
<p>Täällä voit hallita maksutapojasi sekä lisätä varoja salaovi lompakkoon. Varojen lisääminen on hyvä työkalu mikäli haluat vähentää kuittien määrää kuukaudessa.</p>        
</CardContent>
    </Card>
  );
}


const stripePromise = loadStripe('pk_live_igV0AcZqYArf74RJ6nxicPbc00X214raKO');

const AddCardPage = () => {
    const [balance, setBalance] = useState(0); // Tässä määritellään balance-tilamuuttuja ja sen setter-funktio
    const [showAlert, setShowAlert] = useState(false); // Uusi tilamuuttuja varoitukselle

    const getWalletBalance = async () => {
      try {
        const authorizationToken = window.localStorage.getItem('token'); // oletetaan että token on tallennettu näin
        const response = await axios.get('https://api.salaovi.fi/User/getWalletBalance', {
          headers: {
            'Accept': 'application/json',
            'authorization': `Bearer ${authorizationToken}`, // Oleta, että token edellyttää "Bearer" etuliitettä
          },
        });
        setBalance(response.data.data.balance); // Asetetaan saldo tilaan
      } catch (error) {
        console.error('Virhe haettaessa lompakon saldoa:', error.response ? error.response.data : error.message);
      }
    };

    const onBalanceChange = () => {
      getWalletBalance();
    };


    useEffect(() => {
      // Tämä funktio hakee käyttäjän lompakon saldon
      const getWalletBalance = async () => {
        try {
          const authorizationToken = window.localStorage.getItem('token'); // oletetaan että token on tallennettu näin
          const response = await axios.get('https://api.salaovi.fi/User/getWalletBalance', {
            headers: {
              'Accept': 'application/json',
              'authorization': `Bearer ${authorizationToken}`, // Oleta, että token edellyttää "Bearer" etuliitettä
            },
          });
          setBalance(response.data.data.balance); // Asetetaan saldo tilaan
        } catch (error) {
          console.error('Virhe haettaessa lompakon saldoa:', error.response ? error.response.data : error.message);
        }
      };
  
      getWalletBalance();
    }, []);
    const [cards, setCards] = useState([]); // Käyttäjän kortit tallennetaan tähän

    useEffect(() => {
      const listCards = async () => {
        try {
            const authorizationToken = window.localStorage.getItem('token');
            const response = await axios.post('https://api.salaovi.fi/User/listCard', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authorizationToken}`,
                },
            });

            setCards(response.data.data);
            if (response.data.data.length === 0) {
                setShowAlert(true); // Ei kortteja, näytetään varoitus
            }
        } catch (error) {
            console.error('Virhe haettaessa kortteja:', error.response ? error.response.data : error.message);
        }
    };

    listCards();
}, []);


    const handleDeleteCard = async (cardData) => {
      try {
        const authorizationToken = window.localStorage.getItem('token');
        const cardId = cardData._id; // Käytetään kortin yksilöllistä tunnistetta, ei customerId:tä
    
    
        // Lähetä POST-pyyntö poistamaan kortti
        const response = await axios.post('https://api.salaovi.fi/User/deleteCard', { id: cardId }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authorizationToken}`,
          },
        });
    
  
        // Käsittele vastaus
        if (response.data.statusCode === 200) {
       
          window.location.reload();
          // Filtteröi poistettu kortti pois tilasta päivittämiseksi
          setCards(cards.filter(card => card._id !== cardId));
        } else {
          console.log('Kortin poisto epäonnistui:', response.data.message);
        }
      } catch (error) {
        console.error('Virhe poistettaessa korttia:', error.response ? error.response.data : error.message);
      }
    };
    
    
    const storedProfile = localStorage.getItem('profile');
    const name = storedProfile ? JSON.parse(storedProfile).fullName : '';


    
    const CustomToolbar = (props) => (
        <Toolbar {...props} >
    .
        </Toolbar>
      );
     
    return (
      <>
       <WelcomeCard />
   
  <Title title="Lompakko" />
<br></br>
      {showAlert && (
                        <div className="custom-alert">
                            <Alert severity="error" style={{  width: '100%' }}>
                                <AlertTitle><strong>Virhe</strong></AlertTitle>
                                Sinulla tulee olla aktiivinen maksutapa käyttääksesi sovellusta sekä saadaksesi vertailuja <p></p>
                                <strong>Lisää maksutapa alla olevasta lomakkeesta.</strong>
                            </Alert>
                        </div>
                    )}
    <div  className="main-container">
       
                 <br></br>
                 <div className="container_card1">
                 <div className="addcard">
            <div className="add-card-container">
              <Elements options={{ locale: 'fi' }} stripe={stripePromise}>
                <h3>Lisää maksutapa</h3>
                <AddCardForm apiUrl={'https://api.salaovi.fi'} />
               
             
                <div className="wallet-balance2">
                <img 
    src="https://salaovi.s3.amazonaws.com/1699809565139.png"



    alt="Maksutapa" 
    style={{ maxWidth: '360px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
  />
                </div>
              </Elements>
            </div>
          </div>  
          </div>

          <div class="container_card1">
                 <div className="addcard">
            <div className="add-card-container">
              <Elements options={{ locale: 'fi' }} stripe={stripePromise}>
                <h3>Lisää varoja lompakkoon</h3>
               <AddFundsForm onBalanceChange={onBalanceChange} />
                <div className="wallet-balance">
                  <h4>Lompakon saldo: {balance}€</h4>
                </div>
              </Elements>
            </div>
          </div>  
          </div>

          <br></br>
         
          <div className="cards-display">
          
  {cards.length > 0 ? (
    cards.map((card, index, array) => ( // Lisää 'index' ja 'array' parametrit map-funktion callbackiin
      <div key={card._id} className="card-info">
        <h3 className="title">Käytössä olevat maksutavat</h3>
        <div className="container_card">
          <div className="card2">
            <div className="visa_logo">
              {card.brand}
            </div>
            <div className="visa_info">
              <img src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png" alt=""></img>
              <p>•••• •••• •••• {card.last4}</p>
            </div>
            <div className="visa_crinfo">
              <p>{card.exp_month}/{card.exp_year}</p>
              <p>{name}</p>
            </div>
          </div>
        </div>
        {/* Tarkista, onko kortteja enemmän kuin yksi ennen painikkeen renderöintiä */}
        {array.length > 1 && (
          <button onClick={() => handleDeleteCard(card)}>
            Poista
          </button>
        )}
      </div>
    ))
  ) : (
    <p>Ei maksutapoja</p>
  )}
</div>
</div>

      </>
    );
  };
  

export default AddCardPage;