import React, { useState } from "react";
import axios from "axios";
import { useNotify } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControlLabel,
  Switch,
  Text,
  Grid
} from "@material-ui/core";
import { Button } from "@mui/material";
import { useEffect } from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";


const uploadVideo = async (videoFile, setFormData, setUploading) => {
    setUploading(true);
  
    const formData = new FormData();
    formData.append('pic', videoFile);
  
    try {
      // Oletetaan, että käytetään axios-kirjastoa
      const response = await axios({
        method: 'post',
        url: 'https://api.salaovi.fi/User/uploadAttachment',
        data: formData,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Tarkistetaan onnistuiko pyyntö
      if (response.status === 200 && response.data.message === "Success") {
        setFormData(prevFormData => ({
          ...prevFormData,
          videoLink: response.data.data.s3Url
        }));
      } else {
        console.error('Server response error:', response);
      }
    } catch (error) {
      console.error('Error uploading video:', error);
    } finally {
      setUploading(false);
    }
  };
  
  
  

// Muuntaa prosenttiosuuden liukukytkimen arvoksi.
const percentageToValue = (percentage) => (percentage / 100) * (5 - 2) + 2;

// Muuntaa liukukytkimen arvon takaisin prosenttiosuudeksi.
const valueToPercentage = (value) => ((value - 2) / (5 - 2)) * 100;

// Luo kaksiarvoinen liukukytkin komponentti
const CommissionSlider = ({ value, onChange, label }) => {


  const handleChange = (event, newValue) => {

    // Pyöristää arvot kahden desimaalin tarkkuuteen
    const roundedValues = newValue.map((value) => {
      const percentage = valueToPercentage(value);
      // Käytetään toFixed muuttamaan merkkijonoksi ja pyöristämään kahteen desimaaliin ja sitten muunnetaan se takaisin numeroksi
      return isNaN(percentage) ? 0 : Number(percentage.toFixed(2));
    });

    // Lähetetään ylös pyöristetyt prosenttiosuudet
    onChange(roundedValues);
  };

  return (
    <>
      {label && (
        <Typography id="commission-slider-label" gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        value={value.map(percentageToValue)}
        min={2}
        max={5}
        step={0.1}
        valueLabelDisplay="on" 
        onChange={handleChange}
        valueLabelFormat={(value) => value.toFixed(1) + "%"}
      />
    </>
  );
};

const OfferButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();


  const storedProfile = JSON.parse(localStorage.getItem("profile"));
  const userId = storedProfile ? storedProfile.id : "";
  const [formData, setFormData] = useState({
    enquiryId: record._id || "",
    userId: "",
    commisionStart: "",
    commisionEnd: "",
    videoLink: "",
    message: "",
    pastListing: [],
    photography: true,
    homeStaging: true,
    ownWebsite: true,
    postCards: true,
    virtualTour: true,
    landScaping: true,
    market: true,
  });

  // useEffect-hook lokitukselle
  useEffect(() => {

  }, [formData]); // Riippuvuuslista, tässä tapauksessa kun formData päivittyy

  useEffect(() => {

    if (record && record._id) {
      setFormData((formData) => ({
        ...formData,
        enquiryId: record._id,
      }));
    } else {

    }
  }, [record]);

  const handleChange = (name) => (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.value,
    }));
  };
  const handleToggleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.checked });
  };

  // Muunna provisiot liukukytkimen prosenttiosuuksiksi
  const commissionValues = [
    valueToPercentage(formData.commisionStart),
    valueToPercentage(formData.commisionEnd),
  ];

  const handleSliderChange = (name) => (newValue) => {

    // Muunnetaan prosentit arvoiksi välillä 2-5
    const newValues = newValue.map((percentage) => {
      return percentageToValue(percentage);
    });
 
    // Asetetaan muunnetut arvot formDataan
    setFormData((formData) => ({
      ...formData,
      commisionStart: newValues[0],
      commisionEnd: newValues[1],
    }));
  };
  const [uploading, setUploading] = useState(false);
  const handleSave = async () => {
      // Jos pastListing on tyhjä, asetetaan se nulliksi ennen lähetystä
  const submitData = {
    ...formData,
    pastListing: formData.pastListing.length > 0 ? formData.pastListing : null,
  };

  

    const token = window.localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.salaovi.fi/Vendor/bid",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        notify("Tarjous lähetetty onnistuneesti!");
        setOpen(false);
        window.location.reload(); // lataa sivun uudelleen
      } else {
        notify("Jotain meni pieleen tarjousta lähetettäessä");
      }
    } catch (error) {
      console.error("Virhe lähettäessä tarjousta:", error);
      notify("Jotain meni pieleen tarjousta lähetettäessä");
    }
  };

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        const token = window.localStorage.getItem("token");
        try {
          const response = await axios.post(
            "https://api.salaovi.fi/Vendor/listProposal",
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `${token}`,
              },
            }
          );



          if (response.status === 200 && response.data && response.data.data) {
            const innerData = response.data.data;

            setFormData((prevData) => ({
              ...prevData,
              userId: innerData.userId || "",
              commisionStart: innerData.commisionStart || "",
              commisionEnd: innerData.commisionEnd || "",
              videoLink: innerData.videoLink || "",
              message: innerData.message || "",
              pastListing: innerData.pastListing || "[null]",
              photography: innerData.photography || true,
              homeStaging: innerData.homeStaging || true,
              ownWebsite: innerData.ownWebsite || true,
              postCards: innerData.postCards || true,
              virtualTour: innerData.virtualTour || true,
              landScaping: innerData.landScaping || true,
              market: innerData.market || true,
            }));
          } else {
            console.error(
              "Väärä statuskoodi API-vastauksessa:",
              response.status
            );
            notify("Jotain meni pieleen tietojen haussa");
          }
        } catch (error) {
          console.error("Virhe haettaessa tietoja:", error);
          notify("Jotain meni pieleen tietojen haussa");
        }
      };

      fetchData();
    }
  }, [open]);
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        JÄTÄ TARJOUS
      </Button>
      <Dialog
  open={open}
  onClose={() => setOpen(false)}
  PaperProps={{
    style: {
      width: '550px', // Asettaa dialogin leveyden 400 pikseliin
      // Lisää tähän muita tyylejä tarvittaessa
    },
  }}
>
        <DialogTitle>Jätä tarjous</DialogTitle>
        <DialogContent>
        <Typography variant="body1">
    Videotervehdys (ei pakollinen)
  </Typography>

   
    {/* Latauspalkki ja video - vain toinen näkyy kerrallaan */}
    {uploading ? (
      // Latauspalkki, voit asettaa 'value'-attribuuttiin latauksen edistymisen
      <progress value="0" max="100"></progress> // Oletusarvoja ja maksimiarvoa voi säätää
    ) : (
      // Video-esikatseluruutu
      <div style={{ marginTop: '20px' }}>
        <video 
          width="100%" 
          controls 
          poster={formData.videoLink ? "" : "https://salaovi.s3.amazonaws.com/1699108974260.png"        } // Jos videoLink on tyhjä, näytä placeholder-kuva
        >
          {formData.videoLink && <source src={formData.videoLink} type="video/mp4" />}
          Valitettavasti selaimesi ei tue videotoistoa.
        </video>
      </div>
    )}
            <input type="file" onChange={(e) => {
  const file = e.target.files[0];
  if (file) {
    uploadVideo(file, setFormData, setUploading);
  }
}} />
<p></p>
          <CommissionSlider
            label="Palkkiotarjous (%)"
            value={[
              valueToPercentage(formData.commisionStart),
              valueToPercentage(formData.commisionEnd),
            ]}

            onChange={handleSliderChange("commissionRange")}
          />
          <TextField
            label="Viesti"
            fullWidth
            multiline
            rows={6} // Voit asettaa tämän suuremmaksi jos haluat isomman tekstilaatikon
            value={formData.message}
            onChange={handleChange("message")}
          />
          <p></p>
          <Typography variant="body1">
    Tarjous Sisältää
  </Typography>
  <Grid container spacing={1}>
  {/* Ensimmäinen palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.photography}
          onChange={handleToggleChange("photography")}
          color="primary"
        />
      }
      label="Valokuvaus"
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.homeStaging}
          onChange={handleToggleChange("homeStaging")}
          color="primary"
        />
      }
      label="Stailaus"
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.ownWebsite}
          onChange={handleToggleChange("ownWebsite")}
          color="primary"
        />
      }
      label="Somemarkkinointi"
    />
  </Grid>

  {/* Toinen palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.postCards}
          onChange={handleToggleChange("postCards")}
          color="primary"
        />
      }
      label="Esitteet, flyerit ym."
    />
    <FormControlLabel
      control={
        <Switch
          checked={formData.virtualTour}
          onChange={handleToggleChange("virtualTour")}
          color="primary"
        />
      }
      label="Virtuaaliesittely"
    />
    
  </Grid>

  {/* Kolmas palkki */}
  <Grid item xs={12} sm={4}>
    <FormControlLabel
      control={
        <Switch
          checked={formData.market}
          onChange={handleToggleChange("market")}
          color="primary"
        />
      }
      label="Kulukorvaukset"
    />
   <FormControlLabel
      control={
        <Switch
          checked={formData.landScaping}
          onChange={handleToggleChange("landScaping")}
          color="primary"
        />
      }
      label="Asiakirjat"
    />
  </Grid>
</Grid>

    

   

  
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleSave} color="primary">
            Lähetä
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OfferButton;
