import React from 'react';
import { AppBar as RAAppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { ToggleThemeButton } from 'react-admin';
import MyUserMenu from './MyUserMenu';
import NotificationBell from './NotificationBell';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});
const EmptyToolbar = () => <div></div>;
const AppBar = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)'); // Määritä mediakysely

  return (
    <RAAppBar toolbar={<EmptyToolbar />} {...props} userMenu={<MyUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {!isMobile && (
        // Näytä logo vain, jos ei ole mobiililaite
        <>
        <img src="/white.png" alt="logo" height="20px" />
        <span className={classes.spacer} />
        </>
      )}
     
      v1.34
      <NotificationBell />
    </RAAppBar>
  );
};

export default AppBar;
