import React, { useEffect, useState } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import axios from 'axios';
import { Badge, IconButton, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const MAX_NOTIFICATIONS = 10;

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchNotifications(); 
  }, []);

  const fetchNotifications = () => {
    const token = localStorage.getItem('token');
    const lastChecked = localStorage.getItem('lastNotificationCheck') || 0;

    if (token) {
      axios.post('https://partners.salaovi.fi/api/listNotifications', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        const newNotifications = response.data.data
          .filter(notification => new Date(notification.timeStamp).getTime() > lastChecked)
          .slice(0, MAX_NOTIFICATIONS);
        setNotifications(newNotifications);
      })
      .catch(error => {
        console.error('Virhe ilmoitusten haussa:', error);
      });
    }
  };

  const formatDate = (dateString) => {
    const options = { month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fi-FI', options);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchNotifications(); // Hae ilmoitukset uudelleen
  };

  const handleClose = () => {
    setAnchorEl(null);
    localStorage.setItem('lastNotificationCheck', new Date().getTime()); // Päivitä viimeisin aikaleima
    setNotifications([]); // Tyhjennä ilmoitukset heti, kun paneeli suljetaan
};


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {notifications.map(notification => (
            <ListItem button key={notification.timeStamp}>
              <ListItemText 
                primary={notification.title}
                secondary= {notification.message} 
                tertiaryText= {formatDate(notification.timeStamp)}
              />
              <Typography variant="body2" color="textSecondary">
                {formatDate(notification.timeStamp)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default NotificationBell;
